import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from '@commons/breadcrumb/breadcrumb.component';
// DIRECTIVES
import { ButtonComponent } from '@commons/button/button.component';
import { InputComponent } from '@commons/input/input.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { QrCodeModule } from 'ng-qrcode';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CardComponent } from './card/card.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CopyPasteComponent } from './copy-paste/copy-paste.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { LoaderComponent } from './loader/loader.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RadiobuttonComponent } from './radiobutton/radiobutton.component';
import { RatingStarsSelectComponent } from './rating-stars-select/rating-stars-select.component';
import { RatingStarsComponent } from './rating-stars/rating-stars.component';
import { SelectComponent } from './select/select.component';
import { ShopMenuDropdownComponent } from './shop-menu-dropdown/shop-menu-dropdown.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ToggleComponent } from './toggle/toggle.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CopyPasteComponent,
    RatingStarsComponent,
    BreadcrumbComponent,
    InputComponent,
    CheckboxComponent,
    CardComponent,
    ToggleComponent,
    RadiobuttonComponent,
    RatingStarsSelectComponent,
    TextareaComponent,
    SelectComponent,
    ProgressBarComponent,
    LoaderComponent,
    CustomInputComponent,
    ShopMenuDropdownComponent
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    QrCodeModule,
    NgSelectModule,
    RouterModule
  ],
  exports: [
    ButtonComponent,
    CopyPasteComponent,
    RatingStarsComponent,
    BreadcrumbComponent,
    InputComponent,
    CheckboxComponent,
    CardComponent,
    ToggleComponent,
    RadiobuttonComponent,
    RatingStarsSelectComponent,
    TextareaComponent,
    SelectComponent,
    ProgressBarComponent,
    LoaderComponent,
    QrCodeModule,
    CustomInputComponent,
    ShopMenuDropdownComponent
  ],
  providers: [provideNgxMask()]
})
export class CommonsModule {}
