<div class="error">
  <div class="error__header">
    <div>
      <svg-icon
        src="assets/icons/fechar.svg"
        (click)="modal.close()"
      ></svg-icon>
    </div>
    <h2>Erro</h2>
    <p class="error__description subtitle">{{ errorMessage }}</p>
  </div>
  <div class="error__icon">
    <img src="assets/icons/times-danger.png" class="error__image" />
  </div>
  <p class="subtitle">{{ errorDescription }}</p>
  <div class="error__button">
    <commons-button
      [pink]="true"
      [buttonText]="buttonText"
      [full]="true"
      (buttonClick)="modal.close()"
    >
    </commons-button>
  </div>
</div>
