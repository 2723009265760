import { Component, Input, OnInit } from '@angular/core';
import { PerPageOptionsComponent } from '@components/per-page-options/per-page-options.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'components-per-page-products',
  templateUrl: './per-page-products.component.html',
  styleUrls: ['./per-page-products.component.scss']
})
export class PerPageProductsComponent implements OnInit {
  @Input()
  isLaptop = false;

  @Input()
  productsPerPage: number;

  perPageOptions: Array<{ title: string; value: number }> = [
    { title: '24 produtos por página', value: 24 },
    { title: '48 produtos por página', value: 48 },
    { title: '96 produtos por página', value: 96 }
  ];
  showPerPageOptions = false;
  initialProducts: Array<string>;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  clickOnPerPage(): void {
    if (this.isLaptop) {
      this.showPerPageOptions = !this.showPerPageOptions;
    } else {
      const modal = this.modalService.open(PerPageOptionsComponent, {
        centered: true,
        modalDialogClass: 'per-page-options-modal'
      });
      modal.componentInstance.perPageOptions = this.perPageOptions;
      modal.componentInstance.productsPerPage.setValue(this.productsPerPage);
      modal.result.then((response) => {
        this.productsPerPage = response.value;
        BroadcastUtil.get('perPageProducts').emit(response.value);
      });
    }
  }

  async selectPerPageOption(option: {
    title: string;
    value: number;
  }): Promise<void> {
    this.showPerPageOptions = false;
    BroadcastUtil.get('perPageProducts').emit(option.value);
  }

  get perPageTitle(): string {
    return this.perPageOptions.find(
      (option) => option.value === this.productsPerPage
    )
      ? this.perPageOptions.find(
          (option) => option.value === this.productsPerPage
        ).title
      : '24 produtos por página';
  }
}
