export const environment = {
  production: true,
  firebaseRegion: 'southamerica-east1',
  apiUrl: 'https://subscription-api-prod.glambox.com.br/',
  firebase: {
    apiKey: 'AIzaSyCJf1tkjIN1I1ZegazckSiwS0ik4Lequwo',
    authDomain: 'b4aprodution.firebaseapp.com',
    projectId: 'b4aprodution',
    storageBucket: 'b4aprodution.appspot.com',
    messagingSenderId: '458755317337',
    appId: '1:458755317337:web:61725a63efa894a9021b48'
  },
  hasura: {
    endpoint: 'https://hasura-products-zitr2bdv2q-rj.a.run.app/v1/graphql',
    adminSecret: 'f0e3791d4521b1258b954aaf81898dbf'
  },
  elasticSearch: {
    url: 'https://e4a30e39227142719915dfb4bd5240b5.us-central1.gcp.cloud.es.io:443',
    credential: 'MnBJVHY0Y0JtbEZ2dFBZRE82NGQ6WHdXQ1ZwTkhRQUNvS2s5WTNGSUNCUQ=='
  },
  instagramClientId: 320494478948669,
  recaptchaKey: '6LffacYaAAAAALcrWK2IBhJPFsF5DvtVhBt0C2pj',
  facebookAppId: '567750763271022',
  gtmId: 'GTM-MHP4PNW',
  freshWorks: {
    loja: '69000002811',
    clube: '69000002811'
  },
  cloudStorageUrl: 'https://storage.googleapis.com'
};
