<div class="login" [ngClass]="{ login__modal: modal }">
  <div class="login__form-container">
    <h2 class="login__form-container__title">Login</h2>

    <p
      class="login__form-container__error-message"
      *ngIf="errorMessage"
      [innerHTML]="errorMessage"
    ></p>

    <form class="login__form-container__form" (submit)="login($event)">
      <label for="email" class="login__form-container__form__label"
        >E-mail</label
      >
      <commons-input
        [circleBorder]="true"
        [fullW]="true"
        id="email"
        type="email"
        autocomplete="on"
        [control]="email"
      >
      </commons-input>

      <label for="password" class="login__form-container__form__label"
        >Senha</label
      >
      <commons-input
        [circleBorder]="true"
        [fullW]="true"
        id="password"
        type="password"
        [control]="password"
      >
      </commons-input>

      <p>
        <a
          [routerLink]="['/minha-conta/meus-dados/alterar-senha']"
          (click)="checkAndDismiss()"
          class="login__form-container__form__link"
          >Esqueci minha senha</a
        >
      </p>

      <p *ngIf="invalidLogin">{{ invalidLogin }}</p>

      <div class="login__form-container__form__button">
        <commons-button [full]="true" buttonText="Entrar" [type]="'submit'">
        </commons-button>

        <commons-button
          *ngIf="errorMessage"
          [full]="true"
          [darkGray]="true"
          buttonText="Voltar"
          [type]="'button'"
          (buttonClick)="checkAndDismiss()"
        >
        </commons-button>
      </div>

      <div class="d-flex mt-2">
        <hr />
        <span class="login__form-container__form__or-span">ou</span>
        <hr />
      </div>

      <div class="login__form-container__form__button">
        <commons-button
          [svgPath]="'assets/icons/google.svg'"
          [google]="true"
          [full]="true"
          buttonText="Entrar com Google"
          (buttonClick)="loginWithGoogle()"
        ></commons-button>
      </div>

      <p *ngIf="!isInCheckout">
        Cliente novo?
        <a
          (click)="checkAndDismiss(); goToRegister()"
          class="login__form-container__form__link"
        >
          Cadastre-se
        </a>
      </p>
    </form>
  </div>
</div>
