<header class="header" id="header" [class.header--fixed]="fixedNav">
  <div class="container">
    <div class="header__top">
      <div class="header__logo" *ngIf="isLaptop">
        <button (click)="toggleMenu()" id="hamburg" class="header__hmbrg">
          <svg-icon
            [src]="
              menuOpened ? 'assets/icons/close.svg' : 'assets/icons/hamburg.svg'
            "
            [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"
          >
          </svg-icon>
        </button>
      </div>

      <div class="header__logo">
        <svg-icon
          *ngIf="isMobile"
          routerLink="/"
          [src]="'assets/icons/logo-single.svg'"
          [svgStyle]="{
            'width.px': 176,
            'height.px': 26
          }"
          class="header__logo__center"
        >
        </svg-icon>

        <svg-icon
          *ngIf="!isMobile"
          routerLink="/"
          [src]="'assets/icons/logo.svg'"
          [svgStyle]="{
            'width.px': 173,
            'height.px': 33
          }"
          class="header__svg-logo"
        >
        </svg-icon>
      </div>
      <div class="header__action">
        <a class="header__action--item" *ngIf="!userLogged" (click)="login()">
          <svg-icon
            [src]="'assets/icons/user.svg'"
            [class]="'header__action--item__svg-icon'"
          ></svg-icon>
          <p *ngIf="!isMobile" class="header__button-text">Login</p>
        </a>
        <a (click)="openMenu()" class="header__action--item" *ngIf="userLogged">
          <small
            *ngIf="!user?.urlImageProfile"
            class="header__action--item__initials"
            >{{ initials }}</small
          >
          <img
            class="header__action--item__avatar"
            *ngIf="user?.urlImageProfile"
            [src]="user.urlImageProfile"
          />
          <p *ngIf="!isMobile" class="header__button-text">Minha Conta</p>
        </a>
        <a (click)="onClickOpenCart()" class="header__action--item">
          <svg-icon
            [src]="'assets/icons/cart.svg'"
            [class]="'header__action--item__svg-icon'"
          ></svg-icon>
          <span class="header__action--item__total-items">{{
            totalQuantity
          }}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="container">
    <form (submit)="goToSearch()">
      <div
        *ngIf="isLaptop"
        class="header__search"
        [class.header__search--active]="searchOpened"
      >
        <commons-input
          (input)="searchDebounce(search.value)"
          [placeholder]="'Buscar'"
          [control]="search"
        >
        </commons-input>
        <button type="submit">
          <svg-icon
            (click)="goToSearch()"
            class="header__search__icon"
            [src]="'assets/icons/search.svg'"
            [svgAriaLabel]="'button'"
          >
          </svg-icon>
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="!isLaptop && state$ | async as s" class="header__bottom">
    <div class="container">
      <components-header-navigation
        [routesShop]="routesShop"
        [cart]="s.cart"
        [clubRoutes]="clubRoutes"
      >
      </components-header-navigation>
    </div>
  </div>

  <div
    *ngIf="showCollectionFilters && !hideCollectionFilter && showMobileFilters"
    class="header__filters-content"
  >
    <app-collection-filter [fixedNav]="fixedNav"></app-collection-filter>
  </div>

  <div *ngIf="state$ | async as s">
    <div
      [class.cart-backdrop]="s.flags.showCart"
      (click)="onClickCloseCart()"
    ></div>
    <div
      class="cart-sidebar"
      [ngClass]="{
        'show-cart': s.flags.showCart && !isMobile,
        'show-cart-mobile': s.flags.showCart && isMobile
      }"
    >
      <component-cart></component-cart>
    </div>
  </div>

  <div *ngIf="isLaptop && routesShop && brands">
    <app-menu-mobile
      (menuChanged)="menuChanged($event)"
      [user]="user"
      [userLogged]="userLogged"
      [routesShop]="routesShop"
      [brands]="brands"
      [loggedMenuItems]="loggedMenuItems"
      [clubRoutes]="clubRoutes"
      [helpMenu]="helpMenu"
    ></app-menu-mobile>
  </div>
</header>
