<components-header
  *ngIf="isPlatformBrowserStatus"
  (mobileMenuEmitter)="mobileMenu = $event"
  [isMobile]="isMobile"
  [isTablet]="isTablet"
  [isLaptop]="isLaptop"
  [routesShop]="routesShop"
  [updateRoutesShop]="routesShop"
  [fixedNav]="fixedNav"
></components-header>

<router-outlet></router-outlet>

<components-footer></components-footer>

<div #lgpd *ngIf="showLgpd" class="lgpd_container">
  <p>
    Coletamos dados para melhorar sua experiência na MEN'S e a segurança de
    nosso site! <br />
    Se você continuar navegando, automaticamente estará consentindo com a nossa
    <a href="/pages/politica-de-privacidade" class="privacy-policy"
      >Política de privacidade.</a
    >
  </p>
  <commons-button
    (click)="handleLgpd()"
    [pink]="true"
    [buttonText]="'Aceitar'"
    class="lgpd_button"
  >
    Aceitar</commons-button
  >
</div>
