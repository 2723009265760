<ng-container>
  <div class="list-product-card">
    <div class="list-product-card__header">
      <p class="list-product-card__header__length">
        <span class="list-product-card__header__length__span">{{
          totalProducts
        }}</span>
        resultados
      </p>
      <components-sort-products
        [sortSelected]="sortSelected"
        [isLaptop]="isLaptop"
      ></components-sort-products>
    </div>
    <ng-container *ngIf="products.length > 0">
      <div class="list-product-card__list">
        <div *ngFor="let product of products; let i = index">
          <components-product-card
            (productClick)="
              metricazPushProductClick($event, i + 1); productClick.emit()
            "
            (buyClick)="metricazPushAddToCart($event)"
            [product]="product"
            [featuredProduct]="false"
            [userIsSubscriber]="userIsSubscriber"
            [position]="i"
            [personResult]="personResult"
            [lazyImage]="enableImageLazyLoad(i)"
            [isWishlistPage]="isWishlistPage"
            [user]="user"
          >
          </components-product-card>
        </div>
      </div>
      <div class="list-product-card__footer">
        <div>
          <components-list-paginator
            [totalPages]="totalPages"
            [currentPage]="currentPage"
          ></components-list-paginator>
        </div>
        <div
          *ngIf="products.length >= 24"
          class="list-product-card__footer__per-page"
        >
          <components-per-page-products
            [sortSelected]="sortSelected"
            [productsPerPage]="productsPerPage"
            [isLaptop]="isLaptop"
          ></components-per-page-products>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="isWishlistPage && products.length < 1 && filtersApplied.length < 1"
    >
      <div class="list-product-card__wishlist-empty">
        <div class="list-product-card__wishlist-empty__favorite-content">
          <div
            class="list-product-card__wishlist-empty__favorite-content__left-side"
          >
            <img src="assets/icons/icon-empty-wishlist.svg" alt="" />
          </div>
          <div
            class="list-product-card__wishlist-empty__favorite-content__right-side"
          >
            <p
              class="list-product-card__wishlist-empty__favorite-content__right-side__title"
            >
              Lista de favoritos vazia
            </p>
            <p
              class="list-product-card__wishlist-empty__favorite-content__right-side__subtitle"
            >
              Toque no ícone em formato de coração no canto superior direito
              para salvar o produto em sua lista.
            </p>
            <div
              class="list-product-card__wishlist-empty__favorite-content__right-side__button-box"
            >
              <div
                class="list-product-card__wishlist-empty__favorite-content__right-side__button-box__button"
              >
                <commons-button
                  [full]="true"
                  [pink]="true"
                  (buttonClick)="goShop()"
                  buttonText="Ir para loja"
                >
                </commons-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        !isWishlistPage && products.length < 1 && filtersApplied.length < 1
      "
    >
      <div class="list-product-card__empty-list">
        <p class="list-product-card__empty-list__title">
          Ops! Não temos produtos nessa categoria.
        </p>
        <p class="list-product-card__empty-list__subtitle">
          Navegue pelo site e conheça inúmeros produtos esperando por você.
        </p>
        <div class="list-product-card__empty-list__button-box">
          <div class="list-product-card__empty-list__button-box__button">
            <commons-button
              [full]="true"
              [pink]="true"
              (buttonClick)="back()"
              buttonText="Voltar"
            >
            </commons-button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="products.length < 1 && filtersApplied.length > 0">
      <div class="list-product-card__empty-list">
        <p class="list-product-card__empty-list__title">
          Não encontramos nenhum produto que corresponda à essa seleção.
        </p>
        <p class="list-product-card__empty-list__subtitle">
          Limpe os filtros selecionados para explorar novos produtos.
        </p>
        <div class="list-product-card__empty-list__button-box">
          <div class="list-product-card__empty-list__button-box__button">
            <commons-button
              [full]="true"
              [pink]="true"
              (buttonClick)="clearFilters()"
              buttonText="Limpar Filtros"
            >
            </commons-button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
