import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'components-per-page-options',
  templateUrl: './per-page-options.component.html',
  styleUrls: ['./per-page-options.component.scss']
})
export class PerPageOptionsComponent implements OnInit {
  perPageOptions: Array<{ title: string; value: string }>;
  productsPerPage = new FormControl();

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  setProductsPerPage(option: { title: string; value: string }) {
    this.modal.close(option);
  }

  get perPageText(): string {
    return this.productsPerPage.value;
  }
}
