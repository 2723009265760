<div class="success-modal">
  <h2 class="success-modal__title">{{ title }}</h2>
  <p class="success-modal__message">{{ message }}</p>
  <div *ngIf="loggedIn" class="success-modal__button">
    <commons-button
      [pink]="true"
      buttonText="Responder o Questionário"
      [full]="true"
      (buttonClick)="modal.close()"
    >
    </commons-button>
  </div>
  <div *ngIf="!loggedIn" class="success-modal__button">
    <commons-button
      [pink]="true"
      buttonText="Cadastre-se e responda"
      [full]="true"
      (buttonClick)="modal.close()"
    >
    </commons-button>
  </div>
  <div class="success-modal__button">
    <commons-button
      [darkGray]="true"
      buttonText="Agora não"
      [full]="true"
      (buttonClick)="modal.dismiss()"
    >
    </commons-button>
  </div>
</div>
