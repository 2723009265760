import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonsModule } from '@commons/commons.module';
import { MyProfileModalComponent } from './my-profile-modal.component';

@NgModule({
  declarations: [MyProfileModalComponent],
  imports: [CommonModule, CommonsModule],
  exports: [MyProfileModalComponent]
})
export class MyProfileModalModule {}
