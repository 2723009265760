import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonsModule } from '@commons/commons.module';
import { FeaturedProductsComponent } from '@components/featured-products/featured-products.component';
import { FilterAccordionComponent } from '@components/filter-accordion/filter-accordion.component';
import { ProductCardHorizontalComponent } from '@components/product-card-horizontal/product-card-horizontal.component';
import { ProductCarouselComponent } from '@components/product-carousel/product-carousel.component';
import { ProductDescriptionAccordionComponent } from '@components/product-description-accordion/product-description-accordion.component';
import { ProductDescriptionComponent } from '@components/product-description/product-description.component';
import { ProductInfosComponent } from '@components/product-infos/product-infos.component';
import { ProductsCarouselComponent } from '@components/products-carousel/products-carousel.component';
import { ProductsVerticalCarouselComponent } from '@components/products-vertical-carousel/products-vertical-carousel.component';
import { ShippingComponent } from '@components/shipping/shipping.component';
import { ShopSliderBannerComponent } from '@components/shop-slider-banner/shop-slider-banner.component';
import { CarouselModule } from '@infrab4a/components-b4a';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import {
  NgbModalModule,
  NgbModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RecaptchaModule } from 'ng-recaptcha';
import { EllipsisModule } from 'ngx-ellipsis';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AddressFormComponent } from './address-form/address-form.component';
import { AntifraudPaymentErrorModalComponent } from './antifraud-payment-error-modal/antifraud-payment-error-modal.component';
import { BannerPromotionComponent } from './banner-promotion/banner-promotion.component';
import { BeautyProfileProductsComponent } from './beauty-profile/beauty-profile-products/beauty-profile-products.component';
import { BeautyProfileProgressComponent } from './beauty-profile/beauty-profile-progress/beauty-profile-progress.component';
import { BeautyProfileQuestionCardComponent } from './beauty-profile/beauty-profile-question-card/beauty-profile-question-card.component';
import { BenefitsInfoComponent } from './benefits-info/benefits-info.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { BillingHistoryComponent } from './billing-history/billing-history.component';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { BrandsMenuComponent } from './brands-menu/brands-menu.component';
import { CardChangeComponent } from './card-change/card-change.component';
import { CardFormModalComponent } from './card-form-modal/card-form-modal.component';
import { CardFormComponent } from './card-form/card-form.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { CartComponent } from './cart/cart.component';
import { CategoriesFiltersComponent } from './categories-filters/categories-filters.component';
import { FreightComponent } from './checkout/freight/freight.component';
import { CouponComponent } from './checkout/payment-method/coupon/coupon.component';
import { PaymentMethodComponent } from './checkout/payment-method/payment-method.component';
import { PaymentComponent } from './checkout/payment-method/payment/payment.component';
import { PersonalDataComponent } from './checkout/personal-data/personal-data.component';
import { ResumeComponent } from './checkout/resume/resume.component';
import { ShippingAddressComponent } from './checkout/shipping-address/shipping-address.component';
import { ClubBoxInfoComponent } from './club-box-info/club-box-info.component';
import { ClubBannerComponent } from './club/club-banner/club-banner.component';
import { ClubBenefitsComponent } from './club/club-benefits/club-benefits.component';
import { ClubFaqComponent } from './club/club-faq/club-faq.component';
import { ClubHowItWorksComponent } from './club/club-how-it-works/club-how-it-works.component';
import { ClubIntroductionComponent } from './club/club-introduction/club-introduction.component';
import { ClubOurSubscribersComponent } from './club/club-our-subscribers/club-our-subscribers.component';
import { ClubPlansComponent } from './club/club-plans/club-plans.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { EditionBannerComponent } from './edition-banner/edition-banner.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { FilterPriceModule } from './filter-price/filter-price.module';
import { FiltersAppliedModule } from './filters-applied/filters-applied.module';
import { FraudErrorModalComponent } from './fraud-error-modal/fraud-error-modal.component';
import { HeaderPromotionsComponent } from './header-promotions/header-promotions.component';
import { InfoPromotionComponent } from './info-promotion/info-promotion.component';
import { FooterComponent } from './layout/footer/footer/footer.component';
import { CollectionFilterComponent } from './layout/header/collection-filter/collection-filter.component';
import { FiltersModalComponent } from './layout/header/filters-modal/filters-modal.component';
import { HeaderNavigationMobileComponent } from './layout/header/header-navigation-mobile/header-navigation-mobile.component';
import { HeaderNavigationComponent } from './layout/header/header-navigation/header-navigation.component';
import { HeaderSubmenuComponent } from './layout/header/header-submenu/header-submenu.component';
import { HeaderComponent } from './layout/header/header/header.component';
import { MenuMobileComponent } from './layout/header/menu-mobile/menu-mobile.component';
import { MensHeaderComponent } from './mens-header/mens-header.component';
import { ModalCreateFavoriteComponent } from './modal-create-favorite/modal-create-favorite.component';
import { ModalDeleteWishlistComponent } from './modal-delete-favorite/modal-delete-wishlist.component';
import { ModalOptionsFavoriteComponent } from './modal-options-favorite/modal-options-favorite.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { PaymentErrorModalComponent } from './payment-error-modal/payment-error-modal.component';
import { PersonalInfosModule } from './personal-infos/personal-infos.module';
import { PlanRecurrenceCancelComponent } from './plan-recurrence-cancel/plan-recurrence-cancel.component';
import { ProductCardListComponent } from './product-card-list/product-card-list.component';
import { ProductCardModule } from './product-card/product-card.module';
import { ProductsOrderingComponent } from './products-ordering/products-ordering.component';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { RenovationModalComponent } from './renovation-modal/renovation-modal.component';
import { ReputationComponent } from './reputation/reputation.component';
import { ShippingCodeComponent } from './shipping-code/shipping-code.component';
import { ShopRatingModalComponent } from './shop-rating-modal/shop-rating-modal.component';
import { SignaturesInfoComponent } from './signatures-info/signatures-info.component';
import { SignerAlertComponent } from './signer-alert/signer-alert.component';
import { StockLimitModalComponent } from './stock-limit-modal/stock-limit-modal.component';
import { StockoutModalComponent } from './stockout-modal/stockout-modal.component';
import { SubscriptionPaymentErrorModalComponent } from './subscription-payment-error-modal/subscription-payment-error-modal.component';
import { SubscriptionPersonalDataComponent } from './subscription-personal-data/subscription-personal-data.component';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { SuccessRedirectModalComponent } from './success-redirect-modal/success-redirect-modal.component';
import { VideoPromotionComponent } from './video-promotion/video-promotion.component';
@NgModule({
  declarations: [
    FeaturedProductsComponent,
    ProductCardHorizontalComponent,
    ProductCarouselComponent,
    ProductsCarouselComponent,
    ProductDescriptionComponent,
    ProductInfosComponent,
    ShippingComponent,
    ProductDescriptionAccordionComponent,
    ShopSliderBannerComponent,
    ProductsVerticalCarouselComponent,
    FilterAccordionComponent,
    HeaderComponent,
    FooterComponent,
    HeaderNavigationComponent,
    HeaderSubmenuComponent,
    AddressFormComponent,
    CardFormComponent,
    BenefitsComponent,
    HeaderNavigationMobileComponent,
    ErrorModalComponent,
    PaymentErrorModalComponent,
    CardChangeComponent,
    CartComponent,
    CartItemComponent,
    MensHeaderComponent,
    CardFormModalComponent,
    ProductsOrderingComponent,
    ShopRatingModalComponent,
    SuccessModalComponent,
    ProfileModalComponent,
    ReputationComponent,
    PlanRecurrenceCancelComponent,
    ClubBannerComponent,
    ClubBenefitsComponent,
    ClubPlansComponent,
    ClubHowItWorksComponent,
    ClubOurSubscribersComponent,
    ClubFaqComponent,
    FreightComponent,
    CouponComponent,
    PaymentComponent,
    PaymentMethodComponent,
    PersonalDataComponent,
    ResumeComponent,
    ShippingAddressComponent,
    ClubBoxInfoComponent,
    BeautyProfileProductsComponent,
    BeautyProfileProgressComponent,
    BeautyProfileQuestionCardComponent,
    NewsletterComponent,
    ProductCardListComponent,
    CategoriesFiltersComponent,
    RenovationModalComponent,
    BillingHistoryComponent,
    RenovationModalComponent,
    SubscriptionPaymentErrorModalComponent,
    BrandsMenuComponent,
    SubscriptionPaymentErrorModalComponent,
    HeaderPromotionsComponent,
    SignaturesInfoComponent,
    BenefitsInfoComponent,
    BrandsCarouselComponent,
    ClubIntroductionComponent,
    SubscriptionPlansComponent,
    SubscriptionPersonalDataComponent,
    EditionBannerComponent,
    BannerPromotionComponent,
    InfoPromotionComponent,
    SignerAlertComponent,
    VideoPromotionComponent,
    ConfirmationModalComponent,
    SuccessRedirectModalComponent,
    AntifraudPaymentErrorModalComponent,
    StockLimitModalComponent,
    StockoutModalComponent,
    ShippingCodeComponent,
    FraudErrorModalComponent,
    CollectionFilterComponent,
    FiltersModalComponent,
    ModalCreateFavoriteComponent,
    ModalDeleteWishlistComponent,
    ModalOptionsFavoriteComponent,
    CustomToastComponent,
    MenuMobileComponent
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    CommonsModule,
    SlickCarouselModule,
    NgbModule,
    RouterModule,
    RecaptchaModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    EllipsisModule,
    FormsModule,
    CarouselModule,
    PinchZoomModule,
    NgxImageZoomModule,
    FilterPriceModule,
    FiltersAppliedModule,
    ProductCardModule,
    PersonalInfosModule
  ],
  exports: [
    FeaturedProductsComponent,
    ProductCardHorizontalComponent,
    ProductCarouselComponent,
    ProductsCarouselComponent,
    ProductDescriptionComponent,
    ProductInfosComponent,
    ShippingComponent,
    ProductDescriptionAccordionComponent,
    ShopSliderBannerComponent,
    ProductsVerticalCarouselComponent,
    FilterAccordionComponent,
    NgbModalModule,
    HeaderComponent,
    FooterComponent,
    HeaderNavigationComponent,
    AddressFormComponent,
    BenefitsComponent,
    ErrorModalComponent,
    PaymentErrorModalComponent,
    CartComponent,
    MensHeaderComponent,
    CardChangeComponent,
    CartComponent,
    CardFormModalComponent,
    ProductsOrderingComponent,
    ShopRatingModalComponent,
    PlanRecurrenceCancelComponent,
    CardFormComponent,
    ClubBannerComponent,
    ClubBenefitsComponent,
    ClubPlansComponent,
    ClubHowItWorksComponent,
    ClubOurSubscribersComponent,
    ClubFaqComponent,
    FreightComponent,
    CouponComponent,
    PaymentComponent,
    PaymentMethodComponent,
    PersonalDataComponent,
    ResumeComponent,
    ShippingAddressComponent,
    ClubBoxInfoComponent,
    BeautyProfileProductsComponent,
    BeautyProfileProgressComponent,
    BeautyProfileQuestionCardComponent,
    ProductCardListComponent,
    CategoriesFiltersComponent,
    RenovationModalComponent,
    SubscriptionPaymentErrorModalComponent,
    BrandsMenuComponent,
    HeaderPromotionsComponent,
    SignaturesInfoComponent,
    BenefitsInfoComponent,
    BrandsCarouselComponent,
    ClubIntroductionComponent,
    SubscriptionPlansComponent,
    SubscriptionPersonalDataComponent,
    EditionBannerComponent,
    BannerPromotionComponent,
    InfoPromotionComponent,
    VideoPromotionComponent,
    ConfirmationModalComponent,
    SuccessRedirectModalComponent,
    AntifraudPaymentErrorModalComponent,
    StockLimitModalComponent,
    StockoutModalComponent,
    ShippingCodeComponent,
    FraudErrorModalComponent,
    ModalCreateFavoriteComponent,
    ModalDeleteWishlistComponent,
    ModalOptionsFavoriteComponent,
    CustomToastComponent,
    MenuMobileComponent
  ]
})
export class ComponentsModule {}
