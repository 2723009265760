import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonsModule } from '@commons/commons.module';
import { SortOptionsComponent } from './sort-options.component';

@NgModule({
  declarations: [SortOptionsComponent],
  imports: [CommonModule, CommonsModule],
  exports: [SortOptionsComponent]
})
export class SortOptionsModule {}
