import { Component, Input, OnInit } from '@angular/core';
import { SortOptionsComponent } from '@components/sort-options/sort-options.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'components-sort-products',
  templateUrl: './sort-products.component.html',
  styleUrls: ['./sort-products.component.scss']
})
export class SortProductsComponent implements OnInit {
  @Input()
  isLaptop = false;

  @Input()
  sortSelected:
    | 'news'
    | 'best-sellers'
    | 'biggest-price'
    | 'lowest-price'
    | 'biggest-discount'
    | 'best-rating'
    | 'most-relevant' = 'best-sellers';

  orderOptions: Array<{ title: string; value: string; disabled: boolean }> = [
    { title: 'Novidades', value: 'news', disabled: false },
    { title: 'Mais vendidos', value: 'best-sellers', disabled: false },
    { title: 'Maior Preço', value: 'biggest-price', disabled: false },
    { title: 'Menor Preço', value: 'lowest-price', disabled: false },
    { title: 'Maior Desconto', value: 'biggest-discount', disabled: false },
    { title: 'Melhor Avaliação', value: 'best-rating', disabled: false },
    { title: 'Mais relevantes', value: 'most-relevant', disabled: false }
  ];
  showSortOptions = false;
  initialProducts: Array<string>;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  clickOnSort(): void {
    if (this.isLaptop) {
      this.showSortOptions = !this.showSortOptions;
    } else {
      const modal = this.modalService.open(SortOptionsComponent, {
        centered: true,
        modalDialogClass: 'sort-options-modal'
      });
      modal.componentInstance.orderOptions = this.orderOptions;
      modal.componentInstance.sortValue.setValue(this.sortSelected);
      modal.result.then((response) => {
        this.sortSelected = response.value;
        BroadcastUtil.get('sort').emit(response.value);
      });
    }
  }

  async selectSortOption(option: {
    title: string;
    value:
      | 'news'
      | 'best-sellers'
      | 'biggest-price'
      | 'lowest-price'
      | 'biggest-discount'
      | 'best-rating'
      | 'most-relevant';
    disabled: boolean;
  }): Promise<void> {
    this.showSortOptions = false;
    BroadcastUtil.get('sort').emit(option.value);
  }

  get sortTitle(): string {
    return this.orderOptions.find(
      (option) => option.value === this.sortSelected
    )
      ? this.orderOptions.find((option) => option.value === this.sortSelected)
          .title
      : 'Mais vendidos';
  }
}
