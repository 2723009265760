import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Provider,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

const valueAccessor: Provider = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => InputComponent)
};

@Component({
  selector: 'commons-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [valueAccessor]
})
export class InputComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild('input', { static: false, read: ElementRef })
  input: ElementRef;

  @Input()
  type = 'text';
  @Input()
  placeholder = '';
  @Input()
  id = '';
  @Input()
  name = '';
  @Input()
  control = new FormControl('');
  @Input()
  large = false;
  @Input()
  fullW = false;
  @Input()
  fullH = false;
  @Input()
  circleBorder = false;
  @Input()
  mask: string;
  @Input()
  autocomplete = 'off';
  @Input()
  saveMask = false;
  @Input()
  price = false;
  @Output()
  emitBlur = new EventEmitter();
  @Output()
  emitInput = new EventEmitter();

  @Input()
  svgPath?: string = undefined;
  @Input()
  set svgConfig(value: {
    fill: string;
    align: 'end' | 'start';
    stroke: string;
    size: 'regular' | 'small' | 'big';
  }) {
    this._svgStroke = value?.stroke;
    this._svgAlign = value?.align || 'start';
    this._svgFill = value?.fill || 'white';
    this._svgType = value?.size;
    if (value?.size === 'small') {
      this._svgHeight = 12;
      this._svgWidth = 12;
    } else if (value?.size === 'big') {
      this._svgHeight = 18;
      this._svgWidth = 18;
    } else {
      this._svgHeight = 16;
      this._svgWidth = 16;
    }
  }
  @Output()
  iconClick = new EventEmitter<void>();

  _svgFill = 'white';
  _svgAlign: 'end' | 'start' = 'start';
  _svgStroke: string;
  _svgWidth = 16;
  _svgHeight = 16;
  _svgType: 'small' | 'regular' | 'big' = 'regular';

  constructor() {}

  ngAfterViewInit(): void {
    this.control.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.onChange(value);
      this.emitInput.emit(value);
    });
  }

  blur(): void {
    this.emitBlur.emit();
  }

  get invalid(): boolean {
    return (
      this.control &&
      this.control.enabled &&
      !this.control.valid &&
      this.control.touched &&
      this.control.dirty
    );
  }

  get validColor(): boolean {
    return (
      (this.control.valid || !this.control.invalid) &&
      this.control.touched &&
      this.control.dirty &&
      this.control.value !== ''
    );
  }

  /*
   * Control value accessor methods
   */

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onChange(_: any): void {}

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onTouch = () => {};

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  writeValue(value: string): void {
    this.control.setValue(value);
  }
}
