import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'commons-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent implements OnInit {
  @Input()
  value: any;
  @Input()
  model: any;
  @Input()
  name = 'radiogroup';
  @Input()
  control = new FormControl();
  @Output()
  valueSelect = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}
}
