import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonsModule } from '@commons/commons.module';
import { FilterMyProfileComponent } from './filter-my-profile.component';

@NgModule({
  declarations: [FilterMyProfileComponent],
  imports: [CommonModule, CommonsModule],
  exports: [FilterMyProfileComponent]
})
export class FilterMyProfileModule {}
