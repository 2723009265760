<div class="brand">
  <div class="brand__body">
    <div class="brand__body__text">
      <h2>Lojinhas</h2>
      <p>
        Confira a seleção de lojinhas do nosso time de influenciadoras que são experts em beleza.
      </p>
    </div>
    <div class="brand__body__list">
      <a
        [href]="'/favoritos/' + shop.slug"
        class="brand__body__list__card"
        *ngFor="let shop of shopList"
      >
        <img
          loading="lazy"
          [src]="shop.image"
          [alt]="shop.name"
          loading="lazy"
        />
        <span class="brand__body__name">{{ shop.name }}</span><br>
        <span class="brand__body__personName">{{ shop.personName }}</span>
      </a>
    </div>
  </div>
</div>
