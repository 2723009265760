<div class="filters-applied">
  <p class="title">Filtrado por:</p>
  <div class="box" id="scroll">
    <div *ngFor="let filter of filtersApplied" class="filter-info">
      <ng-container *ngIf="getFilterType(filter) === 'default'">
        <p class="name">
          {{ filter.name }}
        </p>
      </ng-container>

      <ng-container *ngIf="getFilterType(filter) === 'rating'">
        <div class="rating">
          <app-rating-stars
            [currentRate]="filter.name"
            [yellow]="true"
            [tiny]="true"
          >
          </app-rating-stars>
          <p class="rating-text">ou mais</p>
        </div>
      </ng-container>

      <ng-container *ngIf="getFilterType(filter) === 'price'">
        <p class="name">
          R$ {{ filter.name.prices.min }} a R$
          {{ filter.name.prices.max }}
        </p>
      </ng-container>

      <div (click)="removeFilter(filter)" class="close">
        <img
          src="assets/icons/times.svg"
          alt="icone remover filtro"
          [style]="{ width: '8px', height: '8px' }"
        />
      </div>
    </div>
  </div>
</div>
