import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Shops } from '@infrab4a/connect';
import { CatalogService } from '@infrab4a/connect-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-product-back-in-stock-modal',
  templateUrl: './product-back-in-stock-modal.component.html',
  styleUrls: ['./product-back-in-stock-modal.component.scss']
})
export class ProductBackInStockModalComponent {
  title: string = 'Pode deixar que a gente te avisa';
  userEmail: string;
  userName: string;
  productId: string;
  name = new FormControl(null, [Validators.required]);

  constructor(
    public modal: NgbActiveModal,
    private notifyService: NotificationService,
    private catalogService: CatalogService
  ) {}

  registerNotification() {
    if (this.userEmail && this.userName) {
      this.catalogService.addCustomerToStockNotification(
        Shops.MENSMARKET,
        this.productId,
        this.userName,
        this.userEmail
      );
      this.showToasterWarning();
      this.modal.dismiss();
    }
  }

  showToasterWarning(): void {
    this.notifyService.showSuccess('Aviso cadastrado', '');
  }
}
