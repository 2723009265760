import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioComponent } from '../radio/radio.component';

@Component({
  selector: 'commons-radio-group',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    }
  ],
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent
  implements ControlValueAccessor, AfterContentInit
{
  @ContentChildren(RadioComponent) radios: QueryList<RadioComponent>;

  @Input() vertical = false;

  private _value: any;
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  ngAfterContentInit() {
    const name = new Date().getTime();
    this.radios.forEach((radio) => {
      radio.name = `${name}`;
      radio.checked = this.value === radio.value;
      radio.valueChange.subscribe((value) => {
        this.value = value;
        this.onChange(value);
        this.onTouched();
      });
    });
  }

  get value(): any {
    return this._value;
  }

  set value(val: any) {
    this._value = val;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
