import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProductBackInStockModalComponent } from '@components/product-back-in-stock-modal/product-back-in-stock-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MensAuthenticationService } from './mens-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AviseMeModalService {
  showAviseMeModal: boolean;
  productId: string;
  redirectUrl: string;
  isMobile: boolean;

  constructor(
    private modalService: NgbModal,
    private authService: MensAuthenticationService,
    private router: Router
  ) {}

  openModalProductBackInStock(
    userEmail: string,
    userName: string,
    productId?: string
  ) {
    if (userEmail && this.showAviseMeModal) {
      const modal = this.modalService.open(ProductBackInStockModalComponent, {
        centered: true,
        modalDialogClass: 'modal-product-back-in-stock'
      });
      modal.componentInstance.userEmail = userEmail;
      modal.componentInstance.userName = userName;
      modal.componentInstance.productId = productId || this.productId;
      modal.componentInstance.title = 'Pode deixar que a gente te avisa';
      this.resetVariables();
    }
  }

  resetVariables(): void {
    this.showAviseMeModal = false;
    this.productId = undefined;
    this.redirectUrl = undefined;
    this.isMobile = false;
  }
}
