import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { RadioComponent } from './radio/radio.component';

@NgModule({
  declarations: [RadioComponent, RadioGroupComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [RadioComponent, RadioGroupComponent]
})
export class RadioModule {}
