import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalDeleteWishlistComponent } from '@components/modal-delete-favorite/modal-delete-wishlist.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-options-favorite',
  templateUrl: './modal-options-favorite.component.html',
  styleUrls: ['./modal-options-favorite.component.scss']
})
export class ModalOptionsFavoriteComponent implements OnInit {
  isMobile = false;
  personId: string;
  wishlistId: string;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.stylePage();
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: object,
    public modal: NgbActiveModal,
    public router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.stylePage();
  }

  stylePage(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isMobile = window.innerWidth < 768;
    }
  }

  goToCreateFavorite() {
    this.modal.close();
    this.router.navigate(['/minha-conta/favoritos/favoritos-nao-criado']);
  }

  goToEdit() {
    this.modal.dismiss();
    this.router.navigate([
      `/minha-conta/favoritos/editar-favoritos/${this.personId}`
    ]);
  }

  openModalDeleteWishlist() {
    this.modal.dismiss();
    const modal = this.modalService.open(ModalDeleteWishlistComponent, {
      centered: true,
      backdrop: 'static',
      modalDialogClass: 'modal-options-favorite'
    });
    modal.componentInstance.showCloseButton = true;
    modal.componentInstance.wishlistId = this.wishlistId;
  }
}
