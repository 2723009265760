/* eslint-disable @typescript-eslint/no-floating-promises */
import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from '@infrab4a/connect';
import { CartService } from '@infrab4a/connect-angular';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'component-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {
  @Input() product: LineItem;

  @Input() isSubscriber = false;

  get productImage(): string {
    if (!this.product.image) return '../../../assets/images/no-img-brand.png';
    if (this.product.image.includes('products-images'))
      return this.product.image;

    return this.product.image
      .replace('original/', 'thumb/')
      .replace('medium/', 'thumb/')
      .replace(`.${this.product.image.split('.').pop()}`, '.webp');
  }

  constructor(
    private cartService: CartService,
    private gtmService: GoogleTagManagerService,
    private notifyService: NotificationService
  ) {}

  ngOnInit(): void {}

  addItem(): void {
    if (this.product?.quantity > 9) {
      this.showToasterWarning();
    } else {
      this.cartService.addItem(this.product).subscribe();
    }
  }
  showToasterWarning(): void {
    this.notifyService.showWarning(
      `<p>Desculpe, mas só é possível comprar até máximo 10 unidades</strong>
      do item <strong>${this.product.name}</strong></p>`,
      '',
      { positionClass: 'toast-top-full-width' }
    );
  }

  decreaseItem(): void {
    this.cartService.decreaseItem(this.product).subscribe();
  }

  deleteItem(): void {
    this.cartService.removeItem(this.product).subscribe();
    this.dataLayerEventRemoveFromCart();
  }

  get hasStock(): boolean {
    return this.product?.stock.quantity > this.product.quantity;
  }

  get stockTooltip(): string {
    return !this.hasStock
      ? `Somente ${this.product.stock.quantity} em estoque`
      : '';
  }

  dataLayerEventRemoveFromCart(): void {
    const products = [
      {
        name: this.product.name,
        id: this.product.sku,
        price: this.product.price.price,
        brand: this.product.brand,
        category: '',
        variant: '',
        quantity: this.product.quantity
      }
    ];

    const gtmTag = {
      event: 'removeFromCart',
      ecommerce: { remove: { products } }
    };

    this.gtmService.pushTag(gtmTag);
  }
}
