import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonsModule } from '@commons/commons.module';
import { ProductBackInStockModalModule } from '@components/product-back-in-stock-modal/product-back-in-stock-modal.module';
import { EllipsisModule } from 'ngx-ellipsis';
import { ProductCardComponent } from './product-card.component';

@NgModule({
  declarations: [ProductCardComponent],
  exports: [ProductCardComponent],
  imports: [
    CommonModule,
    CommonsModule,
    RouterModule,
    EllipsisModule,
    ProductBackInStockModalModule
  ]
})
export class ProductCardModule {}
