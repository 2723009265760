<div *ngIf="menuOpened">
  <div (click)="onOverlay()" class="menu-mobile-overlay"></div>
  <div [class.menu-mobile--close]="menuClosed" class="menu-mobile">
    <div class="menu-mobile__login-infos">
      <div class="menu-mobile__login-infos__login-avatar">
        <ng-container *ngIf="!userLogged">
          <div
            (click)="login()"
            class="menu-mobile__login-infos__login-avatar__unlogged"
          >
            <img
              src="assets/icons/unlogged-icon.svg"
              [style]="{ width: '20px', height: '20px' }"
              alt="Não logado"
            />

            <p class="menu-mobile__login-infos__login-avatar__unlogged__text">
              Entre ou cadastre-se
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="userLogged">
          <div
            (click)="openLoggedMenu()"
            class="menu-mobile__login-infos__login-avatar__logged"
          >
            <div
              class="menu-mobile__login-infos__login-avatar__logged__content"
            >
              <app-personal-infos [user]="user"></app-personal-infos>

              <img
                *ngIf="!loggedMenuOpened"
                src="assets/icons/button-chevron-down.svg"
                [style]="{ width: '14px', height: '23px' }"
                alt="Expandir"
              />
              <img
                *ngIf="loggedMenuOpened"
                src="assets/icons/button-chevron-up.svg"
                [style]="{ width: '14px', height: '23px' }"
                alt="Recolher"
              />
            </div>
            <ng-container *ngIf="loggedMenuOpened">
              <div
                class="menu-mobile__login-infos__login-avatar__logged__menu"
                (click)="goTo(item.link)"
                *ngFor="let item of loggedMenuItems"
              >
                <p>{{ item.name }}</p>
              </div>
              <hr class="menu-mobile__divider" />
              <div
                class="menu-mobile__login-infos__login-avatar__logged__menu"
                (click)="logout($event)"
              >
                <p>Sair</p>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="!brandsMenuOpened">
      <div class="menu-mobile__menus">
        <div class="menu-mobile__menus__item">
          <div
            (click)="openShopMenu()"
            class="menu-mobile__menus__item__content"
          >
            <p class="menu-mobile__menus__item__content__title">Loja</p>
            <img
              *ngIf="!shopMenuOpened"
              src="assets/icons/button-chevron-down.svg"
              [style]="{ width: '14px', height: '23px' }"
              alt="Expandir"
            />
            <img
              *ngIf="shopMenuOpened"
              src="assets/icons/button-chevron-up.svg"
              [style]="{ width: '14px', height: '23px' }"
              alt="Recolher"
            />
          </div>
          <ng-container *ngIf="shopMenuOpened">
            <ng-container *ngFor="let firstSubMenu of routesShop">
              <div
                *ngIf="!firstSubMenu.hideMobile"
                class="menu-mobile__menus__item__submenu"
              >
                <div (click)="selectMenu(firstSubMenu)">
                  <div class="menu-mobile__menus__item__submenu__content">
                    <p
                      class="menu-mobile__menus__item__submenu__content__label"
                    >
                      {{ firstSubMenu?.label }}
                    </p>
                    <img
                      *ngIf="firstSubMenu !== selectedMenu"
                      src="assets/icons/button-chevron-down.svg"
                      [style]="{ width: '14px', height: '23px' }"
                      alt="Expandir"
                    />
                    <img
                      *ngIf="firstSubMenu === selectedMenu"
                      src="assets/icons/button-chevron-up.svg"
                      [style]="{ width: '14px', height: '23px' }"
                      alt="Recolher"
                    />
                  </div>
                </div>
                <ng-container *ngIf="firstSubMenu === selectedMenu">
                  <div
                    (click)="goTo(selectedMenu.path)"
                    class="menu-mobile__menus__item__submenu__content"
                    [class.menu-mobile__menus__item__submenu__content--first-level]="
                      true
                    "
                  >
                    <p
                      class="menu-mobile__menus__item__submenu__content__label"
                    >
                      Ver tudo
                    </p>
                  </div>
                  <div *ngFor="let secondSubMenu of selectedMenu?.subMenu">
                    <div (click)="selectSubMenu(secondSubMenu)">
                      <div
                        class="menu-mobile__menus__item__submenu__content"
                        [class.menu-mobile__menus__item__submenu__content--first-level]="
                          true
                        "
                      >
                        <p
                          class="menu-mobile__menus__item__submenu__content__label"
                        >
                          {{ secondSubMenu?.label }}
                        </p>
                        <img
                          *ngIf="
                            secondSubMenu !== selectedSubMenu &&
                            secondSubMenu.subChildMenu.length
                          "
                          src="assets/icons/button-chevron-down.svg"
                          [style]="{ width: '14px', height: '23px' }"
                          alt="Expandir"
                        />
                        <img
                          *ngIf="
                            secondSubMenu === selectedSubMenu &&
                            secondSubMenu.subChildMenu.length
                          "
                          src="assets/icons/button-chevron-up.svg"
                          [style]="{ width: '14px', height: '23px' }"
                          alt="Recolher"
                        />
                      </div>
                    </div>
                    <ng-container *ngIf="secondSubMenu === selectedSubMenu">
                      <div
                        (click)="goTo(selectedSubMenu.path)"
                        class="menu-mobile__menus__item__submenu__content"
                        [class.menu-mobile__menus__item__submenu__content--second-level]="
                          true
                        "
                      >
                        <p
                          class="menu-mobile__menus__item__submenu__content__label"
                        >
                          Ver tudo
                        </p>
                      </div>
                      <div
                        *ngFor="
                          let subChildMenu of selectedSubMenu?.subChildMenu
                        "
                      >
                        <div (click)="selectSecondSubMenu(subChildMenu)">
                          <div
                            class="menu-mobile__menus__item__submenu__content"
                            [class.menu-mobile__menus__item__submenu__content--second-level]="
                              true
                            "
                          >
                            <p
                              class="menu-mobile__menus__item__submenu__content__label"
                            >
                              {{ subChildMenu?.label }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <div class="menu-mobile__menus__item__submenu">
              <div (click)="openBrandsMenu()">
                <div class="menu-mobile__menus__item__submenu__content">
                  <p class="menu-mobile__menus__item__submenu__content__label">
                    Marcas
                  </p>
                </div>
              </div>
            </div>
            <div class="menu-mobile__menus__item__submenu">
              <div (click)="goTo('/lojinhas')">
                <div class="menu-mobile__menus__item__submenu__content">
                  <p class="menu-mobile__menus__item__submenu__content__label">
                    Lojinhas
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="menu-mobile__menus__item">
          <div
            (click)="openClubMenu()"
            class="menu-mobile__menus__item__content"
          >
            <p
              class="menu-mobile__menus__item__content__title"
              [class.menu-mobile__menus__item__content__title--club]="true"
            >
              Clube
            </p>
            <img
              *ngIf="!clubMenuOpened"
              src="assets/icons/button-chevron-down.svg"
              [style]="{ width: '14px', height: '23px' }"
              alt="Expandir"
            />
            <img
              *ngIf="clubMenuOpened"
              src="assets/icons/button-chevron-up.svg"
              [style]="{ width: '14px', height: '23px' }"
              alt="Recolher"
            />
          </div>
          <ng-container *ngIf="clubMenuOpened">
            <div
              *ngFor="let clubMenu of clubRoutes"
              class="menu-mobile__menus__item__submenu"
            >
              <div (click)="goTo(clubMenu.path, clubMenu.query)">
                <div class="menu-mobile__menus__item__submenu__content">
                  <p class="menu-mobile__menus__item__submenu__content__label">
                    {{ clubMenu.label }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- <ng-container *ngIf="clubMenuOpened && userLogged">
            <div
              *ngFor="let clubMenu of routesLogged"
              class="menu-mobile__menus__item__submenu"
            >
              <div
                (click)="
                  clubMenu.path
                    ? goTo(clubMenu.path)
                    : (glampointsMenuOpened = !glampointsMenuOpened)
                "
              >
                <div class="menu-mobile__menus__item__submenu__content">
                  <p class="menu-mobile__menus__item__submenu__content__label">
                    {{ clubMenu.label }}
                  </p>
                  <img
                    *ngIf="clubMenu.subChildMenu && !glampointsMenuOpened"
                    src="assets/icons/button-chevron-down.svg"
                    [style]="{ width: '14px', height: '23px' }"
                    alt="Expandir"
                  />
                  <img
                    *ngIf="clubMenu.subChildMenu && glampointsMenuOpened"
                    src="assets/icons/button-chevron-up.svg"
                    [style]="{ width: '14px', height: '23px' }"
                    alt="Recolher"
                  />
                </div>
              </div>
            </div>
          </ng-container> -->
        </div>
        <div class="menu-mobile__menus__item">
          <div
            (click)="openHelpMenu()"
            class="menu-mobile__menus__item__content"
          >
            <p class="menu-mobile__menus__item__content__title">Ajuda</p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="brandsMenuOpened">
      <div class="menu-mobile__menus">
        <div class="menu-mobile__menus__item">
          <div
            (click)="brandsMenuOpened = !brandsMenuOpened"
            class="menu-mobile__menus__item__content"
          >
            <div class="menu-mobile__menus__item__content--brands-menu">
              <img
                src="assets/icons/button-chevron-left.svg"
                [style]="{ width: '8px', height: '15px' }"
                alt="Seta apontando para esquerda"
              />
              <p class="menu-mobile__menus__item__content__title">Marcas</p>
            </div>
          </div>
        </div>
      </div>
      <components-brands-menu
        [mobileMenu]="true"
        [bottomBorder]="false"
        [mobile]="true"
        [showBottomText]="false"
        [brandsMenu]="brands"
        (closeMenu)="toggleMenuMobile()"
      >
      </components-brands-menu>
    </ng-container>
  </div>
</div>
