import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'components-mens-header',
  templateUrl: './mens-header.component.html',
  styleUrls: ['./mens-header.component.scss']
})
export class MensHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
