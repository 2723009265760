import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  errorMessage = 'Houve um erro';
  errorDescription = 'Tente novamente';
  buttonText = 'Entendi';
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
