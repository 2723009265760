<section *ngIf="!user?.acceptsNewsletter" class="newsletter">
  <components-newsletter></components-newsletter>
</section>
<div class="footer">
  <div class="footer-row">
    <div class="box">
      <div class="row-accordion">
        <img
          class="mens-img"
          src="assets/images/mens_branca.png"
          alt="Mens Market"
        />

        <div class="accordion-box">
          <ngb-accordion [activeIds]="activeIds">
            <ngb-panel
              [id]="isMobile ? 'static-1' : 'same'"
              cardClass="card-1x1"
            >
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Universo Men's</h4>
              </ng-template>

              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Universo Men's</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>

              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <ul>
                    <li>
                      <a href="/pages/clube" title="Clube da Men's"
                        >Clube da Men's</a
                      >
                    </li>
                    <li>
                      <a href="" title="Men's Market">Men's Market</a>
                    </li>
                    <li>
                      <a href="https://mens.com.br/" title="Mens">Mens</a>
                    </li>
                    <li>
                      <a href="https://usemalmo.com.br/" title="Malmo">Malmo</a>
                    </li>
                    <li>
                      <a href="https://blog.mensmarket.com.br" title="Conteúdo"
                        >Conteúdo</a
                      >
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>

            <ngb-panel
              [id]="isMobile ? 'static-2' : 'same'"
              cardClass="card-1x1"
            >
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Institucional</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Institucional</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <ul>
                    <li>
                      <a href="/pages/sobre-nos" title="Sobre nós">Sobre nós</a>
                    </li>
                    <li>
                      <a href="/pages/imprensa" title="Imprensa">Imprensa</a>
                    </li>
                    <li>
                      <a
                        href="/pages/politica-de-privacidade"
                        title="Política de Privacidade"
                        >Política de Privacidade</a
                      >
                    </li>
                    <li>
                      <a
                        href="/pages/termos-e-condicoes-de-uso"
                        title="Termos e condições de
                      uso"
                        >Termos e condições de uso</a
                      >
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>

            <ngb-panel
              [id]="isMobile ? 'static-3' : 'same'"
              cardClass="card-1x1"
            >
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Podemos te ajudar?</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Podemos te ajudar?</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <ul>
                    <li>
                      <a href="/sac" title="Entre em contato"
                        >Entre em contato</a
                      >
                    </li>
                    <li>
                      <div *ngIf="!user; else loggedtemplate">
                        <a title="Meus Pedidos" (click)="openLoginModal()"
                          >Meus Pedidos</a
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>

            <ngb-panel
              [id]="isMobile ? 'static-4' : 'same'"
              cardClass="card-1x1"
            >
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Redes sociais</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Redes sociais</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <div class="social-list">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/mensmarket"
                      title="Men's Market em Facebook"
                    >
                      <img
                        src="assets/images/account/facebook.svg"
                        alt="icon-pay-1_alt"
                        class="icons"
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/user/mensmarketbr"
                      title="Men's Market em YouTube"
                    >
                      <img
                        src="assets/images/account/youtube.png"
                        alt="icon-pay-2_alt"
                        class="icons"
                      />
                    </a>
                    <a
                      target="_blank"
                      href="http://instagram.com/mensmarket/"
                      title="Men's Market em Instagram"
                    >
                      <img
                        src="assets/images/account/instagram.svg"
                        alt="icon-pay-3_alt"
                        class="icons"
                      />
                    </a>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>

            <ngb-panel
              [id]="isMobile ? 'static-5' : 'same'"
              cardClass="card-1x1"
            >
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Pague com</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Pague com</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <div class="social-list">
                    <img
                      src="assets/images/account/card_white.svg"
                      alt="icon-pay-1_alt"
                      class="icons"
                    />
                    <img
                      src="assets/images/account/boleto.svg"
                      alt="icon-pay-2_alt"
                      class="icons"
                    />
                    <img
                      src="assets/images/account/pix.svg"
                      alt="icon-pay-3_alt"
                      class="icons"
                    />
                  </div>
                </div>
              </ng-template>
            </ngb-panel>

            <ngb-panel
              [id]="isMobile ? 'static-6' : 'same'"
              cardClass="card-1x1"
              *ngIf="!isMobile"
            >
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Reputação</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Reputação</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <b4a-reputation></b4a-reputation>
                </div>
              </ng-template>
            </ngb-panel>

            <ngb-panel
              [id]="isMobile ? 'static-8' : 'same'"
              cardClass="card-1x1"
              *ngIf="isMobile"
            >
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Reputação</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Reputação</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <b4a-reputation></b4a-reputation>
                </div>
              </ng-template>
            </ngb-panel>

            <ngb-panel
              [id]="isMobile ? 'static-7' : 'same'"
              cardClass="card-1x2"
            >
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Segurança</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Segurança</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <div class="social-list security-icons">
                    <img
                      src="assets/images/account/google-safe.png"
                      alt="icon-pay-1_alt"
                      style="height: 27px; margin: 15px 0px 0px 7px"
                    />
                    <img
                      src="assets/images/account/pagar-me.png"
                      alt="icon-pay-2_alt"
                      style="height: 40px; margin: 9px 0px 0px 7px"
                    />
                    <img
                      src="assets/images/account/ssl.png"
                      alt="icon-pay-3_alt"
                      style="height: 37px; margin: 10px 0px 0px 7px"
                    />
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
      <!--address estava aqui-->
    </div>
    <div class="box box--right">
      <div class="row-accordion">
        <img
          class="mens-img"
          src="assets/images/logo_powered.png"
          alt="B4A Connect"
        />
        <div class="accordion-box">
          <ngb-accordion [activeIds]="'same'">
            <ngb-panel [id]="isMobile ? 'static-1' : 'same'">
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">Universo Glam</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">Universo Glam</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <ul>
                    <li>
                      <a href="https://www.glambox.com.br/" title="Glambox"
                        >Glambox</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://glambox.com.br/clube/glampoints"
                        title="Glampoints"
                        >Glampoints</a
                      >
                    </li>
                    <li>
                      <a href="https://glambox.com.br/loja" title="Loja"
                        >Loja</a
                      >
                    </li>
                    <li>
                      <a href="https://beauty.glam.com.br/" title="Glam Beauty"
                        >Glam Beauty</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://glambox.com.br/clube/conteudo/mag"
                        title="Glam Mag"
                        >Glam Mag</a
                      >
                    </li>
                    <li>
                      <a href="https://ella.com.br/" title="Ella">Ella</a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel [id]="isMobile ? 'static-2' : 'same'">
              <ng-template *ngIf="!isMobile" ngbPanelHeader>
                <h4 class="title">B4A</h4>
              </ng-template>
              <ng-template *ngIf="isMobile" ngbPanelTitle>
                <h4 class="title">B4A</h4>
                <div class="toggle-arrow">
                  <i class="fas fa-chevron-right">
                    <svg-icon
                      *ngIf="isPlatformBrowser"
                      src="assets/icons/button-chevron-down-white.svg"
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 8,
                        color: 'white'
                      }"
                    >
                    </svg-icon>
                  </i>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="collapsed-content">
                  <ul>
                    <li>
                      <a href="https://b4a.com.br/" title="A empresa"
                        >A empresa</a
                      >
                    </li>
                    <li>
                      <a href="https://b4a.com.br/#brands" title=""
                        >Para marcas</a
                      >
                    </li>
                    <li>
                      <a href="https://b4a.com.br/#b4aconnect" title=""
                        >B4A Connect</a
                      >
                    </li>
                    <li>
                      <a href="https://b4a.com.br/#influencers" title=""
                        >Influenciadores</a
                      >
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
      <!--<div class="copy-right" *ngIf="isMobile" style="margin-top: 20px">
        <p class="copy-right__text">
          B4A Serviços de Tecnologia e Comércio S.A. Rua Pequetita, 111 - Vila Olímpia - São Paulo - SP / Brasil
        </p>
      </div>-->
    </div>
  </div>
  <div class="copy-right" style="margin-top: 5px">
    <p class="copy-right__text">
      B4A Serviços de Tecnologia e Comércio S.A. - 13.475.001/0001-34 - MMKT
      COMÉRCIO DE PRODUTOS DE BELEZA E SERVIÇOS DE CABELEIREIRO LTDA. -
      15.481.147/0001-18 - Avenida Jornalista Roberto Marinho, nº.: 85, 17º
      Andar (Conjuntos 171 e 172), Cidade Monções - CEP 04576-010 - Cidade de
      São Paulo, Estado de São Paulo
    </p>
  </div>
</div>

<ng-template #loggedtemplate>
  <a [routerLink]="['/minha-conta/meus-pedidos']" title="Meus Pedidos"
    >Meus Pedidos</a
  >
</ng-template>

<ng-template #reputacao>
  <ng-template *ngIf="!isMobile" ngbPanelHeader>
    <h4 class="title">Reputação</h4>
  </ng-template>
  <ng-template *ngIf="isMobile" ngbPanelTitle>
    <h4 class="title">Reputação</h4>
    <div class="toggle-arrow">
      <i class="fas fa-chevron-right">
        <svg-icon
          *ngIf="isPlatformBrowser"
          src="assets/icons/button-chevron-down-white.svg"
          [svgStyle]="{ 'width.px': 15, 'height.px': 8, color: 'white' }"
        >
        </svg-icon>
      </i>
    </div>
  </ng-template>
  <ng-template ngbPanelContent>
    <div class="collapsed-content">
      <b4a-reputation></b4a-reputation>
    </div>
  </ng-template>
</ng-template>
