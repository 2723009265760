<div class="product-card-horizontal">
  <a
    class="product-card-horizontal__container"
    [routerLink]="'/products/' + product.slug"
  >
    <div class="product-card-horizontal__img-container">
      <img [src]="productImage" [alt]="product.name" />
    </div>
    <div class="product-card-horizontal__box">
      <p class="product-card-horizontal__brand">{{ product.brand }}</p>
      <p class="product-card-horizontal__name">{{ product.name }}</p>
      <div>
        <app-rating-stars
          [currentRate]="computedRating"
          [yellow]="true"
          [small]="true"
        >
        </app-rating-stars>
      </div>
      <div class="product-card-horizontal__price-container">
        <p class="product-card-horizontal__price" *ngIf="shouldShowFullPrice">
          <span class="product-card-horizontal__price--label">De:</span>
          <span class="product-card-horizontal__price--value__old-value">{{
            product.price?.fullPrice | currency: 'BRL'
          }}</span>
        </p>
        <p class="product-card-horizontal__price">
          <span class="product-card-horizontal__price--label">Por:</span>
          <span class="product-card-horizontal__price--value bold">{{
            product.price?.price | currency: 'BRL'
          }}</span>
        </p>
        <p
          *ngIf="product.price?.subscriberPrice"
          class="product-card-horizontal__price product-card-horizontal__price--new"
        >
          <span class="product-card-horizontal__price--label bold">Clube:</span>
          <span class="product-card-horizontal__price--value bold">{{
            product.price?.subscriberPrice | currency: 'BRL'
          }}</span>
        </p>
      </div>
    </div>
  </a>
</div>
