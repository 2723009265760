import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product, Wishlist } from '@infrab4a/connect';
import { WishlistService } from '@infrab4a/connect-angular';

@Injectable({
  providedIn: 'root'
})
export class WishlistProductsService {
  private wishlist: Wishlist[];

  constructor(
    private wishListService: WishlistService,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  async getWishlistByUserId(id: string): Promise<Wishlist[]> {
    try {
      this.wishlist = await this.wishListService.getWishlistsByPerson(id);

      return this.wishlist;
    } catch (error) {
      console.info(error.message);
    }
  }

  addIsWishlistToProduct(
    products: (Product & { isWishlist?: boolean })[]
  ): any {
    if (!this.wishlist?.length) return products;
    const wishlistProducts = this.wishlist[0]?.products;

    if (!wishlistProducts?.length) return products;
    return products.map((product) => {
      product.isWishlist = wishlistProducts.includes(product.id);
      return product;
    });
  }

  async addIsWishlistToProductHome(
    products: (Product & { isWishlist?: boolean })[],
    user?
  ) {
    if (user) {
      this.wishlist = await this.getWishlistByUserId(user.id);
    }
    if (!this.wishlist) return products;
    const wishlistProducts = this.wishlist[0];

    if (!wishlistProducts?.products) return products;
    return products.map((product) => {
      product.isWishlist = wishlistProducts.products.includes(product.id);
      return product;
    });
  }
}
