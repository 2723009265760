import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Product } from '@infrab4a/connect';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { debounceTime } from 'rxjs/operators';
import { FiltersService } from 'src/app/services/filters.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'components-filter-price',
  templateUrl: './filter-price.component.html',
  styleUrls: ['./filter-price.component.scss']
})
export class FilterPriceComponent implements OnInit {
  @Input()
  title = '';

  @Input()
  isMobileFilter = false;

  @Input()
  categoryTitle: string;

  prices: {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  };
  products: Array<Product>;
  isCollapsed = false;
  lessPrice = new FormControl(null, [Validators.required]);
  mostPrice = new FormControl(null, [Validators.required]);
  lessPricePlaceholder: number | string;
  mostPricePlaceholder: number | string;
  subsPrice = new FormControl(1);
  clearDisable = false;
  pricesOriginal: {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  };
  radios: Array<{ text: string; id: number }> = [
    {
      text: 'Preço assinante',
      id: 1
    },
    {
      text: 'Preço não assinante',
      id: 2
    }
  ];

  constructor(
    private filtersService: FiltersService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.prices = this.filtersService.getPrices();
    if (this.prices) {
      if (
        !isNaN(this.prices.subscriberPrice.min) &&
        !isNaN(this.prices.subscriberPrice.max)
      ) {
        this.lessPricePlaceholder = Math.trunc(this.prices.subscriberPrice.min);
        this.mostPricePlaceholder = Math.trunc(this.prices.subscriberPrice.max);
      } else {
        this.lessPrice.disable();
        this.mostPrice.disable();
        this.lessPricePlaceholder = '';
        this.mostPricePlaceholder = '';
      }
      this.pricesOriginal = this.filtersService.getPricesOriginal();
      this.clearDisable = true;
    }
    if (
      this.filtersService.getFiltersApplied().find((o) => o.tag === 'price')
    ) {
      this.lessPrice.setValue(this.min);
      this.mostPrice.setValue(this.max);
      this.subsPrice.setValue(this.filtersService.getSubscriberPrice() ? 1 : 2);
      this.clearDisable = false;
    }

    this.lessPrice.valueChanges.pipe(debounceTime(1500)).subscribe((val) => {
      this.filtersService.setIsFilterPrice(true);
      this.filtersService.addMinPrice(Number(this.lessPrice.value || 0));
      if (!this.showMessageError) {
        if (
          this.filtersService.getFiltersApplied().find((o) => o.tag === 'price')
        ) {
          this.filtersService.removeFiltersAppliedByTag('price', false);
        }
        this.filtersService.addFiltersApplied({
          name: {
            prices: {
              min: this.filtersService.getMinPrice(),
              max: this.filtersService.getMaxPrice()
            }
          },
          tag: 'price'
        });
      }
    });

    this.mostPrice.valueChanges.pipe(debounceTime(1500)).subscribe((val) => {
      this.filtersService.setIsFilterPrice(true);
      this.filtersService.addMaxPrice(Number(val || 0));
      if (!this.showMessageError) {
        if (
          this.filtersService.getFiltersApplied().find((o) => o.tag === 'price')
        ) {
          this.filtersService.removeFiltersAppliedByTag('price', false);
        }
        this.filtersService.addFiltersApplied({
          name: {
            prices: {
              min: this.filtersService.getMinPrice(),
              max: this.filtersService.getMaxPrice()
            }
          },
          tag: 'price'
        });
      }
    });

    this.subsPrice.valueChanges.subscribe((value) =>
      this.changePrice({ id: value })
    );
    BroadcastUtil.get('setValuePrices').subscribe((response) => {
      this.subsPrice.setValue(response.id);
    });
  }

  onLessPriceInput(): void {
    this.filtersService.setIsFilterPrice(true);
    this.filtersService.addMinPrice(Number(this.lessPrice.value || 0));

    this.clearDisable = false;
  }

  onMostPriceInput(): void {
    this.filtersService.setIsFilterPrice(true);
    this.filtersService.addMaxPrice(Number(this.mostPrice.value));

    this.clearDisable = false;
  }

  changePrice(item: { id: number }): void {
    if (item.id === 2) {
      this.lessPricePlaceholder = Math.trunc(this.pricesOriginal.price.min);
      this.mostPricePlaceholder = Math.trunc(this.pricesOriginal.price.max);
      this.getNotSubsPrices();
    } else {
      this.lessPricePlaceholder = Math.trunc(
        this.pricesOriginal.subscriberPrice.min
      );
      this.mostPricePlaceholder = Math.trunc(
        this.pricesOriginal.subscriberPrice.max
      );
      this.getSubsPrices();
    }
    this.filtersService.filterEmit(false);
  }

  getSubsPrices(): void {
    this.filtersService.addMinPrice(Number(this.lessPrice.value));
    this.filtersService.addMaxPrice(Number(this.mostPrice.value));
    this.filtersService.setSubscriberPrice(true);
    this.clearDisable = true;
  }

  getNotSubsPrices(): void {
    this.filtersService.addMinPrice(Number(this.lessPrice.value));
    this.filtersService.addMaxPrice(Number(this.mostPrice.value));
    this.filtersService.setSubscriberPrice(false);
    this.clearDisable = false;
  }

  clear(): void {
    this.lessPricePlaceholder = Math.trunc(
      this.pricesOriginal.subscriberPrice.min
    );
    this.mostPricePlaceholder = Math.trunc(
      this.pricesOriginal.subscriberPrice.max
    );
    this.filtersService.setSubscriberPrice(true);
    this.clearDisable = true;
    this.lessPrice.setValue(null);
    this.mostPrice.setValue(null);
    this.subsPrice.setValue(1);
    this.filtersService.removeFiltersAppliedByTag('price', true, true);
  }

  removeBadCharacters(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .replace(/\s/g, '-')
      .replace(/[éèê&]/g, 'e')
      .replace(/[óòõôö]/g, 'o')
      .replace(/[íìîï]/g, 'i')
      .replace(/[áàãäâ]/g, 'a')
      .replace(/[úùüû]/g, 'u')
      .replace(/[$%&#@!*+=]/g, '')
      .replace(/ç/g, 'c');
  }

  get showMessageError(): boolean {
    return Number(this.mostPrice.value) < Number(this.lessPrice.value);
  }

  get svgIcon(): string {
    return this.isCollapsed
      ? 'assets/icons/arrow-down.svg'
      : 'assets/icons/arrow-up.svg';
  }

  get altIcon(): string {
    return this.isCollapsed ? 'icon seta para baixo' : 'icon seta para cima';
  }

  get min(): number {
    return this.filtersService
      .getFiltersApplied()
      .find((o) => o.tag === 'price').name.prices.min;
  }

  get max(): number {
    return this.filtersService
      .getFiltersApplied()
      .find((o) => o.tag === 'price').name.prices.max;
  }
}
