<div
  *ngIf="state$ | async as s"
  class="cart-container"
  [ngStyle]="{ height: s.screenSize.height + 'px' }"
  [ngClass]="
    s.flags.isMobile ? 'cart-container__mobile' : 'cart-container__desktop'
  "
>
  <div class="cart-container__cart-body">
    <div class="cart-container__cart-body__wrap">
      <div class="cart-container__cart-body__header">
        <div>
          <a
            class="cart-container__cart-body__header__close"
            *ngIf="s.flags.isMobile"
            (click)="onClickCloseCart()"
          >
            <svg-icon
              [svgStyle]="{ 'width.px': 9, 'height.px': 18 }"
              src="assets/icons/button-chevron-left.svg"
            >
            </svg-icon>
          </a>
          <div class="cart-container__cart-body__header__title">
            <h1>Minha Sacola</h1>
            <p>Total de itens: {{ s.totals.totalQuantity }}</p>
          </div>
        </div>

        <a *ngIf="!s.flags.isMobile" (click)="onClickCloseCart()">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.744 0L8 5.744L2.256 0L0 2.256L5.744 8L0 13.744L2.256 16L8 10.256L13.744 16L16 13.744L10.256 8L16 2.256L13.744 0Z"
              fill="#464A52"
            />
          </svg>
        </a>
      </div>
      <div class="cart-container__cart-body__product-list">
        <ng-template ngFor let-item [ngForOf]="s.lineItems">
          <component-cart-item
            [isSubscriber]="s.flags.isSubscriber"
            [product]="item"
          ></component-cart-item>
        </ng-template>
      </div>
    </div>
    <div class="cart-container__cart-body__resume">
      <div class="cart-container__cart-body__resume__totals">
        <span class="cart-container__cart-body__resume__total-label"
          >Valor dos produtos:</span
        >
        <span class="cart-container__cart-body__resume__total-value">{{
          s.totals.totalPrice | currency: 'BRL'
        }}</span>
      </div>

      <commons-button
        class="mt-4"
        [disabled]="s.lineItems.length < 1"
        (buttonClick)="onClickGoToCheckout()"
        buttonText="Finalizar Compra"
        [full]="true"
        [darkPink]="true"
      >
      </commons-button>
      <commons-button
        class="mt-4"
        (buttonClick)="onClickCloseCart()"
        buttonText="Continuar comprando"
        [full]="true"
        [darkGray]="true"
      >
      </commons-button>
    </div>
  </div>
</div>
