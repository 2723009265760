import { Injectable } from '@angular/core';
import { GlobalConfig, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  toastrConfig: GlobalConfig;
  private readonly defaultConfig = {
    positionClass: 'toast-top-full-width'
  };

  constructor(private toastr: ToastrService) {}

  showSuccess(message, title) {
    this.toastr.success(message, title, {
      tapToDismiss: true,
      positionClass: 'toast-top-right',
      toastClass: 'toast-icon custom-toast-success',
      closeButton: false
    });
  }

  showError(message, title, options = this.defaultConfig) {
    this.toastr.error(message, title, options);
  }

  showInfo(message, title) {
    this.toastr.info(message, title);
  }
  showCustom(message, title, override: Partial<IndividualConfig> = {}) {
    this.toastr.show(message, title, this.applyConfig(override));
  }

  showWarning(message, ToastConfig, positionClass) {
    this.toastr.warning(message, ToastConfig, positionClass);
  }

  private applyConfig(override: Partial<IndividualConfig> = {}): GlobalConfig {
    return { ...this.toastrConfig, ...override };
  }
}
