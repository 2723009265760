import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {
  title = 'Atenção';
  message =
    "Responda o Questionário da Men's para ativar a função e ver produtos recomendados para o seu perfil";
  loggedIn = false;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
