import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-create-favorite',
  templateUrl: './modal-create-favorite.component.html',
  styleUrls: ['./modal-create-favorite.component.scss']
})
export class ModalCreateFavoriteComponent implements OnInit {
  isMobile = false;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.stylePage();
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: object,
    public modal: NgbActiveModal,
    public router: Router
  ) {}

  ngOnInit() {
    this.stylePage();
  }

  stylePage(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isMobile = window.innerWidth < 768;
    }
  }

  goToCreateFavorite() {
    this.modal.close();
    this.router.navigate(['/minha-conta/favoritos/criar-favoritos']);
  }
}
