import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { User } from '@infrab4a/connect';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '@pages/login/login.component';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';

@Component({
  selector: 'components-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isMobile = false;
  user: User;
  activeIds = ['static-7', 'static-8', 'same'];
  isPlatformBrowser: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(): void {}

  constructor(
    private authService: MensAuthenticationService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isPlatformBrowser = true;
      this.authService.authState$.subscribe((authState) => {
        this.user = authState?.user;
      });
    }
  }

  openLoginModal(): void {
    const ref = this.modalService.open(LoginComponent);
    ref.componentInstance.modal = true;
    ref.componentInstance.redirectUrl = '/minha-conta/meus-pedidos';
  }
}
