import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonsModule } from '@commons/commons.module';
import { ComponentsModule } from '@components/components.module';
import { FilterPriceModule } from '@components/filter-price/filter-price.module';
import { FiltersAppliedModule } from '@components/filters-applied/filters-applied.module';
import { MyProfileModalModule } from '@components/my-profile-modal/my-profile-modal.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CheckoutLayoutComponent } from './checkout-layout/checkout-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    AngularSvgIconModule,
    CommonsModule,
    NgbModule,
    ComponentsModule,
    AccordionModule.forRoot(),
    MyProfileModalModule,
    FilterPriceModule,
    FiltersAppliedModule
  ],
  declarations: [MainLayoutComponent, CheckoutLayoutComponent]
})
export class LayoutModule {}
