<div class="commons-input">
  <svg-icon
    (click)="iconClick.emit()"
    *ngIf="svgPath !== undefined && svgPath !== null && _svgAlign === 'start'"
    [src]="svgPath"
    [svgStyle]="{
      'width.px': _svgWidth,
      fill: _svgFill,
      stroke: _svgStroke,
      'height.px': _svgHeight
    }"
    class="svg-icon -start -{{ _svgType }}"
    [class.-l2]="circleBorder"
  />
  <input
    #input
    class="input"
    [type]="type"
    [name]="name"
    [id]="id"
    [placeholder]="placeholder"
    [class.-fullW]="fullW"
    [class.-fullH]="fullH"
    [class.-circle]="circleBorder"
    [class.-invalid]="invalid"
    [class.-validColor]="validColor"
    [class.-price]="price"
    [formControl]="control"
    (touchend)="onTouch()"
    *ngIf="mask"
    [mask]="mask"
    (blur)="blur()"
    [autocomplete]="autocomplete"
    [dropSpecialCharacters]="!saveMask"
  />
  <input
    #input
    class="input"
    [type]="type"
    [name]="name"
    [id]="id"
    [placeholder]="placeholder"
    [class.-fullW]="fullW"
    [class.-fullH]="fullH"
    [class.-circle]="circleBorder"
    [class.-invalid]="invalid"
    [class.-valid]="validColor"
    [class.-price]="price"
    [formControl]="control"
    *ngIf="!mask"
    (blur)="blur()"
    [autocomplete]="autocomplete"
    (touchend)="onTouch()"
  />
  <svg-icon
    (click)="iconClick.emit()"
    *ngIf="svgPath !== undefined && svgPath !== null && _svgAlign === 'end'"
    [src]="svgPath"
    [svgStyle]="{
      'width.px': _svgWidth,
      fill: _svgFill,
      stroke: _svgStroke,
      'height.px': _svgHeight
    }"
    class="svg-icon -{{ _svgType }}"
    [class.-r2]="circleBorder"
  />
</div>
