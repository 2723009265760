import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'commons-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  control: FormControl;

  @Input()
  fullW = false;

  @Output()
  valueChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  toggleChange(): void {
    if (this.control) {
      this.checked = !this.checked;
    }
    this.valueChange.emit(this.checked);
  }

  get checked(): boolean {
    return this.control.value as boolean;
  }

  set checked(checked: boolean) {
    this.control.setValue(checked);
  }

  get disabled(): boolean {
    return this.control.disabled;
  }
}
