<div class="sort-options">
  <div *ngFor="let option of orderOptions; let i = index">
    <label
      class="sort-options__item"
      [class.sort-options__item--border-bottom]="i < orderOptions.length - 1"
    >
      <p
        class="sort-options__item__title"
        [class.sort-options__item__title--active]="sortText === option.value"
      >
        {{ option.title }}
      </p>
      <commons-radiobutton
        [value]="option.value"
        [control]="sortValue"
        (valueSelect)="setSortValue(option)"
      >
      </commons-radiobutton>
    </label>
  </div>
</div>
