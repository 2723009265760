<div class="per-page-options">
  <div *ngFor="let option of perPageOptions; let i = index">
    <label
      class="per-page-options__item"
      [class.per-page-options__item--border-bottom]="
        i < perPageOptions.length - 1
      "
    >
      <p
        class="per-page-options__item__title"
        [class.per-page-options__item__title--active]="
          perPageText === option.value
        "
      >
        {{ option.title }}
      </p>
      <commons-radiobutton
        [value]="option.value"
        [control]="productsPerPage"
        (valueSelect)="setProductsPerPage(option)"
      >
      </commons-radiobutton>
    </label>
  </div>
</div>
