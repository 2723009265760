import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonsModule } from '@commons/commons.module';
import { ComponentsModule } from '@components/components.module';
import { FiltersAppliedModule } from '@components/filters-applied/filters-applied.module';
import { FiltersProductsModule } from '@components/filters-products/filters-products.module';
import { ListProductCardModule } from '@components/list-product-card/list-product-card.module';
import { ProductCardModule } from '@components/product-card/product-card.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EllipsisModule } from 'ngx-ellipsis';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FavoritesRoutingModule } from './favorites-routing.module';
import { FavoritesComponent } from './favorites.component';

@NgModule({
  declarations: [FavoritesComponent],
  imports: [
    CommonModule,
    CommonsModule,
    ComponentsModule,
    NgbModule,
    FormsModule,
    InfiniteScrollModule,
    EllipsisModule,
    ProductCardModule,
    ListProductCardModule,
    FiltersProductsModule,
    FiltersAppliedModule,
    FavoritesRoutingModule,
    AngularSvgIconModule
  ]
})
export default class FavoritesModule {}
