import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@infrab4a/connect';
import { CartService } from '@infrab4a/connect-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'app-header-submenu',
  templateUrl: './header-submenu.component.html',
  styleUrls: ['./header-submenu.component.scss']
})
export class HeaderSubmenuComponent implements OnInit {
  menu: Array<{ title: string; link: string }> = [
    { title: 'Assinatura', link: 'minha-conta/assinatura' },
    { title: 'Meus Pedidos', link: 'minha-conta/meus-pedidos' },
    { title: "Questionário da Men's", link: 'minha-conta/perfil-de-beleza' },
    { title: 'Favoritos', link: 'meus-favoritos' },
    { title: 'Configurações', link: 'minha-conta/configuracoes' }
  ];
  constructor(
    private router: Router,
    private modal: NgbModal,
    @Inject(PLATFORM_ID) private platform: object,
    private shopCartService: CartService,
    private authService: MensAuthenticationService
  ) {}

  ngOnInit(): void {}

  navigate(link: string): void {
    this.modal.dismissAll();
    this.router.navigate([link]);
  }

  close(): void {
    this.modal.dismissAll();
  }

  async logout(): Promise<void> {
    this.modal.dismissAll();
    if (isPlatformBrowser(this.platform)) {
      localStorage.clear();
      this.authService.logout();
    }
    this.shopCartService
      .updateUserCart(User.toInstance({ isSubscriber: false }))
      .subscribe();
    BroadcastUtil.get('balanceChanged').emit();
    this.router.navigate(['']);
  }
}
