import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CategoryFilter, Filter } from '@infrab4a/connect';
import { FiltersService } from 'src/app/services/filters.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.scss']
})
export class FiltersModalComponent implements OnInit {
  @Output()
  mobileMenu = new EventEmitter();

  @Output()
  myProfileFilterRemoved = new EventEmitter();

  brands: Array<string>;
  filteredBrands: Array<string>;
  recommendedFilterOptions: Array<string> = [];
  ratingFilterOptions: Array<{ stars: number }> = [];
  discountClubFilterOptions: Array<string> = [];
  customFilters: Array<Filter> = [];
  customFilterList = [];
  brandsSearch = new FormControl();
  brandSelected = false;
  priceSelected = false;
  genderSelected = false;
  ratingSelected = false;
  catFilterSelected = false;
  discountSelected = false;
  customFilterSelected = false;
  optionSelected: CategoryFilter;
  filtersList: Array<{ title: string; type: string; id?: number }> = [
    { title: 'Desconto Clube', type: 'discount' },
    { title: 'Marca', type: 'brands' },
    { title: 'Preço', type: 'price' },
    { title: 'Produto por gênero', type: 'genders' },
    { title: 'Avaliação', type: 'rating' }
  ];

  constructor(private filtersService: FiltersService) {}

  ngOnInit(): void {
    this.brands = this.filtersService.getBrands();
    this.filteredBrands = [...this.brands];
    this.recommendedFilterOptions =
      this.filtersService.recommendedFilterOptions;
    this.ratingFilterOptions = this.filtersService.ratingFilterOptions;
    this.discountClubFilterOptions =
      this.filtersService.discountClubFilterOptions;
    this.customFilters = this.filtersService.getCustomFilters();
    if (this.customFilters.length > 0) {
      const reversedFilters = this.customFilters.slice().reverse();
      for (let filter of reversedFilters) {
        this.filtersList.unshift({
          title: filter.description,
          id: filter.id,
          type: 'customFilter'
        });
      }
    }

    BroadcastUtil.get('clearFilters').subscribe(() => {
      this.clearFilters();
    });
    BroadcastUtil.get('closeFiltersModal').subscribe(() => {
      this.mobileBack();
    });
  }

  getFilterLength(option: {
    title: string;
    type: string;
    id?: number;
  }): string {
    switch (option.type) {
      case 'brands':
        return this.filtersService.getSelectedBrands().length > 0
          ? ` (${this.filtersService.getSelectedBrands().length})`
          : '';
      case 'price':
        return '';
      case 'genders':
        return this.filtersService.getSelectedRecommendedFilterOptions()
          .length > 0
          ? ` (${
              this.filtersService.getSelectedRecommendedFilterOptions().length
            })`
          : '';
      case 'rating':
        return this.filtersService.getSelectedRatingFilterOptions().length > 0
          ? ` (${this.filtersService.getSelectedRatingFilterOptions().length})`
          : '';
      case 'discount':
        return this.filtersService.getSelectedDiscountClubFilterOptions()
          .length > 0
          ? ` (${
              this.filtersService.getSelectedDiscountClubFilterOptions().length
            })`
          : '';
      case 'customFilter':
        return this.filtersService
          .getSelectedCustomFilters()
          .filter((f) => f.filterId === option.id).length > 0
          ? ` (${
              this.filtersService
                .getSelectedCustomFilters()
                .filter((f) => f.filterId === option.id).length
            })`
          : '';
    }
  }

  goToFilter(option: { title: string; type: string; id?: number }): void {
    switch (option.type) {
      case 'brands':
        this.brandSelected = true;
        break;
      case 'price':
        this.priceSelected = true;
        break;
      case 'genders':
        this.genderSelected = true;
        break;
      case 'rating':
        this.ratingSelected = true;
        break;
      case 'discount':
        this.discountSelected = true;
        break;
      case 'customFilter':
        this.customFilterSelected = true;
        this.customFilterList = this.customFilters
          .find((filter) => filter.id === option.id)
          .options.map((option) => {
            return {
              description: option.description,
              filterId: option.filterId,
              id: option.id
            };
          });
        break;
    }
  }

  mobileBack(): void {
    if (this.mobileFilterFirstPage) {
      this.mobileMenu.emit(false);
    } else {
      delete this.optionSelected;
      delete this.brandSelected;
      delete this.priceSelected;
      delete this.genderSelected;
      delete this.ratingSelected;
      delete this.catFilterSelected;
      delete this.discountSelected;
      delete this.customFilterSelected;
    }
  }

  onBrandsSearchInput(): void {
    this.filteredBrands = this.brands.filter((o) =>
      FormUtil.containsIgnoreAccents(o, this.brandsSearch.value)
    );
  }

  goTo(): void {
    this.mobileMenu.emit(false);
  }

  filterBrands(brand: string, event: boolean): void {
    if (this.filtersService.getFiltersApplied().find((o) => o.name === brand)) {
      this.filtersService.removeFiltersApplied({
        name: brand,
        tag: 'brand'
      });
      this.filtersService.removeSelectedBrands(brand);
    } else {
      if (event) {
        this.filtersService.addFiltersApplied({ name: brand, tag: 'brand' });
        this.filtersService.addSelectedBrands(brand);
      }
    }
  }

  filterDiscount(discount: string, event: boolean): void {
    if (
      this.filtersService.getFiltersApplied().find((o) => o.name === discount)
    ) {
      this.filtersService.removeFiltersApplied({
        name: discount,
        tag: 'discount'
      });
      this.filtersService.removeSelectedDiscountClubFilterOptions(discount);
    } else {
      if (event) {
        this.filtersService.addFiltersApplied({
          name: discount,
          tag: 'discount'
        });
        this.filtersService.addSelectedDiscountClubFilterOptions(discount);
      }
    }
  }

  filterGenders(gender: string, event: boolean): void {
    if (
      this.filtersService.getFiltersApplied().find((o) => o.name === gender)
    ) {
      this.filtersService.removeFiltersApplied({ name: gender, tag: 'gender' });
      this.filtersService.removeSelectedRecommendedFilterOptions(gender);
    } else {
      if (event) {
        this.filtersService.addFiltersApplied({ name: gender, tag: 'gender' });
        this.filtersService.addSelectedRecommendedFilterOptions(gender);
      }
    }
  }

  filterCustomFilter(customFilter: any, event: boolean): void {
    if (
      this.filtersService
        .getFiltersApplied()
        .find((o) => o.name === customFilter.description)
    ) {
      this.filtersService.removeFiltersApplied({
        name: customFilter.description,
        id: customFilter.id,
        tag: 'customFilter'
      });
      this.filtersService.removeSelectedCustomFilters(customFilter);
    } else {
      if (event) {
        this.filtersService.addFiltersApplied({
          name: customFilter.description,
          id: customFilter.id,
          tag: 'customFilter'
        });
        this.filtersService.addSelectedCustomFilters(customFilter);
      }
    }
  }

  filterRating(rating: { stars: number }, event: boolean): void {
    if (
      this.filtersService
        .getFiltersApplied()
        .find((o) => o.name === rating.stars)
    ) {
      this.filtersService.removeFiltersApplied({
        name: rating.stars,
        tag: 'rating'
      });
      this.filtersService.removeSelectedRatingFilterOptions(rating);
    } else {
      if (event) {
        this.filtersService.addFiltersApplied({
          name: rating.stars,
          tag: 'rating'
        });
        this.filtersService.addSelectedRatingFilterOptions(rating);
      }
    }
  }

  clearFilters(): void {
    this.filtersService.clearAllFiltersApplied();
    this.filtersService.filterRemoved();
    this.filtersService.removeFiltersAppliedByTag('price', true, true);
    this.myProfileFilterRemoved.emit();
    this.filtersService.filterEmit();
    this.mobileMenu.emit(false);
  }

  optionIsSelected(option: any): boolean {
    const list = this.filtersService.getFiltersApplied();
    return this.filtersService
      .getFiltersApplied()
      .some(
        (f) =>
          f.name === option ||
          f.name === option.stars ||
          f.name === option.description
      );
  }

  getIdCheckbox(option: any): string {
    const idName = option.stars
      ? option.stars
      : option.description
      ? option.description
      : option;
    return 'checkbox-' + idName.toString().replace(' ', '');
  }

  get mobileFilterFirstPage(): boolean {
    return (
      !this.optionSelected &&
      !this.brandSelected &&
      !this.priceSelected &&
      !this.genderSelected &&
      !this.ratingSelected &&
      !this.catFilterSelected &&
      !this.discountSelected &&
      !this.customFilterSelected
    );
  }

  get headerTitle(): string {
    if (this.mobileFilterFirstPage) {
      return this.showFiltersApplied
        ? `Filtrar (${this.filtersService.getFiltersApplied().length})`
        : 'Filtrar';
    } else if (this.brandSelected) {
      return this.filtersService.getSelectedBrands().length > 0
        ? `Marca (${this.filtersService.getSelectedBrands().length})`
        : 'Marca';
    } else if (this.priceSelected) {
      return 'Preço';
    } else if (this.genderSelected) {
      return this.filtersService.getSelectedRecommendedFilterOptions().length >
        0
        ? `Produto por gênero (${
            this.filtersService.getSelectedRecommendedFilterOptions().length
          })`
        : 'Produto por gênero';
    } else if (this.ratingSelected) {
      return this.filtersService.getSelectedRatingFilterOptions().length > 0
        ? `Avaliação (${
            this.filtersService.getSelectedRatingFilterOptions().length
          })`
        : 'Avaliação';
    } else if (this.discountSelected) {
      return this.filtersService.getSelectedDiscountClubFilterOptions().length >
        0
        ? `Desconto Clube (${
            this.filtersService.getSelectedDiscountClubFilterOptions().length
          })`
        : 'Desconto Clube';
    } else if (this.customFilterSelected) {
      return this.customFilterSelectedList.length > 0
        ? `${this.customFilterSelectedItem.description} (${this.customFilterSelectedList.length})`
        : this.customFilterSelectedItem.description;
    } else {
      return this.showFiltersApplied
        ? `Filtrar (${this.filtersService.getFiltersApplied().length})`
        : 'Filtrar';
    }
  }

  get showFiltersApplied(): boolean {
    return this.filtersService.getFiltersApplied().length > 0;
  }

  get customFilterSelectedItem(): any {
    return this.customFilters.find((cf) =>
      this.customFilterList.find((filter) => filter.filterId === cf.id)
    );
  }

  get customFilterSelectedList(): any {
    return this.filtersService
      .getSelectedCustomFilters()
      .filter((cf) => cf.filterId === this.customFilterSelectedItem.id);
  }
}
