import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'components-filters-applied',
  templateUrl: './filters-applied.component.html',
  styleUrls: ['./filters-applied.component.scss']
})
export class FiltersAppliedComponent implements OnInit {
  @Output()
  filterRemoved = new EventEmitter();

  @Input()
  categoryTitle: string;

  constructor(
    private filtersService: FiltersService,
    private gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.horizontalScroll();
    this.activatedRoute.params.subscribe((p) => {
      if (p.slug || p.key) {
        this.filterRemoved.emit();
      }
    });
  }

  removeFilter(filter: { name: string; tag: string }): void {
    if (this.filtersApplied.find((f) => f.name === filter.name)) {
      this.filtersService.removeFiltersApplied(
        filter,
        filter.tag !== 'vitrine'
      );
      this.filterRemoved.emit(filter);
      if (filter.tag === 'beautyProfile') {
        this.filtersService.setMyProfile(false);
      }
      if (filter.tag === 'gender') {
        this.filtersService.removeSelectedRecommendedFilterOptions(filter.name);
      }
      if (filter.tag === 'discount') {
        this.filtersService.removeSelectedDiscountClubFilterOptions(
          filter.name
        );
      }
      if (filter.tag === 'brand') {
        this.filtersService.removeSelectedBrands(filter.name);
      }
      if (filter.tag === 'rating') {
        this.filtersService.removeSelectedRatingFilterOptions({
          stars: +filter.name
        });
      }
    }
  }

  horizontalScroll(): void {
    const slider = document.getElementById('scroll');
    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      slider.style.cursor = 'grabbing';
    };
    let stopDragging = function (event) {
      mouseDown = false;
      slider.style.cursor = 'grab';
    };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener('wheel', (event) => {
      event.preventDefault();

      slider.scrollBy({
        left: event.deltaY < 0 ? -30 : 30
      });
    });

    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }

  getFilterType(filter: { name: string; tag: string }): string {
    if (this.isRatingFilter(filter)) return 'rating';
    if (this.isPriceFilter(filter)) return 'price';
    return 'default';
  }

  isRatingFilter(filter: { name: string; tag: string }): boolean {
    return typeof filter.name === 'number';
  }

  isPriceFilter(filter: { name: string; tag: string }): boolean {
    return filter.tag === 'price';
  }

  removeBadCharacters(text: string): string {
    if (!text) return;
    return text
      .toLowerCase()
      .replace(/\s/g, '-')
      .replace(/[éèê&]/g, 'e')
      .replace(/[óòõôö]/g, 'o')
      .replace(/[íìîï]/g, 'i')
      .replace(/[áàãäâ]/g, 'a')
      .replace(/[úùüû]/g, 'u')
      .replace(/[$%&#@!*+=]/g, '')
      .replace(/ç/g, 'c');
  }

  get filtersApplied(): Array<{ name: string; tag: string }> {
    return this.filtersService.getFiltersApplied() || [];
  }
}
