import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'components-my-profile-modal',
  templateUrl: './my-profile-modal.component.html',
  styleUrls: ['./my-profile-modal.component.scss']
})
export class MyProfileModalComponent implements OnInit {
  isLoggedAndHasNotBeautyProfile = false;

  constructor(public modal: NgbActiveModal, private router: Router) {}

  ngOnInit(): void {}

  goTo(): void {
    if (!this.isLoggedAndHasNotBeautyProfile) {
      this.router.navigate(['/minha-conta/register'], {
        queryParams: { redirectUrl: '/minha-conta/perfil-de-beleza' }
      });
    } else {
      this.router.navigate(['/minha-conta/perfil-de-beleza']);
    }
    this.modal.close();
  }
}
