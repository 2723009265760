import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PerPageOptionsModule } from '@components/per-page-options/per-page-options.module';
import { PerPageProductsComponent } from './per-page-products.component';

@NgModule({
  declarations: [PerPageProductsComponent],
  imports: [CommonModule, PerPageOptionsModule],
  exports: [PerPageProductsComponent]
})
export class PerPageProductsModule {}
