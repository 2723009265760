<ng-template #t let-fill="fill">
  <span
    class="rating-star"
    [class.-full]="fill === 100"
    [class.-big]="big"
    [class.-small]="small"
    [class.-yellow]="yellow"
    [class.-orange]="orange"
    [class.-tiny]="tiny"
  >
    <span
      class="half"
      [class.-yellow]="yellow"
      [class.-orange]="orange"
      [style.width.%]="fill"
      >&#9733;</span
    >&#9734;
  </span>
</ng-template>

<ngb-rating
  [(rate)]="currentRate"
  [starTemplate]="t"
  [readonly]="true"
  [max]="5"
></ngb-rating>
