import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MyProfileModalComponent } from '@components/my-profile-modal/my-profile-modal.component';
import {
  BeautyProfile,
  Category,
  CategoryFilter,
  User
} from '@infrab4a/connect';
import { AuthService } from '@infrab4a/connect-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandFilter } from '@pages/collections/base-collection.component';
import { take, tap } from 'rxjs/operators';
import { FiltersService } from 'src/app/services/filters.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'app-collection-filter',
  templateUrl: './collection-filter.component.html',
  styleUrls: ['./collection-filter.component.scss']
})
export class CollectionFilterComponent implements OnInit {
  @Input() fixedNav: boolean;

  myProfile = new FormControl(false);
  category: Category;
  brands: Array<BrandFilter>;
  filters: Array<{ name: string; tag: any }>;
  orderSelected: string = null;
  userHasProfile = false;
  beautyProfileTags: string[];
  mobileMenu = false;
  optionSelected: CategoryFilter;
  brandSelected = false;
  priceSelected = false;
  genderSelected = false;
  catFilterSelected = false;
  discountSelected = false;
  styleLogo = {
    'width.px': 165,
    'heigh.px': 35
  };
  isPlatformBrowserStatus: boolean;
  user: User;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platform: object,
    private filtersService: FiltersService,
    private router: Router,
    protected authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.authService
      .getUser()
      .pipe(
        take(1),
        tap((user) => (this.user = user))
      )
      .subscribe((user) => {
        this.beautyProfileTags = this.loadBeautyProfile(user);
        this.onBeautyProfileChange(
          this.filtersService
            .getFiltersApplied()
            .some((filter) => filter.tag === 'beautyProfile'),
          false,
          false
        );
      });
  }

  onBeautyProfileChange(event: boolean, emit = true, showModal = true): void {
    if (event && this.user?.id) {
      if (this.userHasProfile) {
        this.myProfile.setValue(true);
        this.filtersService.setMyProfile(true);
        if (
          !this.filtersService
            .getFiltersApplied()
            .some((filter) => filter.tag === 'beautyProfile')
        ) {
          this.filtersService.addFiltersApplied(
            {
              name: 'Meu Perfil',
              tag: 'beautyProfile'
            },
            emit
          );
        }
      } else {
        this.myProfile.setValue(false);
        this.filtersService.setMyProfile(false);
        this.filtersService.removeFiltersApplied(
          {
            name: 'Meu Perfil',
            tag: 'beautyProfile'
          },
          emit
        );
        const modal = this.modalService.open(MyProfileModalComponent, {
          centered: true,
          modalDialogClass: 'my-profile-modal'
        });
        modal.componentInstance.isLoggedAndHasNotBeautyProfile = true;
      }
    } else {
      this.myProfile.setValue(false);
      this.filtersService.setMyProfile(false);
      this.filtersService.removeFiltersApplied(
        {
          name: 'Meu Perfil',
          tag: 'beautyProfile'
        },
        emit
      );
      if (!this.userHasProfile && showModal) {
        const modal = this.modalService.open(MyProfileModalComponent, {
          centered: true,
          modalDialogClass: 'my-profile-modal'
        });
        modal.componentInstance.isLoggedAndHasNotBeautyProfile = false;
      }
    }
  }

  removeMyProfileFilter(): void {
    this.myProfile.setValue(false);
  }

  onFiltersClick(): void {
    this.mobileMenu = true;
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        if (this.fixedNav) {
          document.body.style.overflow = 'initial';
        } else {
          document.body.style.overflow = 'hidden';
        }
      }, 500);
    }
  }

  mobileBack(event: boolean): void {
    if (!event) {
      this.mobileMenu = false;
      if (isPlatformBrowser(this.platform)) {
        document.body.style.overflow = 'unset';
      }
    }
  }

  clearFilters(): void {
    BroadcastUtil.get('clearFilters').emit();
    if (isPlatformBrowser(this.platform)) {
      window.scrollTo({
        behavior: 'auto',
        top: 0
      });
    }
  }

  private loadBeautyProfile(user: User) {
    this.userHasProfile = Boolean(user.beautyProfile);
    if (user.beautyProfile) {
      return this.getBeautyProfileTags(user.beautyProfile);
    }

    return [];
  }

  private getBeautyProfileTags(profile: BeautyProfile): Array<string> {
    const tags: string[] = [];
    const profileObjects = Object.entries(profile);

    profileObjects.forEach(([name, options]) => {
      [].concat(options).forEach((option) => {
        tags.push(`perfil-${name} ${option}`);
      });
    });

    return tags.map((t) => t.toLowerCase()).sort();
  }

  get mobileFilterFirstPage(): boolean {
    return (
      !this.optionSelected &&
      !this.brandSelected &&
      !this.priceSelected &&
      !this.genderSelected &&
      !this.catFilterSelected &&
      !this.discountSelected
    );
  }

  get filtersLength(): string {
    return this.filtersService.getFiltersApplied().length > 0
      ? `(${this.filtersService.getFiltersApplied().length})`
      : '';
  }

  get discountFilterCount(): number {
    return this.filters.filter((f) => f.name === 'discount').length;
  }

  get brandFilterCount(): number {
    return this.filters.filter((f) => f.name === 'brand').length;
  }

  get priceFilterCount(): number {
    return this.filters.filter((f) => f.name === 'price').length;
  }

  get genderFilterCount(): number {
    return this.filters.filter((f) => f.name === 'gender').length;
  }

  get catFilterCount(): number {
    return this.filters.filter((f) => f.name === 'catFilter').length;
  }

  get totalFilterCount(): number {
    return this.filters.filter((f) => f.name !== 'beautyProfile').length;
  }

  get showCategoriesFilter(): boolean {
    return this.category?.slug?.includes('glambeauty');
  }

  get isResultsForMe(): boolean {
    return this.router.url.includes('/collections/para-mim');
  }

  get isVitrine(): boolean {
    return this.router.url.includes('/vitrine');
  }
}
