import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filter } from '@infrab4a/connect';
import { BroadcastUtil } from '../utils/broadcast.util';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private brands: Array<string>;
  private filtersApplied: Array<any> = [];
  private myProfile = false;
  private selectedBrands: Array<string> = [];
  private selectedRecommendedFilterOptions: Array<string> = [];
  private selectedRatingFilterOptions: Array<{ stars: number }> = [];
  private selectedDiscountClubFilterOptions: Array<string> = [];
  private selectedCustomFilters: Array<any> = [];
  private prices: {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  };
  private pricesOriginal: {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  };
  private minPrice: number;
  private maxPrice: number;
  private subscriberPrice = true;
  private isFilterPrice = false;
  private control = false;
  private beautyProfileTags: string[];
  private rates: Array<number>;
  private customFilters: Array<Filter> = [];
  private currentPage = 1;
  private productsPerPage = 24;
  private sortSelected:
    | 'news'
    | 'best-sellers'
    | 'biggest-price'
    | 'lowest-price'
    | 'biggest-discount'
    | 'best-rating'
    | 'most-relevant' = 'best-sellers';
  private filtersParameter: any = {};
  public recommendedFilterOptions = ['Feminino', 'Unissex', 'Masculino'];
  public ratingFilterOptions = [
    { stars: 4 },
    { stars: 3 },
    { stars: 2 },
    { stars: 1 }
  ];
  public discountClubFilterOptions = [
    '10% off',
    '20% off',
    '30% off',
    '40% off',
    '50% off',
    '60% off'
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  public getBrands(): Array<string> {
    return this.brands;
  }

  public setBrands(brands: Array<string>): void {
    brands = brands.map((b) => b.trim());
    this.brands = [...new Set(brands)];
  }

  public getSelectedBrands(): Array<string> {
    return this.selectedBrands;
  }

  public addSelectedBrands(brand: string): void {
    this.selectedBrands.push(brand);
  }

  public removeSelectedBrands(brand: string): void {
    const index = this.selectedBrands.findIndex((f) => f === brand);
    this.selectedBrands.splice(index, 1);
  }

  public getSelectedRecommendedFilterOptions(): Array<string> {
    return this.selectedRecommendedFilterOptions;
  }

  public addSelectedRecommendedFilterOptions(gender: string): void {
    this.selectedRecommendedFilterOptions.push(gender);
  }

  public removeSelectedRecommendedFilterOptions(gender: string): void {
    const index = this.selectedRecommendedFilterOptions.findIndex(
      (f) => f === gender
    );
    this.selectedRecommendedFilterOptions.splice(index, 1);
  }

  public getSelectedDiscountClubFilterOptions(): Array<string> {
    return this.selectedDiscountClubFilterOptions;
  }

  public addSelectedDiscountClubFilterOptions(discount: string): void {
    this.selectedDiscountClubFilterOptions.push(discount);
  }

  public getSelectedCustomFilters(): Array<any> {
    return this.selectedCustomFilters;
  }

  public addSelectedCustomFilters(customFilter: any): void {
    this.selectedCustomFilters.push(customFilter);
  }

  public removeSelectedCustomFilters(customFilter: any): void {
    const index = this.selectedCustomFilters.findIndex(
      (f) => f === customFilter
    );
    this.selectedCustomFilters.splice(index, 1);
  }

  public removeSelectedDiscountClubFilterOptions(discount: string): void {
    const index = this.selectedDiscountClubFilterOptions.findIndex(
      (f) => f === discount
    );
    this.selectedDiscountClubFilterOptions.splice(index, 1);
  }

  public getSelectedRatingFilterOptions(): Array<{ stars: number }> {
    return this.selectedRatingFilterOptions;
  }

  public addSelectedRatingFilterOptions(rating: { stars: number }): void {
    this.selectedRatingFilterOptions.push(rating);
  }

  public removeSelectedRatingFilterOptions(rating: { stars: number }): void {
    const index = this.selectedRatingFilterOptions.findIndex(
      (f) => f === rating
    );
    this.selectedRatingFilterOptions.splice(index, 1);
  }

  public getMyProfile(): boolean {
    return this.myProfile;
  }

  public setMyProfile(value: boolean): void {
    this.myProfile = value;
  }

  public getFiltersApplied(): Array<any> {
    return this.filtersApplied;
  }

  public addFiltersApplied(filter: any, emit = true): void {
    console.log(filter);
    this.filtersApplied.push(filter);
    if (emit) {
      this.filterEmit();
    }
  }

  public removeFiltersApplied(filter: any, emit = true): void {
    const index = this.filtersApplied.findIndex(
      (f) => f.name === filter.name && f.tag === filter.tag
    );
    if (index !== -1) {
      this.filtersApplied.splice(index, 1);
    }
    if (filter.tag === 'price') {
      this.setIsFilterPrice(false);
    }
    if (emit) {
      this.filterEmit();
    }
  }

  public removeFiltersAppliedByTag(tag: any, emit = true, clear = false): void {
    const index = this.filtersApplied.findIndex((f) => f.tag === tag);
    if (index !== -1) {
      this.filtersApplied.splice(index, 1);
    }
    if (clear) {
      this.setIsFilterPrice(false);
    }
    if (emit) {
      this.filterEmit();
    }
  }

  public filterEmit(emit = true, addParams = true): void | any {
    const filtersApplied = this.getFiltersApplied();
    if (filtersApplied.find((f) => f.tag === 'price')) {
      this.getIsSubscriberPriceUrl();
    }

    const filtersParameter = filtersApplied.reduce(
      (result, filter) => {
        if (filter.tag === 'brand') {
          result.brands.push(filter.name);
        } else if (filter.tag === 'discount') {
          const numbers = filter.name.match(/\d+/g);
          const discount = numbers !== null ? Number(numbers.join('')) : [];
          result.clubDiscount.push(discount);
        } else if (filter.tag === 'gender') {
          if (filter.name === 'Masculino') {
            result.gender.push('male');
          } else if (filter.name === 'Feminino') {
            result.gender.push('female');
          } else {
            result.gender.push('unisex');
          }
        } else if (filter.tag === 'customFilter') {
          result.customOptions.push(filter.id);
        }

        return result;
      },
      {
        brands: [],
        clubDiscount: [],
        gender: [],
        prices: {
          min: this.getSubscriberPrice()
            ? Math.floor(this.setPricesValue().subscriberPrice.min)
            : this.setPricesValue().price.min,
          max: this.getSubscriberPrice()
            ? Math.ceil(this.setPricesValue().subscriberPrice.max)
            : this.setPricesValue().price.max,
          subscriberPrice: this.getSubscriberPrice()
        },
        rate: 0,
        tags: this.filtersApplied.find((f) => f.tag === 'beautyProfile')
          ? this.getBeautyProfileTags()
          : [],
        customOptions: []
      }
    );

    const ratingFilters = filtersApplied
      .filter((filter) => filter.tag === 'rating')
      .map((filter) => Number(filter.name));

    if (ratingFilters.length > 0) {
      filtersParameter.rate = Math.min(...ratingFilters);
    }
    this.setRates(ratingFilters);

    filtersParameter.gender = Array.from(new Set(filtersParameter.gender));
    this.filtersParameter = filtersParameter;

    if (addParams) {
      this.addParamsToRoute(null);
    }
    if (emit) {
      BroadcastUtil.get('filter').emit(filtersParameter);
      return;
    }

    return filtersParameter;
  }

  private setPricesValue(): {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  } {
    if (!this.getIsFilterPrice()) {
      return this.getPricesOriginal();
    } else {
      return {
        price: { min: this.getMinPrice(), max: this.getMaxPrice() },
        subscriberPrice: { min: this.getMinPrice(), max: this.getMaxPrice() }
      };
    }
  }

  public clearAllFiltersApplied(): void {
    this.filtersApplied = [];
    this.selectedDiscountClubFilterOptions = [];
    this.selectedRatingFilterOptions = [];
    this.selectedRecommendedFilterOptions = [];
    this.selectedBrands = [];
    this.selectedCustomFilters = [];
  }

  public setPrices(prices: {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  }): void {
    this.prices = prices;
  }

  public getPrices(): {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  } {
    return this.filtersApplied.find((f) => f.tag === 'price')
      ? this.prices
      : this.pricesOriginal;
  }

  public getMinPrice(): number {
    return this.minPrice >= 0
      ? Math.floor(this.minPrice)
      : Math.floor(this.prices.subscriberPrice.min);
  }

  public addMinPrice(value: number) {
    this.minPrice = value;
  }

  public getMaxPrice(): number {
    return this.maxPrice >= 0
      ? Math.ceil(this.maxPrice)
      : Math.ceil(this.prices.subscriberPrice.max);
  }

  public addMaxPrice(value: number) {
    this.maxPrice = value;
  }

  public getSubscriberPrice(): boolean {
    return this.subscriberPrice;
  }

  public setSubscriberPrice(value: boolean) {
    this.subscriberPrice = value;
  }

  public getPricesOriginal(): {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  } {
    return this.pricesOriginal ? this.pricesOriginal : this.prices;
  }

  public setPricesOriginal(pricesOriginal: {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  }): void {
    this.pricesOriginal = pricesOriginal;
  }

  public getIsFilterPrice(): boolean {
    return this.isFilterPrice;
  }

  public setIsFilterPrice(value: boolean) {
    this.isFilterPrice = value;
  }

  public getBeautyProfileTags(): Array<string> {
    return this.beautyProfileTags;
  }

  public setBeautyProfileTags(beautyProfileTags: Array<string>): void {
    this.beautyProfileTags = beautyProfileTags;
  }

  public getRates(): Array<number> {
    return this.rates;
  }

  public setRates(rates: Array<number>): void {
    this.rates = rates;
  }

  public getCustomFilters(): Array<Filter> {
    return this.customFilters;
  }

  public setCustomFilters(customFilters: Array<Filter>): void {
    this.customFilters = customFilters;
  }

  filterRemoved(event?: any): void {
    if (event) {
      switch (event.tag) {
        case 'brand':
          this.getSelectedBrands().splice(
            this.getSelectedBrands().findIndex((f) => f === event.name),
            1
          );
          return;
        case 'tags':
          this.getSelectedRecommendedFilterOptions().splice(
            this.getSelectedRecommendedFilterOptions().findIndex(
              (f) => f === event.name
            ),
            1
          );
          return;
        case 'rating':
          this.getSelectedRatingFilterOptions().splice(
            this.getSelectedRatingFilterOptions().findIndex(
              (f) => f === event.stars
            ),
            1
          );
          return;
        case 'discount':
          this.getSelectedDiscountClubFilterOptions().splice(
            this.getSelectedDiscountClubFilterOptions().findIndex(
              (f) => f === event.name
            ),
            1
          );
          return;
        case 'customFilter':
          this.getSelectedCustomFilters().splice(
            this.getSelectedCustomFilters().findIndex((f) => f === event.name),
            1
          );
          return;
      }
    }
  }

  addParamsToRoute(sortSelected = null, page = 1, productsPerPage = 24): void {
    if (sortSelected) this.sortSelected = sortSelected;
    if (page) this.currentPage = page;
    if (productsPerPage) this.productsPerPage = productsPerPage;
    if (this.router.url.includes('/products/')) return;
    this.router.navigate([], {
      queryParams: this.queryParams,
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      replaceUrl: true,
      preserveFragment: true
    });
  }

  getIsSubscriberPriceUrl(): void {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams.prices) {
        if (!this.control) {
          const [min, max, subscriberPrice] = queryParams.prices.split(',');
          this.setSubscriberPrice(subscriberPrice === 'true');
          this.setIsFilterPrice(true);
          this.control = true;
        }
      }
    });
  }

  get queryParams(): {
    brands: string;
    clubDiscount: string;
    gender: string;
    rate: string;
    profile: boolean;
    customOptions: string;
    scrollY: string;
    sort: string;
    page: string;
    perPage: string;
  } {
    return {
      brands: this.filtersParameter.brands?.toString(),
      clubDiscount: this.filtersParameter.clubDiscount?.toString(),
      gender: this.filtersParameter.gender?.toString(),
      rate: this.getRates()?.toString(),
      profile: this.filtersParameter.tags?.length ? true : false,
      customOptions: this.filtersParameter.customOptions?.toString(),
      scrollY: isPlatformBrowser(this.platform)
        ? window.scrollY.toString()
        : '',
      sort: this.sortSelected,
      page: this.currentPage.toString(),
      perPage: this.productsPerPage.toString()
    };
  }
}
