import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonsModule } from '@commons/commons.module';
import { FiltersOptionsComponent } from './filters-options.component';

@NgModule({
  declarations: [FiltersOptionsComponent],
  imports: [CommonModule, CommonsModule],
  exports: [FiltersOptionsComponent]
})
export class FiltersOptionsModule {}
