<div class="per-page-products__per-page-box">
  <div
    (click)="clickOnPerPage()"
    class="per-page-products__per-page-box__per-page"
  >
    <p class="per-page-products__per-page-box__per-page__value">
      {{ perPageTitle }}
    </p>
    <img
      src="assets/icons/button-chevron-down.svg"
      alt="icon seta para baixo"
      [style]="{ width: '12px', height: '7px' }"
    />
  </div>
  <ng-container *ngIf="showPerPageOptions">
    <div
      class="per-page-products__overlay"
      (click)="showPerPageOptions = false"
    ></div>
    <div class="per-page-products__per-page-box__per-page-options">
      <a
        *ngFor="let option of perPageOptions; let i = index"
        (click)="selectPerPageOption(option)"
        class="per-page-products__per-page-box__per-page-options__item"
        [class.per-page-products__per-page-box__per-page-options__item--border-bottom]="
          i < perPageOptions.length - 1
        "
        [class.per-page-products__per-page-box__per-page-options__item--active]="
          perPageTitle === option.title
        "
      >
        {{ option.title }}
      </a>
    </div>
  </ng-container>
</div>
