<div class="sort-products__sort-box">
  <p *ngIf="isLaptop" class="sort-products__sort-box__title">Ordenar por</p>
  <div (click)="clickOnSort()" class="sort-products__sort-box__sort">
    <p class="sort-products__sort-box__sort__value">
      {{ sortTitle }}
    </p>
    <img
      src="assets/icons/button-chevron-down.svg"
      alt="icon seta para baixo"
      [style]="{ width: '12px', height: '7px' }"
    />
  </div>
  <ng-container *ngIf="showSortOptions">
    <div class="sort-products__overlay" (click)="showSortOptions = false"></div>
    <div class="sort-products__sort-box__sort-options">
      <a
        *ngFor="let option of orderOptions; let i = index"
        (click)="selectSortOption(option)"
        class="sort-products__sort-box__sort-options__item"
        [class.sort-products__sort-box__sort-options__item--border-bottom]="
          i < orderOptions.length - 1
        "
        [class.sort-products__sort-box__sort-options__item--active]="
          sortTitle === option.title
        "
      >
        {{ option.title }}
      </a>
    </div>
  </ng-container>
</div>
