import { Component, Input, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FiltersService } from 'src/app/services/filters.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'components-filters-options',
  templateUrl: './filters-options.component.html',
  styleUrls: ['./filters-options.component.scss']
})
export class FiltersOptionsComponent implements OnChanges {
  @Input()
  title: string;

  @Input()
  options: Array<any>;

  @Input()
  isBrandsFilter = false;

  @Input()
  isRatingFilter = false;

  @Input()
  isDiscountFilter = false;

  @Input()
  isGenderFilter = false;

  @Input()
  isCustomFilter = false;

  isCollapsed = false;
  brandsSearch = new FormControl();
  filteredOptions: Array<any>;

  constructor(private filtersService: FiltersService) {}

  ngOnChanges(): void {
    this.filteredOptions = [...this.options];
  }

  onBrandsSearchInput(): void {
    if (this.isBrandsFilter) {
      this.filteredOptions = this.options.filter((o) =>
        FormUtil.containsIgnoreAccents(o, this.brandsSearch.value)
      );
    }
  }

  filterOptions(option: any, event: boolean): void {
    if (this.isBrandsFilter) {
      if (
        this.filtersService.getFiltersApplied().find((o) => o.name === option)
      ) {
        this.filtersService.removeFiltersApplied({
          name: option,
          tag: 'brand'
        });
      } else {
        if (event) {
          this.filtersService.addFiltersApplied({ name: option, tag: 'brand' });
        }
      }
    } else if (this.isRatingFilter) {
      if (
        this.filtersService
          .getFiltersApplied()
          .find((o) => o.name === option.stars)
      ) {
        this.filtersService.removeFiltersApplied({
          name: option.stars,
          tag: 'rating'
        });
      } else {
        if (event) {
          this.filtersService.addFiltersApplied({
            name: option.stars,
            tag: 'rating'
          });
        }
      }
    } else if (this.isDiscountFilter) {
      if (
        this.filtersService.getFiltersApplied().find((o) => o.name === option)
      ) {
        this.filtersService.removeFiltersApplied({
          name: option,
          tag: 'discount'
        });
      } else {
        if (event) {
          this.filtersService.addFiltersApplied({
            name: option,
            tag: 'discount'
          });
        }
      }
    } else if (this.isGenderFilter) {
      if (
        this.filtersService.getFiltersApplied().find((o) => o.name === option)
      ) {
        this.filtersService.removeFiltersApplied({
          name: option,
          tag: 'gender'
        });
      } else {
        if (event) {
          this.filtersService.addFiltersApplied({
            name: option,
            tag: 'gender'
          });
        }
      }
    } else if (this.isCustomFilter) {
      if (
        this.filtersService
          .getFiltersApplied()
          .find((o) => o.name === option.description)
      ) {
        this.filtersService.removeFiltersApplied({
          name: option.description,
          id: option.id,
          tag: 'customFilter'
        });
      } else {
        if (event) {
          this.filtersService.addFiltersApplied({
            name: option.description,
            id: option.id,
            tag: 'customFilter'
          });
        }
      }
    } else {
      if (
        this.filtersService.getFiltersApplied().find((o) => o.name === option)
      ) {
        this.filtersService.removeFiltersApplied({ name: option, tag: 'tags' });
      } else {
        if (event) {
          this.filtersService.addFiltersApplied({ name: option, tag: 'tags' });
        }
      }
    }
  }

  optionIsSelected(option: any): boolean {
    return this.filtersService
      .getFiltersApplied()
      .some(
        (f) =>
          f.name === option ||
          f.name === option.stars ||
          f.name === option.description
      );
  }

  getIdCheckbox(option: any): string {
    const idName = option.stars
      ? option.stars
      : option.description
      ? option.description
      : option;
    return 'checkbox-' + idName.toString().replace(' ', '');
  }

  get svgIcon(): string {
    return this.isCollapsed
      ? 'assets/icons/arrow-down.svg'
      : 'assets/icons/arrow-up.svg';
  }

  get altIcon(): string {
    return this.isCollapsed ? 'icon seta para baixo' : 'icon seta para cima';
  }
}
