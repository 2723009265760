<div class="filters-options">
  <div (click)="isCollapsed = !isCollapsed" class="filters-options__header">
    <p class="filters-options__header__title">{{ title }}</p>
    <img
      [src]="svgIcon"
      [alt]="altIcon"
      [style]="{ width: '20px', height: '12px' }"
    />
  </div>
  <div *ngIf="!isCollapsed">
    <form *ngIf="isBrandsFilter" class="filters-options__form">
      <commons-input
        [circleBorder]="true"
        [fullW]="true"
        [id]="'brandsSearch'"
        type="text"
        [control]="brandsSearch"
        [placeholder]="'Buscar pela marca'"
        (emitInput)="onBrandsSearchInput()"
        [newsletter]="true"
        (iconClick)="onBrandsSearchInput()"
        svgPath="assets/icons/search.svg"
        [svgConfig]="{
          align: 'end',
          size: 'big'
        }"
      ></commons-input>
    </form>
    <div class="filters-options__options">
      <label
        *ngFor="let option of filteredOptions"
        class="filters-options__options__option"
      >
        <commons-checkbox
          [id]="getIdCheckbox(option)"
          [isSelected]="optionIsSelected(option)"
          [isFilter]="true"
          (stateChange)="filterOptions(option, $event)"
        ></commons-checkbox>
        <div *ngIf="isRatingFilter" class="filters-options__options__rating">
          <app-rating-stars
            [currentRate]="option.stars"
            [yellow]="true"
            [tiny]="true"
          >
          </app-rating-stars>
          <p class="filters-options__options__rating__text">ou mais</p>
        </div>
        <p
          *ngIf="!isRatingFilter"
          class="filters-options__options__option__text"
        >
          {{ option.description ? option.description : option }}
        </p>
      </label>
    </div>
  </div>
</div>
