import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonsModule } from '@commons/commons.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductBackInStockModalComponent } from './product-back-in-stock-modal.component';

@NgModule({
  declarations: [ProductBackInStockModalComponent],
  imports: [
    CommonModule,
    CommonsModule,
    NgbModalModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [ProductBackInStockModalComponent]
})
export class ProductBackInStockModalModule {}
