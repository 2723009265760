import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';

export class FormUtil {
  static validarCPF(cpf: string): Observable<boolean> {
    let soma;
    let resto;
    soma = 0;

    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf === '00000000000') {
      return of(false).pipe();
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10), 10)) {
      return of(false).pipe();
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11), 10)) {
      return of(false).pipe();
    }
    return of(true).pipe();
  }

  static cpfValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.validarCPF(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          return !res ? { invalid: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  static cardExpirationValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.validateExpiration(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          return !res ? { invalid: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  static validateExpiration(expiration: string): Observable<boolean> {
    const exp = [expiration.substring(0, 2), expiration.substring(2, 4)];
    if (Number(exp[0]) > 12) {
      return of(false);
    }
    if (Number(exp[1]) + 2000 < new Date().getFullYear()) {
      return of(false);
    }
    const date = new Date(Number(exp[1]) + 2000 + '-' + exp[0] + '-01');
    if (date.getTime() < new Date().getTime()) {
      return of(false);
    }
    return of(true);
  }

  static getCardFlagId(flagName: string): number {
    switch (flagName.toLowerCase()) {
      case 'visa':
        return 1;
      case 'master':
      case 'mastercard':
      case 'master card':
        return 2;
      case 'amex':
        return 3;
      case 'diners':
        return 4;
      case 'hipercard':
        return 5;
      case 'elo':
        return 6;
      case 'discover':
        return 7;
      case 'aura':
        return 8;
      case 'jcb':
        return 9;
      default:
        return 0;
    }
  }

  static getFlagById(id: number): string {
    switch (id) {
      case 1:
        return 'visa';
      case 2:
        return 'master';
      case 3:
        return 'amex';
      case 4:
        return 'diners';
      case 5:
        return 'hipercard';
      case 6:
        return 'elo';
      case 7:
        return 'discover';
      case 8:
        return 'aura';
      case 9:
        return 'jcb';
      default:
        return 'credit-card';
    }
  }

  static prediction(editionId: number): Date {
    let date = new Date();
    date = new Date(
      date.setMonth(
        Number(
          editionId
            .toString()
            .substring(
              editionId.toString().length - 2,
              editionId.toString().length
            )
        )
      )
    );
    date = new Date(
      date.setFullYear(Number(editionId.toString().substring(1, 5)))
    );
    date = new Date(date.setDate(15));
    return date;
  }

  static getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static date(): ValidatorFn {
    const validator = (formControl: FormControl) => {
      if (!formControl.value) {
        return null;
      }

      const [day, month, year]: string = formControl.value.split('/');
      const dayDate = +day;
      const monthDate = +month;
      const yearDate = +year;

      const currentDate = new Date();

      if (dayDate > 31) {
        return { invalidDate: true };
      }

      if (monthDate > 12) {
        return { invalidDate: true };
      }

      if (yearDate >= currentDate.getFullYear()) {
        return { invalidDate: true };
      }

      return null;
    };

    return validator;
  }
  static birthdayYear(): ValidatorFn {
    const validator = (formControl: FormControl) => {
      if (!formControl.value) {
        return null;
      }

      const minYearsOld = 18;
      const maxYearsOld = 120;

      const currentDate = new Date();
      const birthdayArray = formControl.value.split('/');
      const birthdayDate = new Date(
        Number(birthdayArray[2]),
        Number(birthdayArray[1]) - 1,
        Number(birthdayArray[0]),
        12
      );
      const birthdayMinDate = new Date(
        Number(birthdayArray[2]) + minYearsOld,
        Number(birthdayArray[1]) - 1,
        Number(birthdayArray[0]),
        12
      );
      const birthdayMaxDate = new Date(
        Number(birthdayArray[2]) + maxYearsOld,
        Number(birthdayArray[1]) - 1,
        Number(birthdayArray[0]),
        12
      );

      if (birthdayMinDate.getTime() > currentDate.getTime()) {
        return { underAge: 'Menor de 18 anos' };
      }

      if (birthdayMaxDate.getTime() < currentDate.getTime()) {
        return { aboveAge: 'Idade inválida' };
      }

      return null;
    };

    return validator;
  }

  public static containsIgnoreAccents(text: string, filter: string): boolean {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(`'`, '')
      .toLowerCase()
      .includes(
        filter
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(`'`, '')
          .toLowerCase()
      );
  }
}
