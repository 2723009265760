import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
  AuthenticationService,
  BasicUserData,
  BeautyProfileRepository,
  LoginAndPasswordParams,
  Register,
  RegisterService,
  UpdateUserImage,
  User,
  UserFirestoreRepository,
  UserRepository,
  UserType,
  Where
} from '@infrab4a/connect';
import { AuthService } from '@infrab4a/connect-angular';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BroadcastUtil } from '../utils/broadcast.util';

@Injectable({ providedIn: 'root' })
export class MensAuthenticationService {
  private fireUser: User & { isAnonymous: boolean };
  authState$: Observable<{ user: User; isAnonymous: boolean }>;

  constructor(
    @Inject('AuthenticationService')
    private authentication: AuthenticationService,
    private authService: AuthService,
    @Inject('UserRepository') private userService: UserFirestoreRepository,
    @Inject('Register') private readonly register: Register,
    @Inject('RegisterService') private registerService: RegisterService,
    @Inject('UserRepository') private userRepository: UserRepository,
    @Inject(FirebaseApp) private firebaseApp: FirebaseApp,
    @Inject(PLATFORM_ID) private platform: object,
    @Inject('BeautyProfileRepository')
    private beautyProfileRepository: BeautyProfileRepository,
    @Inject(UpdateUserImage) private updateUserImage: UpdateUserImage,
    private http: HttpClient
  ) {
    this.authState$ = this.authService.getAuthstate();
    if (isPlatformBrowser(this.platform)) {
      if (localStorage.getItem('user')) {
        this.fireUser = this.getLocalStorageUser();
      }
      this.authState$ = this.authState$.pipe(
        mergeMap((auth) => {
          if (auth?.user) {
            BroadcastUtil.get('userChanged').emit(auth.user);
            return from(this.setFireUser(auth.user, auth.isAnonymous)).pipe(
              map(() => auth)
            );
          }

          this.removeFireUser();

          return of(auth);
        })
      );

      this.authState$.subscribe();
    }
  }

  private getLocalStorageUser() {
    try {
      const user = JSON.parse(this.decode(localStorage.getItem('user')));
      return user;
    } catch (err) {
      return null;
    }
  }

  async getUserProfileByID(id) {
    try {
      const beautyProfile = await this.beautyProfileRepository.find({
        filters: {
          userId: id
        }
      });
      return beautyProfile.data[0];
    } catch (error) {
      return null;
    }
  }

  async setFireUser(user: User, isAnonymous: boolean): Promise<void> {
    this.fireUser = user as any;
    this.fireUser.isAnonymous = isAnonymous;

    if (this.fireUser?.id) {
      this.fireUser.beautyProfile = await this.getUserProfileByID(
        this.fireUser.id
      );
      localStorage.setItem('user', this.encode(JSON.stringify(this.fireUser)));
    }
  }

  removeFireUser(): void {
    delete this.fireUser;
    localStorage.removeItem('user');
  }

  encode(str: any): string | null {
    try {
      return btoa(str);
    } catch (error) {
      console.error('Erro ao criptografar os dados fornecidos', error);
      return null;
    }
  }

  decode(str: any): string | null {
    try {
      return atob(str);
    } catch (error) {
      console.error('Erro ao descriptografar os dados fornecidos', error);
      return null;
    }
  }

  get authenticated(): boolean {
    return !!this.fireUser;
  }

  login(credentials: LoginAndPasswordParams): Promise<Partial<User>> {
    return this.authentication.signInWithEmailAndPassword(credentials);
  }

  loginWithGoogle(): Observable<any> {
    return this.signInWithGoogle();
  }

  loginInAnonymously(): Promise<Pick<BasicUserData, 'id' | 'isAnonymous'>> {
    return this.authentication.signInAnonymously();
  }

  logout(): Promise<void> {
    return this.authentication.signOut();
  }

  getUser(): User & { isAnonymous?: boolean } {
    return this.fireUser;
  }

  getAuthState(): Observable<{ user: User; isAnonymous: boolean }> {
    return this.authState$;
  }

  userRegister(registerRequest: any): Promise<User> {
    return this.register.register(registerRequest);
  }

  async checkEmail(email: string): Promise<boolean> {
    const user = await this.userService.find({
      filters: { email: { operator: Where.EQUALS, value: email } }
    });
    return user.data.length > 0;
  }

  async checkDocument(cpf: string): Promise<boolean> {
    const user = await this.userService.find({
      filters: { cpf: { operator: Where.EQUALS, value: cpf } }
    });
    return user.data.length > 0;
  }

  async checkAccountWithDocument(cpf: string): Promise<string> {
    const users = await this.userService.find({
      filters: { cpf: { operator: Where.EQUALS, value: cpf } }
    });
    if (users.data.length) {
      const [user] = users.data;
      const [prefix, suffix] = user.email.split('@');
      return `O CPF utilizado já está cadastrado em nosso sistema com o e-mail: 
      <br />
      <b>${prefix.substring(0, 2)}${prefix
        .substring(2)
        .replace(/./g, '*')}@${suffix}</b>
      <br />
      Faça login ou use outro CPF`;
    }

    return null;
  }

  async checkAccountWithEmail(email: string): Promise<string> {
    const users = await this.userService.find({
      filters: { email: { operator: Where.EQUALS, value: email } }
    });
    if (users.data.length) {
      return `O e-mail utilizado já está cadastrado em nosso sistema, faça login ou recupere a sua senha`;
    }

    return null;
  }

  sendEmailReset(email: string): Observable<any> {
    return this.http
      .post<any>(
        `https://southamerica-east1-${environment.firebase.projectId}.cloudfunctions.net/forgotPassword`,
        { email: email }
      )
      .pipe(
        catchError((error) => {
          if (error.status === 500) {
            return of({ userNotFound: true });
          }
          return throwError(error);
        })
      );
  }

  getUserByEmail(user: any): Observable<any> {
    return from(
      this.userRepository.find({
        filters: {
          email: {
            operator: Where.EQUALS,
            value: user.email
          }
        }
      })
    );
  }

  async createAccount(user: User & { password: string }): Promise<User> {
    const checkDocument = await this.checkDocument(user.cpf);
    if (checkDocument) throw `Usuário com CPF ${user.cpf} já registrado.`;

    const checkEmail = await this.checkEmail(user.email);
    if (checkEmail) throw `Usuário com e-mail ${user.email} já registrado.`;

    const email = user.email.toLocaleLowerCase();
    const displayName = `${user.firstName} ${user.lastName}`;

    const registeredUser = await this.registerService.register(user);
    if (!registeredUser)
      throw `Falha ao registrar novo usuário com e-mail ${user.email}.`;

    const password = user.password;
    delete user.password;

    let newUser = await this.userRepository.create({
      ...user,
      id: registeredUser.id,
      email,
      displayName,
      type: UserType.B2C,
      dateCreated: new Date(),
      dateModified: new Date()
    });

    if (!newUser) newUser = (await this.login({ email, password })) as User;

    return newUser;
  }

  public signInWithGoogle(): Observable<User> {
    return from(this.authentication.signInWithGoogle()).pipe(
      mergeMap((user) =>
        this.getUserByEmail(user).pipe(
          map((results) => [results.docs?.map((u) => u.data() as User), user])
        )
      ),
      mergeMap(([results, user]: [User[], User & { uid }]) => {
        if (results && results.length > 0) {
          return of(results[0]);
        }

        const [, firstName, lastName] = /([^\s]+)(.*)/.exec(user.displayName);
        const { email, displayName } = user;
        const isCollaborator = /^.+@infrab4a.com$/.test(user.email);
        const userData = {
          id: user.uid,
          email,
          displayName,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          acceptsNewsletter: false,
          isCollaborator
        };

        return from(this.userService.create(userData)).pipe(
          map((data) => data)
        );
      })
    );
  }

  public uploadProfileImage(file: Blob) {
    const maxSize = 10 * 1024 * 1024; // 10mb
    if (file.size > maxSize) throw new Error('file_size');
    if (
      !file.type.toLowerCase().includes('jpg') &&
      !file.type.toLowerCase().includes('png') &&
      !file.type.toLowerCase().includes('jpeg')
    )
      throw new Error('file_extension');
    return from(this.updateUserImage.execute(file, this.getUser())).pipe(
      map((user) => {
        if (user.urlImageProfile)
          user.urlImageProfile =
            user.urlImageProfile + '?t=' + new Date().getTime();
        return user;
      })
    );
  }
}
