import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalOptionsFavoriteComponent } from '@components/modal-options-favorite/modal-options-favorite.component';
import {
  BeautyProfile,
  Category,
  Product,
  ProductRepository,
  User,
  Wishlist
} from '@infrab4a/connect';
import { CategoryService, WishlistService } from '@infrab4a/connect-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FiltersService } from 'src/app/services/filters.service';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';
import { WishlistProductsService } from 'src/app/services/wishlist.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  products: (Product & { isWishlist?: boolean })[];
  isLaptop = false;
  isTablet = false;
  userIsSubscriber: boolean;
  showFilters = true;
  productsPerPage = 24;
  sortSelected:
    | 'news'
    | 'best-sellers'
    | 'biggest-price'
    | 'lowest-price'
    | 'biggest-discount'
    | 'best-rating'
    | 'most-relevant' = 'best-sellers';
  filtersApplied = {};
  prices: {
    price: { min: number; max: number };
    subscriberPrice: { min: number; max: number };
  };
  result: any;
  totalPages: number;
  totalProducts: number;
  currentPage: number = 1;
  initialParams: {
    brands?: string[];
    prices?: { min: number; max: number; subscriberPrice: boolean };
    gender?: string[];
    tags?: string[];
    clubDiscount?: number[];
    rate?: number;
    customOptions?: string[];
  } = {} as any;
  filters: {
    brands?: string[];
    prices?: { min: number; max: number; subscriberPrice: boolean };
    gender?: string[];
    tags?: string[];
    clubDiscount?: number[];
    rate?: number;
    customOptions?: string[];
  } = {} as any;
  beautyProfileTags: string[];
  scrolling = false;
  isVisitor = false;
  wishlist: Wishlist;
  user: User;
  copiedLink = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.stylePage();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    if (!this.scrolling) {
      this.scrolling = true;
      setTimeout(() => {
        this.filtersService.addParamsToRoute(
          this.sortSelected,
          this.currentPage,
          this.productsPerPage
        );
        this.scrolling = false;
      }, 1000);
    }
  }

  constructor(
    private wishlistService: WishlistService,
    private wishlistProductsService: WishlistProductsService,
    private authService: MensAuthenticationService,
    private router: Router,
    private categoryService: CategoryService,
    @Inject('ProductRepository') protected productService: ProductRepository,
    @Inject(PLATFORM_ID) private platform: object,
    private filtersService: FiltersService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private metaService: Meta,
    private titleService: Title
  ) {
    this.wishlist = this.activatedRoute.snapshot.data.isResolved;
  }

  async ngOnInit(): Promise<void> {
    this.stylePage();
    this.activatedRoute.params.subscribe(async (params) => {
      this.user = this.authService.getUser();
      BroadcastUtil.get('loader').emit(true);
      this.filtersService.clearAllFiltersApplied();
      BroadcastUtil.get('clearFilters').emit();
      this.initialParams = {};
      this.currentPage = 1;
      this.productsPerPage = 24;
      this.sortSelected = 'best-sellers';

      BroadcastUtil.get('isWishlistPage').emit();

      await this.getHasProfile();

      const sub = await this.subscribeQueryParams();
      sub.unsubscribe();

      if (this.user?.id) {
        if (this.router.url.includes('/meus-favoritos')) {
          this.setMetadata();
          await this.getWishlistByPersonId();
          await this.getWishlistBySlug(this.wishlist.slug);
          await this.getBrands();
        }
        this.userIsSubscriber = this.user.isSubscriber ?? false;
      }

      if (this.router.url.includes('/favoritos')) {
        this.isVisitor = true;
        await this.getWishlistBySlug(params.slug);
        await this.getBrands();
      }

      const isEmptyObj = Object.keys(this.initialParams).length === 0;
      if (isEmptyObj) {
        await this.getProducts();
        this.filtersService.setPricesOriginal(this.result.prices);
      } else {
        await this.getProducts(this.sortSelected, this.initialParams);
        this.filtersService.setPricesOriginal(this.result.prices);
      }

      BroadcastUtil.get('updateProductFavorite').subscribe(async (p) => {
        this.products = this.products.filter((product) => product.id !== p.id);
        this.totalProducts = this.products.length;
      });

      BroadcastUtil.get('loggedMenuMobile').subscribe(async (res) => {
        BroadcastUtil.get('loader').emit(true);
        // this.wishlist = await this.wishlistProductsService.getWishlistByPersonId(
        //   this.personResult.person.id
        // )[0];
        this.products = this.wishlistProductsService.addIsWishlistToProduct(
          this.products
        );
        BroadcastUtil.get('loader').emit(false);
      });

      BroadcastUtil.get('perPageProducts').subscribe(async (response) => {
        if (this.isWishlist) {
          this.currentPage = 1;
          this.productsPerPage = response;
          if (isPlatformBrowser(this.platform)) {
            window.scrollTo({
              behavior: 'smooth',
              top: 0
            });
          }
          await this.getProducts(this.sortSelected, this.filters);
          this.filtersService.addParamsToRoute(
            this.sortSelected,
            this.currentPage,
            this.productsPerPage
          );
        }
      });

      BroadcastUtil.get('pagination').subscribe(async (response) => {
        if (this.isWishlist) {
          this.currentPage = response;
          if (isPlatformBrowser(this.platform)) {
            window.scrollTo({
              behavior: 'smooth',
              top: 0
            });
          }
          await this.getProducts(this.sortSelected, this.filters);
          this.filtersService.addParamsToRoute(
            this.sortSelected,
            this.currentPage,
            this.productsPerPage
          );
        }
      });

      BroadcastUtil.get('sort').subscribe(async (response) => {
        if (this.isWishlist) {
          this.currentPage = 1;
          await this.getProducts(response, this.filtersApplied);
          this.sortSelected = response;
          this.filtersService.addParamsToRoute(
            this.sortSelected,
            this.currentPage,
            this.productsPerPage
          );
        }
      });

      BroadcastUtil.get('filter').subscribe(async (response) => {
        if (this.isWishlist) {
          this.filters = response;
          this.currentPage = 1;
          this.filtersService.addParamsToRoute(
            this.sortSelected,
            this.currentPage,
            this.productsPerPage
          );
          if (
            this.filtersService.getFiltersApplied().length > 0 ||
            this.filtersService.getIsFilterPrice()
          ) {
            await this.getProducts(this.sortSelected, response);
            this.filtersApplied = response;
          } else {
            this.showFilters = false;
            await this.getProducts(this.sortSelected, {});
            this.filtersApplied = {};
            this.showFilters = true;
          }
        }
      });
      BroadcastUtil.get('loader').emit(false);
    });
  }

  async getWishlistBySlug(slug: string): Promise<void> {
    if (!this.wishlist) {
      this.wishlist = await this.wishlistService.getWishlistBySlug(slug);
    }
    if (this.wishlist.filters.length) {
      const filtersCategory = await this.categoryService.fetchFilterOptions(
        Category.toInstance(this.wishlist)
      );

      this.filtersService.setCustomFilters(filtersCategory);
    } else {
      this.filtersService.setCustomFilters(this.wishlist.filters);
    }
  }

  async getProducts(
    sort:
      | 'news'
      | 'best-sellers'
      | 'biggest-price'
      | 'lowest-price'
      | 'biggest-discount'
      | 'best-rating'
      | 'most-relevant' = 'best-sellers',
    filters = {}
  ): Promise<void> {
    BroadcastUtil.get('loader').emit(true);

    this.result = await this.wishlistService.getCatalogService().fetchProducts({
      category: this.wishlist,
      filters: filters,
      sort: sort,
      perPage: this.productsPerPage,
      page: this.currentPage
    });
    this.totalPages = this.result.pages;
    this.totalProducts = this.result.products.total;
    const products = this.result.products.data;
    if (this.user?.id && this.isVisitor) {
      await this.wishlistProductsService.getWishlistByUserId(this.user.id);
    }
    this.products = this.user?.id
      ? this.wishlistProductsService.addIsWishlistToProduct(products)
      : products;
    this.totalProducts = this.products.length;
    if (
      Object.keys(this.initialParams).length === 0 &&
      this.result.products.total !== 0
    ) {
      this.filtersService.setPrices(this.result.prices);
      if (!this.filtersService.getIsFilterPrice()) {
        this.filtersService.getSubscriberPrice()
          ? BroadcastUtil.get('setValuePrices').emit({ text: '', id: 1 })
          : BroadcastUtil.get('setValuePrices').emit({ text: '', id: 2 });
      }
    }
    BroadcastUtil.get('loader').emit(false);
  }

  async getBrands(): Promise<void> {
    this.showFilters = false;
    const result = await this.wishlistService
      .getCategoryService()
      .fetchBrands(this.wishlist);
    this.filtersService.setBrands(
      result.map((wishlist) => wishlist.conditions.brand)
    );
    this.showFilters = true;
  }

  async getHasProfile(): Promise<void> {
    if (this.user?.beautyProfile) {
      this.beautyProfileTags = this.getBeautyProfileTags(
        this.user.beautyProfile
      );
    }
  }

  stylePage(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isTablet = window.innerWidth < 768;
      this.isLaptop = window.innerWidth > 1024;
    }
  }

  goBack(): void {
    if (isPlatformBrowser(this.platform)) {
      window.history.back();
    }
  }

  async subscribeQueryParams(): Promise<Subscription> {
    return new Promise<Subscription>((resolve) => {
      resolve(
        this.activatedRoute.queryParams.subscribe((queryParams) => {
          if (queryParams.brands) {
            this.initialParams.brands = queryParams.brands.split(',');
            for (let item of this.initialParams.brands) {
              this.filtersService.addFiltersApplied(
                {
                  name: item,
                  tag: 'brand'
                },
                false
              );
              this.filtersService.addSelectedBrands(item);
            }
          }
          if (queryParams.gender) {
            this.initialParams.gender = queryParams.gender.split(',');
            for (let item of this.initialParams.gender) {
              this.filtersService.addFiltersApplied(
                {
                  name:
                    item === 'female'
                      ? 'Feminino'
                      : item === 'male'
                      ? 'Masculino'
                      : 'Unissex',
                  tag: 'gender'
                },
                false
              );
              this.filtersService.addSelectedRecommendedFilterOptions(item);
            }
          }
          if (queryParams.profile) {
            const profile = queryParams.profile === 'true';
            this.filtersService.setMyProfile(profile);
            if (profile) {
              this.initialParams.tags =
                this.filtersService.getBeautyProfileTags();
              if (
                !this.filtersService
                  .getFiltersApplied()
                  .some((filter) => filter.tag === 'beautyProfile')
              ) {
                this.filtersService.addFiltersApplied(
                  {
                    name: 'Meu Perfil',
                    tag: 'beautyProfile'
                  },
                  false
                );
              }
            } else {
              this.initialParams.tags = [];
            }
          }
          if (queryParams.clubDiscount) {
            this.initialParams.clubDiscount = queryParams.clubDiscount
              .split(',')
              .map((item) => Number(item));
            for (let item of this.initialParams.clubDiscount) {
              this.filtersService.addFiltersApplied(
                {
                  name:
                    item === 20
                      ? '20% off'
                      : item === 30
                      ? '30% off'
                      : item === 40
                      ? '40% off'
                      : item === 50
                      ? '50% off'
                      : item === 60
                      ? '60% off'
                      : '10% off',
                  tag: 'discount'
                },
                false
              );
              this.filtersService.addSelectedDiscountClubFilterOptions(
                item.toString()
              );
            }
          }
          if (queryParams.rate) {
            const rates = queryParams.rate
              .split(',')
              .map((item) => Number(item));
            this.initialParams.rate = Math.min(...rates);
            for (let item of rates) {
              this.filtersService.addFiltersApplied(
                { name: item, tag: 'rating' },
                false
              );
              this.filtersService.addSelectedRatingFilterOptions(item);
            }
          }
          if (isPlatformBrowser(this.platform) && queryParams.scrollY) {
            setTimeout(() => {
              window.scrollTo({
                behavior: 'smooth',
                top: Number(queryParams.scrollY)
              });
            }, 1000);
          }
          if (queryParams.sort) {
            this.sortSelected = queryParams.sort;
          }
          if (queryParams.customOptions) {
            this.initialParams.customOptions =
              queryParams.customOptions.split(',');
            const customFilters = this.filtersService.getCustomFilters();
            for (let item of this.initialParams.customOptions) {
              const foundOption = customFilters.find((cf) =>
                cf.options.some((option) => option.id === item)
              );
              if (foundOption) {
                const customFilter = foundOption.options.find(
                  (option) => option.id === item
                );
                this.filtersService.addFiltersApplied(
                  {
                    name: customFilter.description,
                    tag: 'customFilter'
                  },
                  false
                );
                this.filtersService.addSelectedCustomFilters(customFilter);
              }
            }
          }
          if (queryParams.page) {
            this.currentPage = Number(queryParams.page);
          }
          if (queryParams.perPage) {
            this.productsPerPage = Number(queryParams.perPage);
          }
        })
      );
    });
  }

  filterRemoved(): void {
    this.showFilters = false;
    setTimeout(() => {
      this.showFilters = true;
    }, 300);
  }

  openModalOptions() {
    const modal = this.modalService.open(ModalOptionsFavoriteComponent, {
      centered: true,
      backdrop: 'static',
      modalDialogClass: 'modal-options-favorite'
    });
    modal.componentInstance.showCloseButton = true;
    modal.componentInstance.personId = this.wishlist.personId;
    modal.componentInstance.wishlistId = this.wishlist.id;
  }

  get isWishlist(): boolean {
    return this.router.url.includes('favoritos');
  }

  async getWishlistByPersonId() {
    const wishlist = await this.wishlistProductsService.getWishlistByUserId(
      this.user.id
    );
    if (wishlist) {
      this.wishlist = wishlist[0];
    } else {
      this.router.navigate(['minha-conta/favoritos/favoritos-nao-criado']);
    }
  }

  shareNavigator() {
    if (isPlatformBrowser(this.platform)) {
      navigator.share({
        text: ``,
        url: `${window.location.origin}/favoritos/${this.wishlist.slug}`
      });
    }
  }

  copyToClipboard(): void {
    if (isPlatformBrowser(this.platform)) {
      let copyItem = `${window.location.origin}/favoritos/${this.wishlist.slug}`;
      navigator.clipboard.writeText(copyItem);
      this.copiedLink = true;
      setTimeout(() => {
        this.copiedLink = false;
      }, 3500);
    }
  }

  getBeautyProfileTags(profile: BeautyProfile): Array<string> {
    const tags: string[] = [];
    const profileObjects = Object.entries(profile);

    profileObjects.forEach(([name, options]) => {
      [].concat(options).forEach((option) => {
        tags.push(`perfil-${name} ${option}`);
      });
    });

    return tags.map((t) => t.toLowerCase()).sort();
  }

  private setMetadata(): void {
    // this.titleService.setTitle('glam: acessível, divertida e feita pra você!');
    // this.metaService.updateTag({
    //   property: 'og:title',
    //   content: 'glam: acessível, divertida e feita pra você!'
    // });
    // this.metaService.updateTag({
    //   property: 'og:description',
    //   content:
    //     'Uma experiência de beleza personalizada. Assine a Glambox e receba até 7 produtos de beleza valendo mais de R$240, pagando apenas R$74,90.'
    // });
    // this.metaService.updateTag({
    //   name: 'description',
    //   content:
    //     'Uma experiência de beleza personalizada. Assine a Glambox e receba até 7 produtos de beleza valendo mais de R$240, pagando apenas R$74,90.'
    // });
  }

  get showFiltersApplied(): boolean {
    return this.filtersService.getFiltersApplied().length > 0;
  }
}
