import { Component, OnInit } from '@angular/core';
import { WishlistService } from '@infrab4a/connect-angular';

@Component({
  selector: 'app-customer-shops',
  templateUrl: './customer-shops.component.html',
  styleUrls: ['./customer-shops.component.scss']
})
export class CustomerShopsComponent implements OnInit {
  shopList: {
    name: string;
    image: string;
    slug: string;
    personName: string;
  }[] = [];

  lists = [
    '1180749',
    '1222372',
    '182276',
    '2340359',
    '25377',
    '2879302',
    '3850668',
    '3902148',
    '3918987',
    '3936856',
    '3954329',
    '4020708',
    '4025069',
    '4026158',
    '4138220',
    '4155802',
    '4156350',
    '4163749',
    '4163749',
    '4203029',
    '4206813',
    '4220208',
    '4244342',
    '4306181',
    '4323324',
    '4329207',
    '4331050',
    '4367448',
    '4368571',
    '4409766',
    '4412132',
    '4449121',
    '4493919',
    '4504296',
    '4514532',
    '4538649',
    '4593035',
    '4636741',
    '4671976',
    '4710974',
    '4732377',
    '4745167',
    '4775084',
    '4851180',
    '4853758',
    '4857683',
    '4948370',
    '4956072',
    '5008384',
    '5026543',
    '5049742',
    '5071257',
    '5087793',
    '5088899',
    '5117375',
    '5133282',
    '5150000',
    '5178572',
    '5201463',
    '5221872',
    '5229781',
    '5239035',
    '5242729',
    '5250090',
    '5250889',
    '5269008',
    '5287818',
    '5294523',
    '5294524',
    '5322111',
    '5330311',
    '5384827',
    '5386039',
    '5388767',
    '5437345',
    '5490483',
    '5506981',
    '5510175',
    '5513246',
    '5514110',
    '5549932',
    '5549932',
    '5557082',
    '5569042',
    '5580166',
    '5593649',
    '5597361',
    '5601293',
    '5631346',
    '5634593',
    '5634609',
    '5634625',
    '92358'
  ];

  constructor(private wishlistService: WishlistService) {}

  async ngOnInit() {
    this.lists.forEach(async (list) => {
      this.wishlistService.getWishlistsByPerson(list).then((wishlist) => {
        let resolvedWishlist = wishlist.map((item) => {
          return {
            name: item.name,
            image: item.personPhoto
              ? item.personPhoto
              : '../../../assets/images/no-img-brand.png',
            slug: item.slug,
            personName: item.personName
          };
        });

        this.shopList.push(...resolvedWishlist);
        console.log(this.shopList);
        this.shopList.sort((a, b) =>
          a.personName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim() >
          b.personName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            ? 1
            : -1
        );
      });
    });
  }
}
