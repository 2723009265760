import { Inject, Injectable } from '@angular/core';
import {
  CheckoutTypes,
  Coupon,
  CouponRepository,
  Exclusivities,
  Shops,
  Where
} from '@infrab4a/connect';

@Injectable({ providedIn: 'root' })
export class CouponService {
  constructor(
    @Inject('CouponRepository') private couponRepository: CouponRepository
  ) {}

  private static gifts: Array<{ nickname: string; text: string }> = [
    {
      nickname: 'SELECT1',
      text: 'Parabéns! Você ganhou um Gel Facial Urby Men'
    },
    { nickname: 'SOLTEIRO', text: '10% OFF + brinde!' }
  ];

  async checkTransactionalCoupon(nickname: string, userEmail: string) {
    const couponSnap = await this.couponRepository.find({
      filters: {
        nickname: { operator: Where.EQUALS, value: nickname },
        shopAvailability: { operator: Where.EQUALS, value: Shops.MENSMARKET }
      }
    });

    if (couponSnap.count < 1) return Promise.reject();

    if (couponSnap.data[0] instanceof Coupon) {
      const coupon = couponSnap.data[0] as Coupon;
      return this.handleTransactionalCoupon(coupon, userEmail)
        .then(() => coupon)
        .catch(() => Promise.reject());
    } else return Promise.reject();
  }

  handleTransactionalCoupon(coupon: Coupon, userEmail: string): Promise<any> {
    if (+coupon.checkoutType !== CheckoutTypes.ECOMMERCE)
      return Promise.reject();

    const isNotColaborator =
      userEmail &&
      !this.isCollaborator(userEmail) &&
      coupon.exclusivityType.includes(Exclusivities.COLLABORATORS);

    if (isNotColaborator) return Promise.reject();

    const isNotSpecificUser =
      coupon.exclusivityType.includes(Exclusivities.SPECIFIC_USER) &&
      !coupon.userExclusiveEmail.includes(userEmail);

    if (isNotSpecificUser) return Promise.reject();

    if (coupon?.expiresIn < new Date()) return Promise.reject();

    return Promise.resolve();
  }

  isCollaborator(userEmail: string): boolean {
    if (userEmail?.match(/@b4a.com.br/g)) return true;

    return false;
  }

  static isGift(nickname: string): boolean {
    return this.gifts.some(
      (c) => c.nickname.toUpperCase() === nickname.toUpperCase()
    );
  }

  static giftText(nickname: string): string {
    const coupon = this.gifts.find(
      (c) => c.nickname.toUpperCase() === nickname.toUpperCase()
    );
    return coupon?.text || '';
  }
}
