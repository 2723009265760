<div *ngIf="showFilters" class="filters-products">
  <div *ngIf="!isResultsForMe">
    <components-filter-my-profile></components-filter-my-profile>
  </div>
  <div class="filters-products" *ngIf="customFilters.length > 0">
    <components-filters-options
      *ngFor="let option of customFilters"
      [title]="option.title ? option.title : option.description"
      [options]="option.options"
      [isCustomFilter]="true"
    ></components-filters-options>
  </div>
  <components-filters-options
    *ngIf="discountClubFilterOptions"
    [title]="'Desconto Clube'"
    [options]="discountClubFilterOptions"
    [isDiscountFilter]="true"
  ></components-filters-options>

  <components-filters-options
    *ngIf="brandsFilterOptions"
    [title]="'Marcas'"
    [options]="brandsFilterOptions"
    [isBrandsFilter]="true"
  ></components-filters-options>

  <components-filter-price [title]="'Preço'"></components-filter-price>

  <components-filters-options
    *ngIf="recommendedFilterOptions"
    [title]="'Recomendado para'"
    [options]="recommendedFilterOptions"
    [isGenderFilter]="true"
  ></components-filters-options>

  <components-filters-options
    *ngIf="ratingFilterOptions"
    [title]="'Avaliações'"
    [options]="ratingFilterOptions"
    [isRatingFilter]="true"
  ></components-filters-options>
</div>

<div *ngIf="!showFilters" class="filters-products__loader">
  <img src="/assets/images/spinner.gif" />
</div>
