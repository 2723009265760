import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'commons-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  @Input() label: string = '';
  @Input() value: any;
  @Input() name: string;
  @Input() checked: boolean;

  @Output() valueChange = new EventEmitter<any>();

  onChange(event: Event) {
    this.valueChange.emit(this.value);
  }
}
