<ng-container *ngIf="idCheckbox">
  <input
    type="checkbox"
    [name]="name"
    [id]="idCheckbox"
    [formControl]="control"
    (change)="changeOption($event)"
    [attr.disabled]="disabled ? true : null"
  />
  <label
    [for]="idCheckbox"
    class="checkbox"
    [class.checkbox--checked]="control.value"
    [class.checkbox--disabled]="disabled"
    [class.checkbox--big]="big"
  >
    <svg-icon
      *ngIf="control.value"
      src="assets/icons/white-check.svg"
      [svgStyle]="svgStyle"
    >
    </svg-icon>
  </label>
</ng-container>
