import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { Product, User } from '@infrab4a/connect';
import { FiltersService } from 'src/app/services/filters.service';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';

@Component({
  selector: 'components-list-product-card',
  templateUrl: './list-product-card.component.html',
  styleUrls: ['./list-product-card.component.scss']
})
export class ListProductCardComponent implements OnInit {
  @Input()
  products: Array<Product>;

  @Input()
  userIsSubscriber: boolean;

  @Input()
  isLaptop = false;

  @Input()
  sortSelected:
    | 'news'
    | 'best-sellers'
    | 'biggest-price'
    | 'lowest-price'
    | 'biggest-discount'
    | 'best-rating'
    | 'most-relevant' = 'best-sellers';

  @Input() totalPages: number;
  @Input() totalProducts: number;
  @Input() currentPage: number;
  @Input() productsPerPage: number;
  @Input() isWishlistPage?: boolean;

  @Output()
  productClick = new EventEmitter<void>();

  user: User;

  constructor(
    @Inject(PLATFORM_ID) private platform: object,
    private filtersService: FiltersService,
    private router: Router,
    private authService: MensAuthenticationService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUser();
  }

  back(): void {
    if (isPlatformBrowser(this.platform)) {
      window.history.back();
    }
  }

  enableImageLazyLoad(index: number): boolean {
    return index > 11;
  }

  clearFilters(): void {
    this.filtersService.clearAllFiltersApplied();
    this.filtersService.filterRemoved();
    this.filtersService.filterEmit();
  }

  get filtersApplied(): Array<{ name: string; tag: string }> {
    return this.filtersService.getFiltersApplied() || [];
  }

  goShop() {
    this.router.navigate(['/']);
  }
}
