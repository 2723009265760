<ul class="header-submenu">
  <svg-icon
    [src]="'assets/icons/close.svg'"
    [class]="'header-submenu__close-svg'"
    (click)="close()"
  ></svg-icon>

  <li class="header-submenu__option header-submenu__close-svg-mobile">
    <svg-icon [src]="'assets/icons/close.svg'" (click)="close()"></svg-icon>
  </li>

  <li
    *ngFor="let option of menu"
    class="header-submenu__option"
    (click)="navigate(option.link)"
  >
    {{ option.title }}
  </li>
  <div class="header-submenu__divider"></div>
  <li (click)="logout()" class="header-submenu__option">Logout</li>
</ul>
