import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {
  constructor(@Inject(PLATFORM_ID) private platform: object) {}

  async initializeWidget(): Promise<void> {
    if (isPlatformBrowser(this.platform)) {
      await window['Smooch']
        .init({
          integrationId: '669679f6be796b200df19fac',
          locale: 'pt-BR',
          businessName: "Atendimento Men's Market",
          businessIconUrl:
            'https://storage.googleapis.com/b4aprodution.appspot.com/glambox/assets/icons/logomens.png',
          canUserCreateMoreConversations: true,
          canUserSeeConversationList: true,
          customText: {
            clickToRetry:
              'Mensagem não entregue. Clique para tentar novamente.',
            conversationListHeaderText: 'Minhas conversas',
            conversationListPreviewAnonymousText: 'Anônimo',
            conversationListPreviewCarouselText: '{user} enviou uma mensagem',
            conversationListPreviewFileText: '{user} enviou um arquivo',
            conversationListPreviewFormText: '{user} enviou um formulário',
            conversationListPreviewFormResponseText:
              '{user} preencheu um formulário',
            conversationListPreviewImageText: '{user} enviou uma imagem',
            conversationListPreviewLocationRequestText:
              '{user} enviou uma solicitação de localização',
            conversationListPreviewUserText: 'Você',
            conversationListRelativeTimeJustNow: 'Agora',
            conversationListRelativeTimeMinute: '1 minuto atrás',
            conversationListRelativeTimeMinutes: '{value} minutos atrás',
            conversationListRelativeTimeHour: '1 hora atrás',
            conversationListRelativeTimeHours: '{value} horas atrás',
            conversationListRelativeTimeYesterday: 'Ontem',
            conversationListTimestampFormat: 'DD/MM/YY',
            conversationTimestampHeaderFormat: 'D MMMM YYYY, h:mm A',
            couldNotConnect: 'Offline. Você não receberá mensagens.',
            couldNotConnectRetry: 'Reconectando...',
            couldNotConnectRetrySuccess: 'Reconectado!',
            couldNotLoadConversations:
              'Não foi possível carregar suas conversas.',
            emailFieldLabel: 'Email',
            emailFieldPlaceholder: 'Seu endereço de email',
            emailFormButton: 'enviar',
            emailLinkingErrorMessage: 'Email inválido.',
            fetchHistory: 'Carregar mais',
            fetchingHistory: 'Buscando histórico...',
            fileTooLargeError: 'Tamanho máximo do arquivo excedido ({size})',
            fileTypeError: 'Tipo de arquivo não suportado.',
            formErrorEntryRequired: 'Campo obrigatório',
            formErrorInvalidEmail: 'Email inválido',
            formErrorNoLongerThan:
              'Deve conter no máximo ({characters}) caracteres',
            formErrorNoShorterThan:
              'Deve conter no mínimo ({characters}) caracteres',
            formErrorUnknown: 'Ocorreu algum erro inesperado',
            formFieldSelectPlaceholderFallback: 'Selecione...',
            imageClickToReload: 'Clique para recarregar.',
            imageClickToView: 'Clique para visualizar {size} imagens.',
            imagePreviewNotAvailable: 'Pré visualização não disponível.',
            inputPlaceholder: 'Digite sua mensagem aqui',
            inputPlaceholderBlocked: 'Preencha o formulário acima...',
            messageIndicatorTitlePlural: '({count}) Novas mensagens',
            messageIndicatorTitleSingular: '({count}) Nova mensagem',
            messageRelativeTimeDay: '{value} dias atrás',
            messageRelativeTimeHour: '{value} horas atrás',
            messageRelativeTimeJustNow: 'Agora',
            messageRelativeTimeMinute: '{value} minutos atrás',
            messageTimestampFormat: 'h:mm A',
            messageDelivered: 'Entregue',
            messageSeen: 'Visualizado',
            messageSending: 'Enviando...',
            newConversationButtonText: 'Nova conversa',
            sendButtonText: 'Enviar',
            settingsHeaderText: 'Configurações',
            shareLocation: 'Localização',
            syncConversation: 'Sincronizar conversas',
            tapToRetry: 'Mensagem não entregue. Clique para tentar novamente.',
            unsupportedMessageType: 'Tipo de mensagem não suportada.',
            unsupportedActionType: 'Ação não suportada.',
            uploadDocument: 'Arquivo',
            uploadInvalidError: 'Arquivo inválido',
            uploadPhoto: 'Imagem',
            uploadVirusError:
              'Um vírus foi detectado em seu arquivo e foi recusado'
          },
          customColors: {
            brandColor: '0091b9',
            conversationColor: '0091b9',
            actionColor: '0091b9'
          }
        })
        .catch(function (error) {
          console.error('Error initializing Smooch:', error);
        });

      window['Smooch'].on('widget:opened', () => {
        const widget = document.getElementById('web-messenger-container');
        if (widget) {
          widget.style.zIndex = '100000';
        } else {
          console.error('Smooch container not found');
        }

        const conversations = window['Smooch'].getConversations();
        if (conversations.length === 0) {
          window['Smooch'].createConversation();
        }
      });
    }
  }
}
