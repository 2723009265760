import { Category, Product } from '@infrab4a/connect';
import { BrandFilter } from '@pages/collections/base-collection.component';
import { BehaviorSubject, Observable } from 'rxjs';

export class ShopFiltersProvider {
  sortBy: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  products: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>(
    undefined
  );
  myProfile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  key: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  category: BehaviorSubject<Category> = new BehaviorSubject<Category>(
    {} as any
  );
  brands: BehaviorSubject<Array<BrandFilter>> = new BehaviorSubject<
    Array<BrandFilter>
  >(null);
  filters: BehaviorSubject<
    Array<{ name: string; tag: any }>
  > = new BehaviorSubject<Array<{ name: string; tag: any }>>([]);
  filter: BehaviorSubject<{
    name: string;
    tag: any;
    $event?;
    isCategory: boolean;
  }> = new BehaviorSubject<{
    name: string;
    tag: any;
    $event?;
    isCategory: boolean;
  }>(null);

  keepMyProfileActive: boolean;

  constructor() {}

  start(): void {
    this._sortBy = null;
    this._products = undefined;
    this._category = {} as any;
    this._brands = null;
    this._filters = [];
    this._filter = null;
    this._key = null;
  }

  get sortBy$(): Observable<string> {
    return this.sortBy.asObservable();
  }

  set _sortBy(sortBy: string) {
    this.sortBy.next(sortBy);
  }

  get products$(): Observable<Product[]> {
    return this.products.asObservable();
  }

  set _products(products: Array<Product>) {
    this.products.next(products);
  }

  get myProfile$(): Observable<boolean> {
    return this.myProfile.asObservable();
  }

  set _myProfile(myProfile: boolean) {
    this.myProfile.next(myProfile);
    this.keepMyProfileActive = myProfile;
  }

  get category$(): Observable<Category> {
    return this.category.asObservable();
  }

  set _category(category: Category) {
    this.category.next(category);
  }

  get brands$(): Observable<Array<BrandFilter>> {
    return this.brands.asObservable();
  }

  set _brands(brands: Array<BrandFilter>) {
    this.brands.next(brands);
  }

  get filters$(): Observable<Array<{ name: string; tag: any }>> {
    return this.filters.asObservable();
  }

  set _filters(filters: Array<{ name: string; tag: any }>) {
    this.filters.next(filters);
  }

  get filter$(): Observable<{
    name: string;
    tag: any;
    $event?;
    isCategory: boolean;
  }> {
    return this.filter.asObservable();
  }

  set _filter(filter: { name: string; tag: any; $event; isCategory: boolean }) {
    this.filter.next(filter);
  }

  get key$(): Observable<string> {
    return this.key.asObservable();
  }

  set _key(key: string) {
    this.key.next(key);
  }
}
