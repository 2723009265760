<div class="shop-menu-dropdown">
  <div
    [class.shop-menu-dropdown__arrow-up]="showDropdown"
    [class.shop-menu-dropdown__arrow-up--club-menu]="club"
  >
    <ng-content select="[item]"></ng-content>
  </div>
  <div class="shop-menu-dropdown__overlay"></div>
  <div
    class="shop-menu-dropdown__menu-container"
    [class.shop-menu-dropdown__menu-container--club-menu]="club"
    [class.shop-menu-dropdown__menu-container--left-items]="leftItems"
    [class.shop-menu-dropdown__menu-container--brands]="brands"
    *ngIf="showDropdown"
  >
    <ng-content select="[dropdown]"></ng-content>
  </div>
</div>
