import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterMyProfileModule } from '@components/filter-my-profile/filter-my-profile.module';
import { FilterPriceModule } from '@components/filter-price/filter-price.module';
import { FiltersOptionsModule } from '@components/filters-options/filters-options.module';
import { FiltersProductsComponent } from './filters-products.component';

@NgModule({
  declarations: [FiltersProductsComponent],
  imports: [
    CommonModule,
    FilterMyProfileModule,
    FiltersOptionsModule,
    FilterPriceModule
  ],
  exports: [FiltersProductsComponent]
})
export class FiltersProductsModule {}
