import { Component, Input } from '@angular/core';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'components-list-paginator',
  templateUrl: './list-paginator.component.html',
  styleUrls: ['./list-paginator.component.scss']
})
export class ListPaginatorComponent {
  @Input() totalPages: number;
  @Input() currentPage: number;

  ellipsis = '...';

  onClickPage(page: any): void {
    if (page !== this.ellipsis) {
      this.currentPage = page;
      BroadcastUtil.get('pagination').emit(this.currentPage);
    }
  }

  prevPage(): void {
    if (this.currentPage !== 1) {
      this.currentPage = this.currentPage - 1;
      BroadcastUtil.get('pagination').emit(this.currentPage);
    }
  }

  nextPage(): void {
    if (this.currentPage !== this.totalPages) {
      this.currentPage = this.currentPage + 1;
      BroadcastUtil.get('pagination').emit(this.currentPage);
    }
  }

  get paginatedProductPages(): Array<number | string> {
    const pagesToShow = 3;
    const pageArray: (number | string)[] = [];

    if (this.totalPages <= 4) {
      for (let i = 1; i <= this.totalPages; i++) {
        pageArray.push(i);
      }
    } else {
      pageArray.push(1);

      if (this.currentPage <= 2) {
        // Special case when currentPage is 1 or 2
        pageArray.push(2);
        pageArray.push(3);
        pageArray.push(this.ellipsis);
        pageArray.push(this.totalPages);
      } else if (this.currentPage >= this.totalPages - 1) {
        // Special case when currentPage is totalPages or totalPages - 1
        pageArray.push(this.ellipsis);
        pageArray.push(this.totalPages - 2);
        pageArray.push(this.totalPages - 1);
        pageArray.push(this.totalPages);
      } else {
        // General case
        if (this.currentPage > 3) {
          pageArray.push(this.ellipsis);
        }

        let startPage = Math.max(
          2,
          this.currentPage - Math.floor(pagesToShow / 2)
        );
        let endPage = Math.min(
          this.totalPages - 1,
          startPage + pagesToShow - 1
        );

        if (endPage - startPage < pagesToShow - 1) {
          startPage = Math.max(2, endPage - pagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
          pageArray.push(i);
        }

        if (this.currentPage < this.totalPages - 2) {
          pageArray.push(this.ellipsis);
        }

        pageArray.push(this.totalPages);
      }
    }

    return pageArray;
  }

  get prevArrowImg(): string {
    return this.currentPage <= 1
      ? 'assets/icons/arrow-left-gray.svg'
      : 'assets/icons/button-chevron-left.svg';
  }

  get nextArrowImg(): string {
    return this.currentPage === this.totalPages
      ? 'assets/icons/arrow-right-gray.svg'
      : 'assets/icons/button-chevron-right.svg';
  }
}
