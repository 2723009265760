import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonsModule } from '@commons/commons.module';
import { ListPaginatorModule } from '@components/list-paginator/list-paginator.module';
import { PerPageProductsModule } from '@components/per-page-products/per-page-products.module';
import { ProductCardModule } from '@components/product-card/product-card.module';
import { SortProductsModule } from '@components/sort-products/sort-products.module';
import { ListProductCardComponent } from './list-product-card.component';

@NgModule({
  declarations: [ListProductCardComponent],
  imports: [
    CommonModule,
    CommonsModule,
    SortProductsModule,
    ListPaginatorModule,
    PerPageProductsModule,
    ProductCardModule
  ],
  exports: [ListProductCardComponent]
})
export class ListProductCardModule {}
