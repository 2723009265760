<div
  class="product-card"
  [ngClass]="product.stock.quantity < 1 ? 'disabled' : ''"
>
  <a (click)="emitEventAndNavigate()" class="product-card__img-container">
    <div
      *ngIf="product?.reviews?.length || product?.rate"
      class="product-card__rate-star"
    >
      <img src="assets/icons/star.svg" />
      <p class="product-card__rate-star__rate">
        {{ getProductRating(product) }}
      </p>
    </div>
    <div
      *ngIf="!product.isWishlist"
      class="product-card__heart"
      (click)="addProductToFavorite($event, product)"
    >
      <img src="assets/icons/icon-favorite.svg" />
    </div>
    <div
      *ngIf="product.isWishlist && !isWishlistPage"
      class="product-card__heart__isFavorite"
      (click)="removeProductFromFavorite($event, product)"
    >
      <img src="assets/icons/icon-favorite-red.svg" />
    </div>
    <div
      *ngIf="product.isWishlist && isWishlistPage"
      class="product-card__heart__isFavorite"
      (click)="removeProductFromFavorite($event, product)"
    >
      <img src="assets/icons/icon-remove-wishlist.svg" />
    </div>
    <img
      *ngIf="lazyImage"
      loading="lazy"
      [src]="productImage"
      [alt]="product.name"
    />
    <img *ngIf="!lazyImage" [src]="productImage" [alt]="product.name" />
  </a>
  <div class="info">
    <p class="product-card__brand">{{ product.brand }}</p>
    <a [routerLink]="'/products/' + product.slug">
      <h2
        *ngIf="!isOnRootRoute"
        class="product-card__name"
        [title]="product.name"
        ellipsis
        [ellipsis-content]="product.name"
      ></h2>
      <h3
        *ngIf="isOnRootRoute"
        class="product-card__name"
        [title]="product.name"
        ellipsis
        [ellipsis-content]="product.name"
      ></h3>
    </a>
    <div class="product-card__price-container">
      <p
        *ngIf="product.price?.subscriberPrice"
        class="product-card__price product-card__price--new"
      >
        <span class="product-card__price--label bold">Clube:</span>
        <span class="product-card__price--value bold">{{
          product.price?.subscriberPrice | currency : 'BRL'
        }}</span>
      </p>
      <div class="product-card__price-container__column">
        <p class="product-card__price__no-club flex-row">
          <span>Sem clube:</span>
          <span>{{ product.price?.price | currency : 'BRL' }}</span>
        </p>
        <p class="product-card__price__original-value flex-row">
          <span class="product-card__price__original-value--line-through"
            >{{ product.price?.fullPrice | currency : 'BRL' }}
          </span>
          <span class="product-card__label discount">
            {{ discountPercentage }}% OFF
          </span>
        </p>
      </div>
    </div>
  </div>
  <div class="product-card__button-container" *ngIf="showButtonBuy">
    <div class="product-card__button">
      <commons-button
        *ngIf="product.stock.quantity >= 1"
        [svgPath]="'assets/icons/big-bag.svg'"
        [full]="true"
        [big]="true"
        [buttonText]="'Comprar'"
        (buttonClick)="buyClick.emit(product); addToCart()"
        [darkPink]="true"
      >
      </commons-button>
      <commons-button
        *ngIf="product.stock.quantity < 1"
        [buttonText]="'Avise-me'"
        [full]="true"
        [darkGray]="true"
        (buttonClick)="
          product?.hasVariants
            ? emitEventAndNavigate()
            : openModalProductBackInStock(product?.id)
        "
      >
      </commons-button>
    </div>
  </div>
</div>
