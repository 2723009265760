<input
  type="radio"
  [value]="value"
  [name]="name"
  [id]="value"
  [formControl]="control"
  (click)="valueSelect.emit($event)"
  *ngIf="control"
/>
<input
  type="radio"
  [value]="value"
  [name]="name"
  [id]="value"
  [(ngModel)]="model"
  (click)="valueSelect.emit($event)"
  *ngIf="!control"
/>
<label
  [for]="value"
  class="radio"
  [class.radio--active]="
    (control && control.value === value) || (model && model === value)
  "
>
  <span
    class="radio__button"
    *ngIf="(control && control.value === value) || (model && model === value)"
  ></span>
</label>
