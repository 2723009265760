<div class="my-profile-modal">
  <p class="my-profile-modal__title">Atenção</p>
  <p class="my-profile-modal__subtitle">
    Responda o Perfil de Beleza para ativar a função e ver produtos recomendados
    para o seu perfil
  </p>
  <div class="my-profile-modal__buttons">
    <commons-button
      [full]="true"
      [pink]="true"
      (buttonClick)="goTo()"
      [buttonText]="
        isLoggedAndHasNotBeautyProfile
          ? 'Responda o perfil'
          : 'Cadastre-se e responda'
      "
    >
    </commons-button>
    <commons-button
      [full]="true"
      [white]="true"
      (buttonClick)="modal.close()"
      buttonText="Agora não"
    >
    </commons-button>
  </div>
</div>
