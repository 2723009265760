<div class="modal-options-favorite">
  <div class="modal-options-favorite__close">
    <a (click)="modal.dismiss()">
      <img src="assets/icons/times.svg" />
    </a>
  </div>
  <div class="modal-options-favorite__container">
    <h1>Opções</h1>
    <div class="modal-options-favorite__container__list">
      <div
        (click)="goToEdit()"
        class="modal-options-favorite__container__list__option"
      >
        <p>Editar favoritos</p>
        <img src="assets/icons/arrow-right-pink.svg" alt="" />
      </div>
      <div
        (click)="goToCreateFavorite()"
        class="modal-options-favorite__container__list__option"
      >
        <p>O que são os favoritos</p>
        <img src="assets/icons/arrow-right-pink.svg" alt="" />
      </div>
      <div
        (click)="openModalDeleteWishlist()"
        class="modal-options-favorite__container__list__option"
      >
        <p>Excluir favoritos</p>
        <img src="assets/icons/arrow-right-pink.svg" alt="" />
      </div>
    </div>
  </div>
</div>
