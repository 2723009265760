<div class="favorite">
  <ng-container *ngIf="isLaptop">
    <div class="favorite__desktop container">
      <div class="favorite__desktop__left">
        <div class="favorite__desktop__left__wishlist-owner-content">
          <img
            class="favorite__desktop__left__wishlist-owner-content__avatar"
            loading="lazy"
            [src]="
              wishlist
                ? wishlist?.personPhoto
                : 'assets/images/settings/empty-avatar.png'
            "
            alt=""
          />
          <p class="favorite__desktop__left__wishlist-owner-content__name">
            {{ wishlist?.name }}
          </p>
          <p
            class="favorite__desktop__left__wishlist-owner-content__created-by"
          >
            Criado por {{ wishlist?.personName }}
          </p>
        </div>
        <div class="favorite__desktop__left__actions">
          <a
            *ngIf="!isVisitor"
            class="favorite__desktop__left__actions__btn-options"
            (click)="openModalOptions()"
          >
            <img src="assets/icons/icon-dots-menu.svg" alt="" />
            <p>Opções</p>
          </a>

          <div class="favorite__desktop__left__actions__btn-share-desktop">
            <a
              *ngIf="!copiedLink"
              class="favorite__desktop__left__actions__btn-share-desktop__link"
              (click)="copyToClipboard()"
            >
              <img src="assets/icons/icon-copy-blue.svg" alt="" />
              <p>Compartilhar</p>
            </a>

            <div *ngIf="copiedLink" class="copy">
              <svg-icon
                src="assets/icons/check-success-circle.svg"
                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
              >
              </svg-icon>
              <p>Copiado</p>
            </div>
          </div>
        </div>
        <components-filters-products
          *ngIf="products"
          [products]="products"
          [showFilters]="showFilters"
        ></components-filters-products>
      </div>
      <div class="favorite__desktop__right">
        <components-banner-description
          [category]="wishlist"
          [isTablet]="isTablet"
          *ngIf="wishlist"
        ></components-banner-description>
        <p class="favorite__desktop__left__wishlist-owner-content__description">
          {{ wishlist?.description }}
        </p>
        <components-filters-applied
          *ngIf="showFiltersApplied"
          (filterRemoved)="filterRemoved()"
        ></components-filters-applied>
        <components-list-product-card
          [products]="products"
          [personResult]="personResult"
          [userIsSubscriber]="userIsSubscriber"
          [isLaptop]="isLaptop"
          [sortSelected]="sortSelected"
          [totalPages]="totalPages"
          [totalProducts]="totalProducts"
          [currentPage]="currentPage"
          [productsPerPage]="productsPerPage"
          [isWishlistPage]="!isVisitor"
          *ngIf="products"
        ></components-list-product-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLaptop">
    <div class="favorite__mobile container">
      <div class="favorite__mobile__wishlist-owner-content">
        <img
          class="favorite__mobile__wishlist-owner-content__avatar"
          loading="lazy"
          [src]="wishlist?.personPhoto"
          alt=""
        />
        <p class="favorite__mobile__wishlist-owner-content__name">
          {{ wishlist?.name }}
        </p>
        <p class="favorite__mobile__wishlist-owner-content__created-by">
          Criado por {{ wishlist?.personName }}
        </p>
        <p class="favorite__desktop__right__description">
          {{ wishlist?.description }}
        </p>
      </div>
      <div class="favorite__mobile__actions">
        <a
          *ngIf="!isVisitor"
          class="favorite__mobile__actions__btn-options"
          (click)="openModalOptions()"
        >
          <img src="assets/icons/icon-dots-menu.svg" alt="" />
          <p>Opções</p>
        </a>

        <a
          (click)="shareNavigator()"
          class="favorite__mobile__actions__btn-share"
        >
          <img src="assets/icons/icon-share-black.svg" alt="" />
          <p>Compartilhar</p>
        </a>
      </div>
      <components-banner-description
        [category]="wishlist"
        [isTablet]="isTablet"
        *ngIf="wishlist"
      ></components-banner-description>
      <components-list-product-card
        [products]="products"
        [userIsSubscriber]="userIsSubscriber"
        [isLaptop]="isLaptop"
        [sortSelected]="sortSelected"
        [totalPages]="totalPages"
        [totalProducts]="totalProducts"
        [currentPage]="currentPage"
        [productsPerPage]="productsPerPage"
        [isWishlistPage]="!isVisitor"
        *ngIf="products"
      ></components-list-product-card>
    </div>
  </ng-container>
</div>
