<button
  *ngIf="href === undefined"
  [type]="type"
  class="button"
  [class.button--darkPink]="darkPink"
  [class.button--pink]="pink"
  [class.button--gray]="gray"
  [class.button--darkGray]="darkGray"
  [class.button--white]="white"
  [class.button--white-purple]="whitePurple"
  [class.button--google]="google"
  [class.button--facebook]="facebook"
  [class.button--full]="full"
  [class.button--big]="big"
  [class.button--outline]="outline"
  [class.button--outline-darkGray]="outlineDarkGray"
  [class.button--rounded]="rounded"
  [class.button--small]="small"
  [class.button--medium]="medium"
  [class.button--disabled]="disabled"
  [disabled]="disabled"
  [class.button--clear]="clear"
  [class.button--round]="round"
  [class.button--disableHover]="disableHover"
  [class.button--beige]="beige"
  [class.button--purple]="purple"
  [class.button--soft-pink]="softPink"
  [class.button--transparent]="transparent"
  [class.button--green]="green"
  [class.button--yellow]="yellow"
  (click)="buttonClick.emit()"
  [class.button--selected]="selected"
  [style.background-color]="customColor"
  [class.button--fontSmall]="fontSmall"
  [style.min-width]="minWidth ?? 'auto'"
>
  <svg-icon
    *ngIf="svgPath !== undefined && svgPath !== null && svgAlign === 'start'"
    [src]="svgPath"
    [svgStyle]="{ 'width.px': svgWidth, fill: svgFill, stroke: svgStroke }"
    [svgClass]="'button__svg'"
  ></svg-icon>
  <img [src]="imageUrl" *ngIf="imageUrl && imageAlign === 'start'" />
  <span class="button__text">{{ buttonText }}</span>
  <svg-icon
    *ngIf="svgPath !== undefined && svgPath !== null && svgAlign === 'end'"
    [src]="svgPath"
    [svgStyle]="{ 'width.px': svgWidth, fill: svgFill, stroke: svgStroke }"
    [svgClass]="'button__svg'"
  ></svg-icon>
  <img [src]="imageUrl" *ngIf="imageUrl && imageAlign === 'end'" />
</button>

<a
  *ngIf="href !== undefined && href !== null && !useRouterLink"
  [href]="href"
  class="button"
  [class.button--darkPink]="darkPink"
  [class.button--pink]="pink"
  [class.button--gray]="gray"
  [class.button--white]="white"
  [class.button--white-purple]="whitePurple"
  [class.button--google]="google"
  [class.button--facebook]="facebook"
  [class.button--full]="full"
  [class.button--big]="big"
  [class.button--rounded]="rounded"
  [class.button--outline]="outline"
  [class.button--outline-darkGray]="outlineDarkGray"
  [class.button--small]="small"
  [class.button--clear]="clear"
  [class.button--round]="round"
  [class.button--disableHover]="disableHover"
  [target]="target"
  [class.button--purple]="purple"
  [class.button--soft-pink]="softPink"
  [class.button--green]="green"
  [class.button--transparent]="transparent"
  [class.button--disabled]="disabled"
  [class.button--yellow]="yellow"
  [style.pointer-events]="disabled ? 'none' : 'auto'"
  [class.button--selected]="selected"
  [style.background-color]="customColor"
  [class.button--fontSmall]="fontSmall"
>
  <svg-icon
    *ngIf="svgPath !== undefined && svgPath !== null && svgAlign === 'start'"
    [src]="svgPath"
    [svgStyle]="{ 'width.px': svgWidth, fill: svgFill }"
    [svgClass]="'button__svg'"
  ></svg-icon>
  <img [src]="imageUrl" *ngIf="imageUrl && imageAlign === 'start'" />
  <span class="button__text">{{ buttonText }}</span>
  <svg-icon
    *ngIf="svgPath !== undefined && svgPath !== null && svgAlign === 'end'"
    [src]="svgPath"
    [svgStyle]="{ 'width.px': svgWidth, fill: svgFill }"
    [svgClass]="'button__svg'"
  ></svg-icon>
  <img [src]="imageUrl" *ngIf="imageUrl && imageAlign === 'end'" />
</a>

<a
  *ngIf="href !== undefined && href !== null && useRouterLink"
  [routerLink]="href"
  class="button"
  [class.button--darkPink]="darkPink"
  [class.button--pink]="pink"
  [class.button--gray]="gray"
  [class.button--white]="white"
  [class.button--white-purple]="whitePurple"
  [class.button--google]="google"
  [class.button--facebook]="facebook"
  [class.button--full]="full"
  [class.button--big]="big"
  [class.button--rounded]="rounded"
  [class.button--outline]="outline"
  [class.button--outline-darkGray]="outlineDarkGray"
  [class.button--small]="small"
  [class.button--clear]="clear"
  [class.button--round]="round"
  [class.button--disableHover]="disableHover"
  [target]="target"
  [class.button--purple]="purple"
  [class.button--soft-pink]="softPink"
  [class.button--green]="green"
  [class.button--transparent]="transparent"
  [class.button--disabled]="disabled"
  [class.button--yellow]="yellow"
  [style.pointer-events]="disabled ? 'none' : 'auto'"
  [class.button--selected]="selected"
  [style.background-color]="customColor"
  [class.button--fontSmall]="fontSmall"
>
  <svg-icon
    *ngIf="svgPath !== undefined && svgPath !== null && svgAlign === 'start'"
    [src]="svgPath"
    [svgStyle]="{ 'width.px': svgWidth, fill: svgFill }"
    [svgClass]="'button__svg'"
  ></svg-icon>
  <img [src]="imageUrl" *ngIf="imageUrl && imageAlign === 'start'" />
  <span class="button__text">{{ buttonText }}</span>
  <svg-icon
    *ngIf="svgPath !== undefined && svgPath !== null && svgAlign === 'end'"
    [src]="svgPath"
    [svgStyle]="{ 'width.px': svgWidth, fill: svgFill }"
    [svgClass]="'button__svg'"
  ></svg-icon>
  <img [src]="imageUrl" *ngIf="imageUrl && imageAlign === 'end'" />
</a>
