import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerShopsComponent } from './customer-shops.component';
import { CustomerShopsRoutingModule } from './customer-shops.routing.module';

@NgModule({
  declarations: [CustomerShopsComponent],
  imports: [CommonModule, CustomerShopsRoutingModule]
})
export default class CustomerShopsModule {}
