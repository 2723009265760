<div class="collection-filter">
  <div
    *ngIf="!isResultsForMe && !isVitrine"
    class="collection-filter__my-profile"
  >
    <p class="collection-filter__text">Meu Perfil</p>
    <commons-toggle
      [control]="myProfile"
      (valueChange)="onBeautyProfileChange($event)"
      class="collection-filter__my-profile__toggle"
    >
    </commons-toggle>
  </div>
  <div (click)="onFiltersClick()" class="collection-filter__filters">
    <p class="collection-filter__text">
      {{ isVitrine ? 'Filtro' : 'Filtros' }}{{ filtersLength }}
    </p>
    <img
      src="assets/icons/filter-icon.svg"
      alt="icone filtro"
      [style]="{ width: '17px', height: '18px' }"
    />
  </div>
</div>

<app-filters-modal
  *ngIf="mobileMenu"
  (mobileMenu)="mobileBack($event)"
  (myProfileFilterRemoved)="removeMyProfileFilter()"
></app-filters-modal>
