<div
  [class.brands--bottomBorder]="bottomBorder"
  [class.brands--mobile]="mobile"
  class="brands"
>
  <a
    *ngIf="!showBottomText"
    (click)="redirect('marcas', true); mobileMenu && closeMenu.emit()"
    class="brands__link"
    >Ver tudo</a
  >
  <div #parent *ngIf="brandsMenu" class="brands__container">
    <div class="brands__lists">
      <div
        [class.brands__lists__menu--mobileMenu]="mobileMenu"
        class="brands__lists__menu"
      >
        <div *ngFor="let child of brandsMenu; let i = index">
          <a
            (click)="scrollToLetter(charAt(i), parent)"
            *ngIf="charAt(i - 1) !== charAt(i)"
          >
            <p class="brands__lists__menu__first-letter">
              {{ charAt(i) | uppercase }}
            </p>
          </a>
        </div>
      </div>
      <div class="brands__lists__letters">
        <ul>
          <li *ngFor="let child of brandsMenu; let i = index">
            <p
              class="brands__lists__letters__letter-group"
              [id]="charAt(i)"
              [class.brands__lists--first-letter]="charAt(i - 1) !== charAt(i)"
              *ngIf="charAt(i - 1) !== charAt(i)"
            >
              {{ charAt(i) | uppercase }}
            </p>
            <a
              class="brands__lists__letters__label"
              [class.brands__lists__letters__label--brand-color]="
                child.label === 'Malmo' ||
                child.path.includes('/marca-mens') ||
                child.label === 'Glam Beauty'
              "
              (click)="
                redirect(child?.path, false, charAt(i), child.label);
                mobileMenu && closeMenu.emit()
              "
            >
              <h2>{{ child.label }}</h2>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <a
    *ngIf="showBottomText"
    class="brands__link"
    (click)="redirect('marcas', true)"
    >Ver tudo de Marcas</a
  >
</div>
