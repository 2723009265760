/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ErrorModalComponent } from '@components/error-modal/error-modal.component';
import {
  Checkout,
  LineItem,
  Product,
  ProductRepository,
  Shops,
  User,
  VariantRepository,
  Where
} from '@infrab4a/connect';
import { Cart, CartService, CheckoutService } from '@infrab4a/connect-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  of
} from 'rxjs';
import { catchError, distinctUntilKeyChanged, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MensAuthenticationService } from './mens-authentication.service';

type Flags = {
  showCart: boolean;
  isSubscriber: boolean;
  isMobile: boolean;
};

type Totals = {
  totalQuantity: number;
  totalPrice: number;
};

type ScreenSize = {
  width: number;
  height: number;
};

export type State = {
  user: User;
  cart: Cart;
  checkout: Checkout;
  lineItems: LineItem[];
  flags: Flags;
  totals: Totals;
  screenSize: ScreenSize;
};

@Injectable({ providedIn: 'root' })
export class StateService {
  public state$!: Observable<State>;

  private user$ = new BehaviorSubject<User>(null);
  private cart$ = new BehaviorSubject<Cart>({});
  private checkout$ = new BehaviorSubject<Checkout>(null);

  private lineItems$ = new BehaviorSubject<LineItem[]>([]);
  private flags$ = new BehaviorSubject<Flags>({
    showCart: false,
    isSubscriber: false,
    isMobile: false
  });
  private totals$ = new BehaviorSubject<Totals>({
    totalQuantity: 0,
    totalPrice: 0
  });
  private screenSize$ = new BehaviorSubject<ScreenSize>({
    width: 1024,
    height: 768
  });

  private checkoutId: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private cartService: CartService,
    private checkoutService: CheckoutService,
    private authService: MensAuthenticationService,
    private http: HttpClient,
    private ngbModal: NgbModal,
    @Inject('ProductRepository') private productService: ProductRepository,
    @Inject('VariantRepository') private variantService: VariantRepository,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.state$ = this.combineIntoObject({
      user: this.user$,
      cart: this.cart$,
      checkout: this.checkout$,
      lineItems: this.lineItems$,
      flags: this.flags$,
      totals: this.totals$,
      screenSize: this.screenSize$
    }) as Observable<State>;

    this.checkScreenSize();
    this.getCheckoutData();
    this.getUserData();
    this.getCartData();
    this.getLineItems();
    this.getTotals();
    this.checkGifts();
    this.checkStock();
  }

  public checkScreenSize(): void {
    if (isPlatformBrowser(this.platform)) {
      this.screenSize$.next({
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth
      });

      this.flags$.next({
        ...this.flags$.value,
        isMobile: window.innerWidth < 768
      });
    }
  }

  private async getCheckoutData(): Promise<void> {
    if (!isPlatformBrowser(this.platform)) return;
    try {
      const checkout = await this.checkoutService.getCheckout().toPromise();
      if (checkout !== null) {
        this.checkout$.next(checkout);
        this.checkoutId = checkout.id;
      }
    } catch (error) {
      console.log(error);
    }
  }

  private getUserData(): void {
    const user = this.authService.getUser();
    if (user) {
      this.user$.next(user);
      this.flags$.next({
        ...this.flags$.value,
        isSubscriber: user.isSubscriber
      });
    } else {
      this.user$.next(null);
      this.flags$.next({
        ...this.flags$.value,
        isSubscriber: false
      });
      if (this.checkout$.value?.user?.isSubscriber) {
        this.cartService
          .updateUserCart(User.toInstance({ isSubscriber: false }))
          .subscribe();
      }
    }
  }

  private getCartData(): void {
    this.subscriptions.push(
      this.cartService
        .getCart()
        .pipe(
          catchError(() => of({})),
          map((cart) => {
            this.cart$.next(cart);
          })
        )
        .subscribe()
    );
  }

  private getLineItems(): void {
    if (isPlatformBrowser(this.platform))
      this.subscriptions.push(
        this.cart$
          .pipe(
            map((cart) => {
              this.lineItems$.next(Object.values(cart));
            })
          )
          .subscribe()
      );
  }

  private getTotals(): void {
    this.subscriptions.push(
      combineLatest([
        this.lineItems$,
        this.flags$.pipe(distinctUntilKeyChanged('isSubscriber'))
      ])
        .pipe(
          map(([lineItems, flags]) => {
            let [totalQuantity, totalPrice] = [0, 0];

            lineItems.forEach((lineItem) => {
              totalQuantity += lineItem.quantity || 0;
              if (lineItem.isGift) {
                totalPrice += 0;
              } else if (flags.isSubscriber && lineItem.price.subscriberPrice) {
                totalPrice +=
                  lineItem.price.subscriberPrice * lineItem.quantity;
              } else {
                totalPrice += lineItem.pricePaid * lineItem.quantity;
              }
            });

            this.totals$.next({ totalQuantity, totalPrice });
          })
        )
        .subscribe()
    );
  }

  private checkStock(): void {
    if (isPlatformBrowser(this.platform))
      this.subscriptions.push(
        this.flags$
          .pipe(
            distinctUntilKeyChanged('showCart'),
            map(async (flags) => {
              if (flags.showCart && this.lineItems$.value.length) {
                for (const item of this.lineItems$.value)
                  await this.validateStock(item);
              }
            })
          )
          .subscribe()
      );
  }

  private async validateStock(line: LineItem): Promise<void> {
    const product = await this.getProductById(line.id);
    let stock: number;

    if (product && product.hasVariants)
      stock = await this.getVariantStock(line, product);
    else if (product && product.stock) stock = product.stock?.quantity;

    if (stock && stock < line.quantity) {
      void this.cartService.removeItem(line).subscribe(() => {
        line.stock.quantity = stock;

        if (line.stock.quantity > 0) this.updateItemQuantity(line);
        else this.quantityCallback(line);
      });
    }
  }

  private async getProductById(id: string): Promise<Product> {
    try {
      const product = await this.productService.get({ id });
      return product;
    } catch (error) {
      console.error(error);
    }
  }

  private async getVariantStock(
    line: LineItem,
    product: Product
  ): Promise<number> {
    const variants = (
      await this.variantService.find({
        filters: { productId: { operator: Where.EQUALS, value: product.id } }
      })
    ).data;

    if (variants && variants.length) {
      const variant = variants.find((v) => v.sku === line.sku);

      if (variant && variant.stock) return variant.stock.quantity;
    }
  }

  private updateItemQuantity(line: LineItem): void {
    line.quantity = 0;

    void this.cartService.addItem(line, line.stock.quantity).subscribe(() => {
      this.quantityCallback(line);
    });
  }

  private quantityCallback(lineItem: LineItem): void {
    let errorMessage: string;

    if (lineItem.stock.quantity === 0) {
      errorMessage = `Desculpe! Não temos mais unidades do item ${lineItem.name}
        em estoque no momento, e por isso o removemos do pedido.`;
    } else {
      const unit = lineItem.stock.quantity === 1 ? 'unidade' : 'unidades';

      errorMessage = `Desculpe! Temos apenas ${lineItem.stock.quantity} ${unit}
        em estoque do produto ${lineItem.name}, por isso o seu pedido será alterado.`;
    }

    this.ngbModal.dismissAll();

    const modal = this.ngbModal.open(ErrorModalComponent);

    modal.componentInstance.errorMessage = errorMessage;

    modal.result.then(
      (res) => {},
      () => {}
    );
  }

  private checkGifts(): void {
    if (isPlatformBrowser(this.platform))
      this.subscriptions.push(
        this.totals$
          .pipe(
            distinctUntilKeyChanged('totalPrice'),
            map(() => this.checkBuy2Win())
          )
          .subscribe()
      );
  }

  private async checkBuy2Win(): Promise<void> {
    if (isPlatformBrowser(this.platform))
      this.cartService.getGifts().subscribe();
  }

  private async checkBuy2WinFunction(): Promise<void> {
    const notGiftItems = this.lineItems$.value.filter((item) => !item.isGift);

    if (notGiftItems.length) {
      const res = await this.getBuy2WinGifts(notGiftItems);
      const giftItems = res.result ? this.productsToLineItems(res.result) : [];
      const lineItems = notGiftItems.concat(
        giftItems.filter(({ stock }) => !!stock.quantity)
      );

      this.cart$.next(this.generateCartObject(lineItems));

      await this.getCheckoutData();

      await this.checkoutService
        .updateCheckoutLineItems({
          id: this.checkoutId,
          lineItems
        })
        .toPromise();
    } else {
      void this.clearCart();
    }

    this.getTotals();
  }

  private async getBuy2WinGifts(notGiftItems: LineItem[]) {
    const data = {
      lineItems: this.lineItems$.value,
      cartTotal: this.totals$.value.totalPrice,
      categories: notGiftItems.map((item) => item.categories),
      shop: Shops.MENSMARKET
    };

    return (await this.http
      .post(
        `https://southamerica-east1-${environment.firebase.projectId}.cloudfunctions.net/checkHasuraCartElegebility`,
        { data }
      )
      .toPromise()) as { result: any[] };
  }

  private generateCartObject(items: LineItem[]): Cart {
    const cart: Cart = {};

    items?.forEach((item) => {
      cart[item.sku] = LineItem.toInstance({
        ...(cart[item.sku] || item),
        quantity:
          (cart[item.sku]?.quantity || 0) + (item.quantity ? item.quantity : 1)
      });
    });

    return cart;
  }

  public async clearCart(): Promise<void> {
    if (isPlatformBrowser(this.platform))
      await this.cartService.clearCart().toPromise();
  }

  private productsToLineItems(items: any[]): LineItem[] {
    return items.map((item) => {
      const {
        brand,
        categories,
        id,
        name,
        price,
        sku,
        slug,
        stock,
        weight,
        EAN
      } = item;

      const image =
        item.miniatures && item.miniatures.length
          ? item.miniatures[0]
          : item.images[0];

      return LineItem.toInstance({
        brand,
        categories,
        id,
        name,
        price,
        sku,
        slug,
        stock,
        weight,
        EAN,
        image,
        pricePaid: 0,
        quantity: 1,
        isGift: true
      });
    });
  }

  public async openCart(): Promise<void> {
    if (!isPlatformBrowser(this.platform)) return;

    this.getUserData();
    await this.checkBuy2Win();
    this.flags$.next({
      ...this.flags$.value,
      showCart: true
    });
  }

  public closeCart(): void {
    if (isPlatformBrowser(this.platform))
      this.flags$.next({
        ...this.flags$.value,
        showCart: false
      });
  }

  private combineIntoObject(sourceObj: any) {
    const keys = Object.keys(sourceObj);
    const sources = Object.values(sourceObj);

    return combineLatest(sources).pipe(
      map((values) => {
        const obj = {};

        for (let i = 0; i < keys.length; i++) obj[keys[i]] = values[i];

        return obj;
      })
    );
  }
}
