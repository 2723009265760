<div class="cart-item-container">
  <div *ngIf="product.isGift" class="cart-item-container__label">
    Compre e Ganhe
  </div>
  <div class="cart-item-container__details">
    <img [src]="productImage" />
    <span>{{ product.name }}</span>
  </div>
  <div *ngIf="!product.isGift" class="cart-item-container__actions">
    <div class="cart-item-container__actions__item">
      <span class="cart-item-container__actions__item__label">Preço</span>
      <span
        *ngIf="
          isSubscriber && product.price.subscriberPrice;
          else unSubscriber
        "
        class="cart-item-container__actions__item__price"
      >
        {{ product.price.subscriberPrice | currency: 'BRL' }}
      </span>
      <ng-template #unSubscriber>
        <span class="cart-item-container__actions__item__price">
          {{ product.pricePaid | currency: 'BRL' }}
        </span>
      </ng-template>
    </div>
    <div class="cart-item-container__actions__item">
      <span class="cart-item-container__actions__item__label">Quantidade</span>
      <div class="cart-item-container__actions__item__add-remove">
        <a (click)="decreaseItem()">-</a>
        <span>{{ product.quantity }}</span>
        <a
          triggers="click hover"
          placement="top"
          [ngbTooltip]="stockTooltip"
          (click)="addItem()"
          >+</a
        >
      </div>
    </div>
    <div class="cart-item-container__actions__item">
      <span class="cart-item-container__actions__item__label">Subtotal</span>
      <span
        *ngIf="
          isSubscriber && product.price.subscriberPrice;
          else unSubscriberSubTotal
        "
        class="cart-item-container__actions__item__price"
        >{{
          product.price.subscriberPrice * product.quantity
            | currency: 'BRL'
        }}</span
      >
      <ng-template #unSubscriberSubTotal>
        <span class="cart-item-container__actions__item__price">{{
          product.pricePaid * product.quantity | currency: 'BRL'
        }}</span>
      </ng-template>
    </div>
    <div class="cart-item-container__actions__item">
      <span class="cart-item-container__actions__item__label">Remover</span>
      <a (click)="deleteItem()">
        <svg-icon src="assets/icons/trash-can.svg"></svg-icon>
      </a>
    </div>
  </div>
</div>
