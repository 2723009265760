import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BeautyProfile, User } from '@infrab4a/connect';
import { AuthService } from '@infrab4a/connect-angular';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'components-filter-my-profile',
  templateUrl: './filter-my-profile.component.html',
  styleUrls: ['./filter-my-profile.component.scss']
})
export class FilterMyProfileComponent implements OnInit {
  myProfile = new FormControl();
  hasProfile = false;
  showUnloggedTooltip = false;
  showLoggedHasNotBeautyProfileTooltip = false;
  beautyProfileTags: string[];

  protected user: User;

  constructor(
    private router: Router,
    private filtersService: FiltersService,
    protected authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    // BroadcastUtil.get('loggedMenuMobile').subscribe(async (b) => {
    //   await this.getHasProfile();
    // });

    this.authService.getUser().subscribe((user) => {
      this.user = user;

      this.getHasProfile().then(() => {
        this.changeBeautyProfile(
          this.filtersService
            .getFiltersApplied()
            .some((filter) => filter.tag === 'beautyProfile'),
          false,
          false
        );
      });
    });
  }

  async getHasProfile(): Promise<void> {
    if (this.user?.id) {
      const { beautyProfile } = this.user;
      if (beautyProfile) {
        this.hasProfile = true;
        this.beautyProfileTags = this.getBeautyProfileTags(beautyProfile);
        this.filtersService.setBeautyProfileTags(this.beautyProfileTags);
      } else {
        this.hasProfile = false;
      }
    }
  }

  getBeautyProfileTags(profile: BeautyProfile): Array<string> {
    const tags: string[] = [];
    const profileObjects = Object.entries(profile);

    profileObjects.forEach(([name, options]) => {
      [].concat(options).forEach((option) => {
        tags.push(`perfil-${name} ${option}`);
      });
    });

    return tags.map((t) => t.toLowerCase()).sort();
  }

  changeBeautyProfile(event: boolean, emit = true, showTooltip = true): void {
    if (event && this.user?.id) {
      if (this.hasProfile) {
        this.showLoggedHasNotBeautyProfileTooltip = false;
        this.myProfile.setValue(true);
        this.filtersService.setMyProfile(true);
        if (
          !this.filtersService
            .getFiltersApplied()
            .some((filter) => filter.tag === 'beautyProfile')
        ) {
          this.filtersService.addFiltersApplied(
            {
              name: 'Meu Perfil',
              tag: 'beautyProfile'
            },
            emit
          );
        }
      } else {
        this.showLoggedHasNotBeautyProfileTooltip = true;
        this.myProfile.setValue(false);
        this.filtersService.setMyProfile(false);
      }
    } else {
      this.myProfile.setValue(false);
      this.filtersService.setMyProfile(false);
      if (!this.hasProfile && showTooltip) {
        this.showUnloggedTooltip = true;
      }
      if (!this.showUnloggedTooltip) {
        this.filtersService.removeFiltersApplied(
          {
            name: 'Meu Perfil',
            tag: 'beautyProfile'
          },
          emit
        );
      }
    }
  }

  goTo(): void {
    if (this.showUnloggedTooltip) {
      this.router.navigate(['/minha-conta/register'], {
        queryParams: { redirectUrl: '/minha-conta/perfil-de-beleza' }
      });
    } else {
      this.router.navigate(['minha-conta/perfil-de-beleza']);
    }
  }

  closeTooltip(): void {
    this.showUnloggedTooltip = false;
    this.showLoggedHasNotBeautyProfileTooltip = false;
  }
}
