/* eslint-disable @typescript-eslint/no-floating-promises */
import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ZendeskService } from './services/zendesk.service';
import { BroadcastUtil } from './utils/broadcast.util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'mens-frontend';
  loader = false;
  fwScript: HTMLScriptElement;
  fw: HTMLScriptElement;
  isPlatformBrowserStatus = false;
  constructor(
    private route: Router,
    private cdRef: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private platform: object,
    private zendeskService: ZendeskService
  ) {}

  ngOnInit(): void {
    BroadcastUtil.get('loader').subscribe(
      (show: boolean) => (this.loader = show)
    );

    this.zendeskService.initializeWidget();

    if (isPlatformBrowser(this.platform)) {
      this.isPlatformBrowserStatus = true;
      localStorage.removeItem('beautyProfileChange');
    }

    this.route.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
}
