<input
  type="radio"
  [value]="value"
  [name]="name"
  [id]="name + '-' + value"
  [checked]="checked"
  (change)="onChange($event)"
/>
<label [for]="name + '-' + value">
  <ng-content></ng-content>
</label>
