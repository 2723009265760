import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SortOptionsModule } from '@components/sort-options/sort-options.module';
import { SortProductsComponent } from './sort-products.component';

@NgModule({
  declarations: [SortProductsComponent],
  imports: [CommonModule, SortOptionsModule],
  exports: [SortProductsComponent]
})
export class SortProductsModule {}
