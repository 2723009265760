import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-checkout-layout',
  templateUrl: './checkout-layout.component.html',
  styleUrls: ['./checkout-layout.component.scss']
})
export class CheckoutLayoutComponent implements OnInit {
  isPlatformBrowserStatus = false;

  constructor(@Inject(PLATFORM_ID) private platform: object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isPlatformBrowserStatus = true;
    }
  }
}
