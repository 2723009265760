import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { User } from '@infrab4a/connect';

@Component({
  selector: 'app-personal-infos',
  templateUrl: './personal-infos.component.html',
  styleUrls: ['./personal-infos.component.scss']
})
export class PersonalInfosComponent implements OnInit, OnChanges {
  @Input()
  user: User;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.currentValue) {
      this.user = changes.user.currentValue;
    }
  }

  ngOnInit(): void {}

  get noSigner(): boolean {
    return !this.user?.isSubscriber;
  }

  get avatar(): string {
    return (
      this.user?.urlImageProfile || 'assets/images/settings/empty-avatar.png'
    );
  }
}
