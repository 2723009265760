<div
  [class.glambox-newsletter--club]="router.url === '/pages/clube'"
  class="glambox-newsletter"
>
  <div class="glambox-newsletter__container">
    <ng-container *ngIf="!showSuccess">
      <h2>Quer mais? Nós te damos!</h2>
      <p class="glambox-newsletter__container__subtitle">
        Te enviamos diretamente todas as novidades sobre o Universo Men's,
        promoções imperdíveis e as últimas tendências que você não pode perder!
      </p>
      <div class="glambox-newsletter__container__form">
        <div class="glambox-newsletter__container__form__input">
          <commons-input
            [circleBorder]="true"
            [fullW]="true"
            id="email"
            type="email"
            [control]="email"
            [placeholder]="'Digite seu e-mail'"
            (emitBlur)="email.value && validateEmail()"
          >
          </commons-input>
          <small
            *ngIf="email?.errors?.email && email?.touched && email?.dirty"
            class="error small-text left"
            >E-mail Inválido</small
          >
          <small
            *ngIf="email?.errors?.registered && email.touched && email.dirty"
            class="error small-text left"
            >E-mail cadastrado</small
          >
        </div>
        <div class="glambox-newsletter__container__form__button">
          <commons-button
            [full]="true"
            [disabled]="!email.valid"
            [pink]="true"
            buttonText="Enviar"
            (buttonClick)="submit()"
          >
          </commons-button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showSuccess">
      <h3>Seu e-mail foi cadastrado com sucesso!</h3>
      <p class="glambox-newsletter__container__subtitle">
        Em breve você receberá um e-mail de confirmação e ofertas especiais.
      </p>
    </ng-container>
  </div>
</div>
