import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'commons-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input()
  idCheckbox: string;
  @Input()
  name = 'checkbox';
  @Input()
  control: FormControl;
  @Output()
  stateChange = new EventEmitter<any>();
  @Input()
  isSelected = false;
  @Input()
  big = false;
  @Input()
  disabled = false;

  svgStyle: { 'width.px': number; 'height.px': number } = {
    'height.px': 14,
    'width.px': 11
  };
  constructor() {}

  ngOnInit(): void {
    if (!this.idCheckbox) {
      this.idCheckbox = 'checkbox' + FormUtil.getRandomInt(100, 1000);
    }
    if (!this.control) {
      this.control = new FormControl(this.isSelected);
    } else {
      this.isSelected = this.control.value;
      this.control.setValue(this.isSelected);
    }
    if (this.big) {
      this.svgStyle = { 'height.px': 11, 'width.px': 14 };
    }
  }

  changeOption($event): void {
    this.control.setValue($event.target.checked);
    this.stateChange.emit($event.target.checked);
  }
}
