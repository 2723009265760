<div class="back-in-stock-modal">
  <div class="back-in-stock-modal__modal-header">
    <h1 class="back-in-stock-modal__modal-header__modal-title">
      {{ title }}
    </h1>
  </div>

  <ng-container *ngIf="userEmail">
    <img
      src="assets/icons/check-green-circle.svg"
      [style.width]="'64px'"
      [style.height]="'64px'"
      loading="lazy"
      alt="success"
      class="back-in-stock-modal__success"
    />
    <p class="back-in-stock-modal__message">
      Quando o produto estiver disponível, vamos te notificar no e-mail:
      <b>{{ userEmail }}</b>
    </p>
  </ng-container>

  <div class="back-in-stock-modal__button">
    <commons-button
      [buttonText]="'Entendi'"
      [full]="true"
      (buttonClick)="registerNotification()"
    >
    </commons-button>
  </div>
</div>
