import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'components-sort-options',
  templateUrl: './sort-options.component.html',
  styleUrls: ['./sort-options.component.scss']
})
export class SortOptionsComponent implements OnInit {
  orderOptions: Array<{ title: string; value: string; disabled: boolean }>;
  sortValue = new FormControl();

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  setSortValue(option: { title: string; value: string; disabled: boolean }) {
    this.modal.close(option);
  }

  get sortText(): string {
    return this.sortValue.value;
  }
}
