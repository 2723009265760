<div class="toggle" [class.toggle--fullW]="fullW">
  <div
    class="toggle__slider"
    [class.toggle__slider--active]="checked"
    (click)="toggleChange()"
  >
    <svg-icon
      *ngIf="checked"
      class="toggle__icon"
      src="assets/icons/white-check.svg"
      [svgStyle]="{ 'width.px': 12, 'height.px': 9.5 }"
    >
    </svg-icon>
    <svg-icon
      *ngIf="!checked"
      class="toggle__icon2"
      src="assets/icons/white_x_icon.svg"
      [svgStyle]="{ 'width.px': 15, 'height.px': 15 }"
    >
    </svg-icon>
    <span
      class="toggle__button"
      [class.toggle__button--active]="checked"
    ></span>
  </div>
  <label class="toggle__label" *ngIf="label">{{ label }}</label>
</div>
