import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'commons-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input()
  buttonText = 'Default';
  @Input()
  disabled = false;
  @Input()
  href?: string = undefined;
  @Input()
  target: '_blank' | '_self' | '_parent' | '_top' | 'framename' = '_self';
  @Input()
  type: 'button' | 'submit' = 'button';

  @Input()
  google = false;
  @Input()
  facebook = false;
  @Input()
  customColor = '';
  @Input()
  pink = false;
  @Input()
  white = false;
  @Input()
  whitePurple = false;
  @Input()
  darkPink = false;
  @Input()
  full = false;
  @Input()
  big = false;
  @Input()
  medium = false;
  @Input()
  small = false;
  @Input()
  outline = false;
  @Input()
  outlineDarkGray = false;
  @Input()
  rounded = false;
  @Input()
  disableHover = false;
  @Input()
  clear = false;
  @Input()
  round = false;
  @Input()
  beige = false;
  @Input()
  purple = false;
  @Input()
  softPink = false;
  @Input()
  gray = false;
  @Input()
  darkGray = false;
  @Input()
  transparent = false;
  @Input()
  green = false;
  @Input()
  yellow = false;
  @Input()
  selected = false;
  @Input()
  fontSmall = false;
  @Input()
  useRouterLink = false;

  @Input()
  svgPath?: string = undefined;
  @Input()
  svgWidth = 14;
  @Input()
  svgFill = 'white';
  @Input()
  svgAlign: 'end' | 'start' = 'start';
  @Input()
  svgStroke: string;
  @Input()
  minWidth: string;

  @Input()
  imageUrl: string;
  @Input()
  imageAlign: 'end' | 'start' = 'start';

  @Output()
  buttonClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
