<div class="filters-modal container">
  <div class="filters-modal__header">
    <div class="filters-modal__header__back">
      <img
        [src]="'assets/icons/chevron-left-blue.svg'"
        (click)="mobileBack()"
        [style]="{ width: '18px', height: '18px' }"
        alt="Voltar"
      />
    </div>
    <p class="filters-modal__header__title">{{ headerTitle }}</p>
  </div>
  <div class="filters-modal__options">
    <ng-container *ngIf="mobileFilterFirstPage">
      <div
        *ngIf="showFiltersApplied"
        class="filters-modal__options__filters-applied"
      >
        <components-filters-applied
          (filterRemoved)="filterRemoved($event)"
        ></components-filters-applied>
      </div>
      <div
        *ngFor="let option of filtersList; let i = index"
        (click)="goToFilter(option)"
        class="filters-modal__options__option"
        [class.filters-modal__options__option--border-bottom]="
          i < filtersList.length - 1
        "
      >
        <p class="filters-modal__options__option__title">
          {{ option.title }}{{ getFilterLength(option) }}
        </p>
        <img
          src="/assets/icons/button-chevron-right.svg"
          [style]="{ width: '16px', height: '16px' }"
          alt="Seta apontando para direita"
        />
      </div>
    </ng-container>
    <ng-container *ngIf="discountSelected">
      <div class="filters-modal__options__box">
        <label
          *ngFor="let item of discountClubFilterOptions; let i = index"
          class="filters-modal__options__option-checkbox"
          [class.filters-modal__options__option--border-bottom]="
            i < discountClubFilterOptions.length - 1
          "
        >
          <commons-checkbox
            [idCheckbox]="getIdCheckbox(item)"
            [isSelected]="optionIsSelected(item)"
            (stateChange)="filterDiscount(item, $event)"
          ></commons-checkbox>
          <p class="filters-modal__options__option-checkbox__text">
            {{ item }}
          </p>
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="brandSelected">
      <form class="filters-modal__options__input-brands">
        <commons-input
          [circleBorder]="true"
          [fullW]="true"
          [id]="'brandsSearch'"
          type="text"
          [control]="brandsSearch"
          [placeholder]="'Buscar pela marca'"
          (emitInput)="onBrandsSearchInput()"
          [newsletter]="true"
          (iconClick)="onBrandsSearchInput()"
          [svgPath]="'assets/icons/search.svg'"
          [svgConfig]="{
            align: 'end',
            size: 'big'
          }"
        ></commons-input>
      </form>
      <div class="filters-modal__options__box">
        <label
          *ngFor="let brand of filteredBrands; let i = index"
          class="filters-modal__options__option-checkbox"
          [class.filters-modal__options__option--border-bottom]="
            i < filteredBrands.length - 1
          "
        >
          <commons-checkbox
            [idCheckbox]="getIdCheckbox(brand)"
            [isSelected]="optionIsSelected(brand)"
            (stateChange)="filterBrands(brand, $event)"
          ></commons-checkbox>
          <p class="filters-modal__options__option-checkbox__text">
            {{ brand }}
          </p>
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="priceSelected">
      <div class="filters-modal__options__box">
        <components-filter-price
          [isMobileFilter]="true"
        ></components-filter-price>
      </div>
    </ng-container>
    <ng-container *ngIf="genderSelected">
      <div class="filters-modal__options__box">
        <label
          *ngFor="let item of recommendedFilterOptions; let i = index"
          class="filters-modal__options__option-checkbox"
          [class.filters-modal__options__option--border-bottom]="
            i < recommendedFilterOptions.length - 1
          "
        >
          <commons-checkbox
            [idCheckbox]="getIdCheckbox(item)"
            [isSelected]="optionIsSelected(item)"
            (stateChange)="filterGenders(item, $event)"
          ></commons-checkbox>
          <p class="filters-modal__options__option-checkbox__text">
            {{ item }}
          </p>
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="ratingSelected">
      <div class="filters-modal__options__box">
        <label
          *ngFor="let item of ratingFilterOptions; let i = index"
          class="filters-modal__options__option-checkbox"
          [class.filters-modal__options__option--border-bottom]="
            i < ratingFilterOptions.length - 1
          "
        >
          <commons-checkbox
            [idCheckbox]="getIdCheckbox(item)"
            [isSelected]="optionIsSelected(item)"
            (stateChange)="filterRating(item, $event)"
          ></commons-checkbox>
          <div class="filters-modal__options__option-checkbox__rating">
            <app-rating-stars
              [currentRate]="item.stars"
              [yellow]="true"
              [superSmall]="true"
            >
            </app-rating-stars>
            <p class="filters-modal__options__option-checkbox__rating__text">
              ou mais
            </p>
          </div>
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="customFilterSelected">
      <div class="filters-modal__options__box">
        <label
          *ngFor="let item of customFilterList; let i = index"
          class="filters-modal__options__option-checkbox"
          [class.filters-modal__options__option--border-bottom]="
            i < customFilterList.length - 1
          "
        >
          <commons-checkbox
            [idCheckbox]="getIdCheckbox(item)"
            [isSelected]="optionIsSelected(item)"
            (stateChange)="filterCustomFilter(item, $event)"
          ></commons-checkbox>
          <p class="filters-modal__options__option-checkbox__text">
            {{ item.description }}
          </p>
        </label>
      </div>
    </ng-container>
  </div>
  <div class="filters-modal__buttons-box">
    <commons-button
      [full]="true"
      [pink]="true"
      (buttonClick)="goTo()"
      [buttonText]="mobileFilterFirstPage ? 'Fechar' : 'Aplicar'"
    >
    </commons-button>
    <commons-button
      [full]="true"
      [white]="true"
      buttonText="Limpar Filtros"
      (buttonClick)="clearFilters()"
    >
    </commons-button>
  </div>
</div>
