import { HttpClient } from '@angular/common/http';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  Checkout,
  CheckoutRepository,
  Coupon,
  ProductRepository,
  User
} from '@infrab4a/connect';
import { Cart, CartService, CheckoutService } from '@infrab4a/connect-angular';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CouponService } from 'src/app/services/coupon.service';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';
import { OpenService } from 'src/app/services/open.service';
import { State, StateService } from 'src/app/services/state.service';
import { ValidatorsUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'component-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  isMobile = false;

  cart: Cart;
  totalQuantity: number;
  totalPrice: number;
  subTotalPrice: number;
  shippingPrice: number;
  discount = 0;
  userSubscriber = false;
  checkout: Checkout;
  zip: FormControl = new FormControl('', ValidatorsUtil.cepValidator);
  nickname: FormControl = new FormControl('');
  coupon: Coupon;

  user: User;
  innerHeight: number;

  cuponBtnAction: { key: number; value: string };

  state$!: Observable<State>;

  constructor(
    @Inject('ProductRepository') private productService: ProductRepository,
    @Inject(PLATFORM_ID) private platform: object,
    //public modal: NgbActiveModal,
    private cartService: CartService,
    private checkoutService: CheckoutService,
    private openService: OpenService,
    private couponService: CouponService,
    private http: HttpClient,
    private authService: MensAuthenticationService,
    @Inject('CheckoutRepository')
    private checkoutRepository: CheckoutRepository,
    private router: Router,
    private stateService: StateService
  ) {
    this.state$ = this.stateService.state$.pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.stateService.checkScreenSize();
  }

  onClickGoToCheckout(): void {
    this.stateService.closeCart();
    void this.router.navigate(['/checkout']);
  }

  onClickCloseCart(): void {
    this.stateService.closeCart();
  }
}
