<div class="filter-price">
  <div
    *ngIf="!isMobileFilter"
    (click)="isCollapsed = !isCollapsed"
    class="filter-price__header"
  >
    <p class="filter-price__header__title">{{ title }}</p>
    <img
      [src]="svgIcon"
      [alt]="altIcon"
      [style]="{ width: '20px', height: '12px' }"
    />
  </div>
  <div *ngIf="!isCollapsed" class="filter-price__box">
    <div class="filter-price__box__inputs">
      <div class="filter-price__box__inputs__input">
        <p class="filter-price__box__inputs__input__text">De</p>
        <form class="filter-price__box__inputs__input__form">
          <commons-input
            [circleBorder]="true"
            [fullW]="true"
            [id]="'lessPrice'"
            type="number"
            [control]="lessPrice"
            [placeholder]="lessPricePlaceholder"
            (emitInput)="onLessPriceInput()"
            [price]="true"
            svgPath="assets/icons/cifrao.svg"
            [svgConfig]="{
              svgAlign: 'start',
            }"
          ></commons-input>
        </form>
      </div>
      <div class="filter-price__box__inputs__input">
        <p class="filter-price__box__inputs__input__text">Até</p>
        <form class="filter-price__box__inputs__input__form">
          <commons-input
            [circleBorder]="true"
            [fullW]="true"
            [id]="'mostPrice'"
            type="number"
            [control]="mostPrice"
            [placeholder]="mostPricePlaceholder"
            (emitInput)="onMostPriceInput()"
            [price]="true"
            svgPath="assets/icons/cifrao.svg"
            [svgConfig]="{
              svgAlign: 'start',
            }"
          ></commons-input>
        </form>
      </div>
    </div>
    <small *ngIf="showMessageError" class="error"
      >* Preço "Até" deve ser maior ou igual que o preço "De"</small
    >
    <div class="filter-price__box__radios">
      <commons-radio-group [vertical]="true" [formControl]="subsPrice">
        <commons-radio *ngFor="let item of radios" [value]="item.id">
          <span class="option-text">{{ item.text }}</span>
        </commons-radio>
      </commons-radio-group>
      <!-- <label
        *ngFor="let item of radios"
        class="filter-price__box__radios__label"
      >
        <commons-radiobutton
          [value]="item.id"
          [control]="subsPrice"
          (valueSelect)="changePrice(item)"
        >
        </commons-radiobutton>
        <p class="filter-price__box__radios__label__text">{{ item.text }}</p>
      </label> -->
      <p
        *ngIf="!isMobileFilter"
        (click)="!clearDisable && clear()"
        class="filter-price__box__radios__clear"
        [class.filter-price__box__radios__clear--disabled]="clearDisable"
      >
        Limpar
      </p>
    </div>
  </div>
</div>
