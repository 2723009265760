<nav class="header-navigation">
  <div class="header-navigation__links">
    <div
      *ngFor="let route of routesShop; let i = index"
      class="header-navigation__link"
      [class.header-navigation__link--active]="isSelected(route.path)"
      [class.header-navigation__link--arrow-menu]="
        openedMenu?.path === route.path
      "
      [class.header-navigation__link--last-child]="i === routesShop.length - 1"
      [class.hidden]="isMobile || searchOpened"
      (mouseenter)="showSubmenu(route, i)"
    >
      <commons-shop-menu-dropdown
        [leftItems]="route.label === 'Mãos e Pés'"
        [brands]="route.label === 'Marcas'"
      >
        <h2
          *ngIf="currentUrl === '/'"
          item
          (click)="goTo(route?.path)"
          class="header__shop-menu-content__menus__menu__text"
        >
          {{ route?.label }}
        </h2>
        <p
          *ngIf="currentUrl !== '/'"
          item
          (click)="goTo(route?.path)"
          class="header__shop-menu-content__menus__menu__text"
        >
          {{ route?.label }}
        </p>
        <div
          dropdown
          *ngIf="route.label !== 'Marcas'"
          class="header__shop-menu-content__menus__menu__shop-menu"
        >
          <div
            class="header__shop-menu-content__menus__menu__shop-menu__all-menus"
            [class.header__shop-menu-content__menus__menu__shop-menu__all-menus--column]="
              !hasSubChildMenu(route)
            "
          >
            <div
              *ngFor="let subMenu of route?.subMenu"
              class="header__shop-menu-content__menus__menu__shop-menu__all-menus__submenu"
            >
              <h2
                *ngIf="currentUrl === '/'"
                [class.header__shop-menu-content__menus__menu__shop-menu__all-menus__submenu__title]="
                  subMenu?.subChildMenu.length
                "
                [class.header__shop-menu-content__menus__menu__shop-menu__all-menus__submenu__subtitle]="
                  !subMenu?.subChildMenu.length
                "
                (click)="!subMenu?.subChildMenu.length && goTo(subMenu?.path)"
              >
                {{ subMenu?.label }}
              </h2>
              <p
                *ngIf="currentUrl !== '/'"
                [class.header__shop-menu-content__menus__menu__shop-menu__all-menus__submenu__title]="
                  subMenu?.subChildMenu.length
                "
                [class.header__shop-menu-content__menus__menu__shop-menu__all-menus__submenu__subtitle]="
                  !subMenu?.subChildMenu.length
                "
                (click)="!subMenu?.subChildMenu.length && goTo(subMenu?.path)"
              >
                {{ subMenu?.label }}
              </p>
              <ng-container *ngIf="currentUrl === '/'">
                <h2
                  *ngFor="let subChildMenu of subMenu?.subChildMenu"
                  class="header__shop-menu-content__menus__menu__shop-menu__all-menus__submenu__subtitle"
                  (click)="goTo(subChildMenu?.path)"
                >
                  {{ subChildMenu?.label }}
                </h2>
              </ng-container>
              <ng-container *ngIf="currentUrl !== '/'">
                <p
                  *ngFor="let subChildMenu of subMenu?.subChildMenu"
                  class="header__shop-menu-content__menus__menu__shop-menu__all-menus__submenu__subtitle"
                  (click)="goTo(subChildMenu?.path)"
                >
                  {{ subChildMenu?.label }}
                </p>
              </ng-container>
              <div *ngIf="hasSubChildMenu(route)">
                <p
                  (click)="goTo(subMenu?.path)"
                  class="header__shop-menu-content__menus__menu__shop-menu__see-all"
                >
                  Ver tudo
                </p>
              </div>
            </div>
            <div *ngIf="!hasSubChildMenu(route)">
              <p
                (click)="goTo(route?.path)"
                class="header__shop-menu-content__menus__menu__shop-menu__see-all"
              >
                Ver tudo
              </p>
            </div>
          </div>
          <div *ngIf="hasSubChildMenu(route)">
            <p
              (click)="goTo(route?.path)"
              class="header__shop-menu-content__menus__menu__shop-menu__see-all header__shop-menu-content__menus__menu__shop-menu__see-all--align-center"
            >
              Ver tudo de {{ route?.label }}
            </p>
          </div>
        </div>
        <div dropdown *ngIf="route.label === 'Marcas'">
          <components-brands-menu
            [brandsMenu]="marcas"
            [path]="route.path"
            [bottomBorder]="false"
          >
          </components-brands-menu>
        </div>
      </commons-shop-menu-dropdown>
    </div>
    <div class="header-navigation__link-club">
      <commons-shop-menu-dropdown [club]="true">
        <h2
          item
          (click)="goTo('/pages/clube')"
          class="header__shop-menu-content__menus__menu__text-club"
        >
          Clube da Men's
        </h2>
        <div dropdown>
          <div class="header__shop-menu-content__menus__menu__club-menu-club">
            <div *ngFor="let clubMenu of clubRoutes">
              <p
                class="header__shop-menu-content__menus__menu__club-menu__text-club"
                (click)="goTo(clubMenu.path, clubMenu.query)"
              >
                {{ clubMenu.label }}
              </p>
            </div>
          </div>
        </div>
      </commons-shop-menu-dropdown>
    </div>
    <a
      class="header-navigation__search"
      [class.header-navigation__search--active]="searchOpened"
    >
      <form (submit)="goToSearch()">
        <commons-input
          (input)="searchDebounce(search.value)"
          [placeholder]="'Buscar'"
          [circleBorder]="true"
          [control]="search"
          [svgPath]="'assets/icons/search.svg'"
          [svgConfig]="{ align: 'end', type: 'big' }"
          (iconClick)="goToSearch()"
        ></commons-input>
      </form>
    </a>
  </div>
</nav>

<ng-container *ngIf="!isMobile">
  <div
    (mouseleave)="clearResults()"
    *ngIf="loading"
    class="header-navigation__search-results container"
  >
    <p class="subtitle">Carregando...</p>
  </div>
  <div
    (mouseleave)="clearResults()"
    *ngIf="resultsForShow && resultsForShow?.length < 1 && search.value?.length"
    class="header-navigation__search-results container"
  >
    <p class="subtitle">Nenhum produto encontrado!</p>
  </div>
  <div
    (mouseleave)="clearResults()"
    *ngIf="resultsForShow && resultsForShow?.length > 0"
    class="header-navigation__search-results container"
  >
    <div class="row">
      <div
        class="col-12 col-md-4 mb-2"
        *ngFor="let result of resultsForShow; let i = index"
      >
        <components-product-card-horizontal
          [product]="result"
        ></components-product-card-horizontal>
      </div>
    </div>
    <div class="row">
      <commons-button
        [href]="'search/' + search.value.toLowerCase()"
        [rounded]="true"
        buttonText="VER TODOS OS RESULTADOS"
      >
      </commons-button>
    </div>
  </div>
</ng-container>
