import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';

@Component({
  selector: 'b4a-reputation',
  templateUrl: './reputation.component.html',
  styleUrls: ['./reputation.component.scss']
})
export class ReputationComponent {
  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    if (isPlatformBrowser(platform)) {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.id = 'ra-embed-reputation';
      script.src = 'https://s3.amazonaws.com/raichu-beta/selos/bundle.js';
      script.dataset.id = 'NzY3Njg6bWVucy1tYXJrZXQ=';
      script.dataset.target = 'reputation-ra';
      script.dataset.model = '2';
      this.renderer2.appendChild(document.body, script);
    }
  }
}
