<div class="create-favorite-modal">
  <div class="create-favorite-modal__close">
    <a (click)="modal.dismiss()">
      <img src="assets/icons/times.svg" />
    </a>
  </div>
  <div class="create-favorite-modal__container">
    <h1>Favoritos</h1>
    <p>
      Ops! Você precisa criar uma lista antes de "Favoritar" produtos. Após
      criar sua lista, volte aqui e adicione seus produtinhos favoritos!
    </p>
    <commons-button
      [pink]="true"
      [full]="true"
      [svgPath]="'assets/icons/icon-add.svg'"
      [svgAlign]="'start'"
      buttonText="Criar lista"
      (buttonClick)="goToCreateFavorite()"
    >
    </commons-button>
  </div>
</div>
