import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse, Location, ShippingMethodResponse } from '../models';
import { State } from '../models/api-response-models/state';

@Injectable({
  providedIn: 'root'
})
export class OpenService {
  private readonly OPEN_SERVICE_URL = environment.apiUrl + 'open';
  constructor(private http: HttpClient) {}

  async findLocationByZipcode(zipcode: string): Promise<Location> {
    return this.http
      .get(environment.apiUrl + 'open/location/cep/' + zipcode)
      .pipe(
        map((data: ApiResponse) => {
          return data.result;
        })
      )
      .toPromise() as Promise<Location>;
  }

  async generateNewPassword(body: { email: string }): Promise<string> {
    return this.http
      .post(environment.apiUrl + 'open/forgotpassword', body)
      .pipe(map((data: ApiResponse) => data.result))
      .toPromise();
  }

  async getShippingMethods(
    zip: string,
    weightGrams: number,
    personId: string
  ): Promise<Array<ShippingMethodResponse>> {
    return this.http
      .get(
        `${environment.apiUrl}open/checkshippingcompany?personId=${personId}&postalCode=${zip}&weightGrams=${weightGrams}`
      )
      .pipe(map((data: ApiResponse) => data.result))
      .toPromise() as Promise<Array<ShippingMethodResponse>>;
  }

  async listStates(): Promise<Array<State>> {
    return this.http
      .get(environment.apiUrl + 'open/location/states')
      .pipe(map((data: ApiResponse) => data.result))
      .toPromise();
  }

  shippingCostValidate(input: {
    postalCode: string;
    subscriptionPlan: 'SELECT' | 'SELECT_MENSAL';
    couponName?: string;
  }): Observable<{ shippingPrice: number }> {
    const { postalCode, subscriptionPlan, couponName } = input;
    const selectSubscriptionId = 3;

    return this.http
      .post<{ result: { shippingPrice: number } }>(
        `${this.OPEN_SERVICE_URL}/shippingcost/validate`,
        {
          shippingPostalCode: postalCode,
          fetchShippingInfo: false,
          isRenewal: false,
          couponName: couponName ?? '',
          personId: 0,
          subscriptionId: selectSubscriptionId, // only SELECT plans have shipping costs yet
          subscriptionTypeId:
            subscriptionPlan === 'SELECT'
              ? SubscriptionType.SELECT_SEMESTRAL
              : SubscriptionType.SELECT_MENSAL
        }
      )
      .pipe(
        map((response) => ({
          shippingPrice: response.result.shippingPrice
        }))
      );
  }
}

enum SubscriptionType {
  SELECT_MENSAL = 81,
  SELECT_SEMESTRAL = 82
  // SELECT_ANUAL = 83,
  // PRIME_MENSAL = 84,
  // PRIME_SEMESTRAL = 85,
  // PRIME_ANUAL = 86
}
