import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent implements OnInit {
  @Input() currentRate: number;
  @Input() yellow = false;
  @Input() orange = false;
  @Input() big = false;
  @Input() small = false;
  @Input() tiny = false;

  constructor() {}

  ngOnInit(): void {}
}
