export class TextUtil {
  static clearBrandName(name: string): string {
    const toRemove = [' ', 'marca'];
    const nameSplit = name
      .toLowerCase()
      .replace('-', '')
      .split(' ')
      .map((el) => (el = el.trim()))
      .filter((el) => el && !toRemove.includes(el.toLowerCase()))
      .map((el, i) => {
        if (i === 0 || this.doCapitalize(el)) {
          const chars = el.split('');
          chars[0] = chars[0].toUpperCase();
          return chars.join('');
        }
        return el;
      });

    return nameSplit.length > 1 ? nameSplit.join(' ') : nameSplit.shift();
  }

  static doCapitalize(word: string): boolean {
    const dontCapitalize = [
      'do',
      'da',
      'dos',
      'das',
      'de',
      'no',
      'na',
      'nos',
      'nas',
      'em',
      'um',
      'uns',
      'uma',
      'umas',
      'as',
      'os',
      'com'
    ];

    return word.length > 1 && !dontCapitalize.includes(word);
  }
}
