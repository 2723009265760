import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HeaderSubmenuComponent } from '@components/layout/header/header-submenu/header-submenu.component';
import { ProfileModalComponent } from '@components/profile-modal/profile-modal.component';
import {
  Category,
  CategoryFilter,
  CategoryRepository,
  MenuNav,
  Product,
  ProductsIndex,
  Shops,
  Status,
  Subscription,
  SubscriptionRepository,
  User,
  Where
} from '@infrab4a/connect';
import { Cart, CartService } from '@infrab4a/connect-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BrandFilter,
  GenderFilter,
  PriceFilter
} from '@pages/collections/base-collection.component';
import { LoginComponent } from '@pages/login/login.component';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ShopFiltersProvider } from 'src/app/providers/shop-filters.provider';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';
import { State, StateService } from 'src/app/services/state.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'components-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.fixed')
  @Input()
  fixedNav: boolean;

  @Input()
  hidden = false;

  @Input()
  routesShop: MenuNav[];
  isPlatformBrowserStatus: boolean = false;

  @Input()
  set updateRoutesShop(shop: MenuNav[]) {
    if (shop) {
      const index = shop.findIndex((r) => r.label === "Clube da Men's");
      if (index >= 0)
        this.routesShop[index].subMenu[0].subChildMenu =
          this.getClubMenuOptions();
    }
  }

  @Input()
  isMobile = false;

  @Input()
  isTablet = false;

  @Input()
  isLaptop = false;

  @Output()
  mobileMenuEmitter = new EventEmitter<boolean>();

  styleLogo = {
    'width.px': 165,
    'heigh.px': 35
  };

  resultsForShow: Product[];
  loading: boolean;
  search: FormControl = new FormControl('');
  searchOpened = false;
  searchDebounce = _.debounce((term) => this.searchChange(term), 500);
  user: User;
  subscriber: Subscription;
  initials: string;
  totalQuantity: number;
  userHasProfile = false;

  maxResults = 9;

  filterButtonText = 'Fechar';

  filters: Array<{ name: string; tag: any }>;
  category: Category;
  brands: Array<{ label: string; path: string }>;

  myProfile = new FormControl(false);
  toggle = new FormControl(false);

  myProfile$: Observable<boolean>;
  category$: Observable<Category>;
  brands$: Observable<Array<BrandFilter>>;
  filters$: Observable<Array<{ name: string; tag: any }>>;
  menuOpened = false;
  clubRoutes: { label: string; path: string; query?: string }[] = [];

  prices = [
    { label: 'ATÉ R$25', value: { min: 0, max: 25 } },
    { label: 'R$25 - R$50', value: { min: 25.01, max: 50 } },
    { label: 'R$50 - R$100', value: { min: 50.01, max: 100 } },
    { label: 'R$100 - R$150', value: { min: 100.01, max: 150 } },
    { label: 'MAIS DE R$150', value: { min: 150.01, max: 99999 } }
  ];

  genders = [
    { label: 'Feminino', value: 'feminino' },
    { label: 'Masculino', value: 'masculino' }
  ];

  loggedMenuItems: Array<any> = [
    {
      name: 'Meus pedidos',
      link: '/minha-conta/meus-pedidos'
    },
    {
      name: 'Assinatura',
      link: '/minha-conta/assinatura'
    },
    {
      name: `Questionário da Men's`,
      link: '/minha-conta/perfil-de-beleza'
    },
    {
      name: 'Favoritos',
      link: '/meus-favoritos'
    },
    {
      name: 'Configurações',
      link: '/minha-conta/configuracoes'
    }
  ];

  mobileMenu = false;
  optionSelected: CategoryFilter;
  brandSelected = false;
  priceSelected = false;
  genderSelected = false;
  hideCollectionFilter = false;

  state$!: Observable<State>;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: MensAuthenticationService,
    private cartService: CartService,
    private shopFiltersProvider: ShopFiltersProvider,
    private stateService: StateService,
    @Inject(PLATFORM_ID) private platform: object,
    @Inject('SubscriptionRepository')
    private subscriptionRepository: SubscriptionRepository,
    private productsElasticSearch: ProductsIndex,
    @Inject('CategoryRepository') private categoryRepository: CategoryRepository
  ) {}

  ngOnInit(): void {
    this.onResize();
    if (isPlatformBrowser(this.platform)) {
      BroadcastUtil.get('openLoginModal').subscribe(() => {
        this.login();
      });
      this.isPlatformBrowserStatus = true;
      this.setMaxResults();
      this.cartService.getCart().subscribe((cart) => {
        this.totalQuantity = this.getTotalQuantity(cart);
      });

      BroadcastUtil.get('hideCollectionFilter').subscribe(() => {
        this.hideCollectionFilter = true;
      });

      this.getBrands();
      BroadcastUtil.get('userUpdated').subscribe((user) => {
        this.user.urlImageProfile = user?.urlImageProfile;
      });

      this.authService.getAuthState().subscribe(
        async (authstate) => {
          // TODO: remover IF para usuários anonimos terem acesso ao painel
          if (authstate.isAnonymous || authstate.isAnonymous === undefined) {
            this.authService.logout();
            this.initials = null;
            this.user = null;
            this.subscriber = null;
          } else if (authstate.user) {
            this.user = authstate.user;
            this.getInitials(authstate.user);
            await this.getSubscriber(authstate.user.id);
          }
          this.clubRoutes = this.getClubMenuOptions();

          if (this.routesShop) {
            const index = this.routesShop?.findIndex(
              (r) => r.label === "Clube da Men's"
            );
            if (index >= 0)
              this.routesShop[index].subMenu[0].subChildMenu =
                this.getClubMenuOptions();
          }

          this.loadBeautyProfile();
        },
        (error) => console.error(error)
      );
    }
  }

  getBrands(): void {
    this.categoryRepository
      .find({
        filters: {
          brandCategory: { operator: Where.EQUALS, value: true },
          shop: { operator: Where.EQUALS, value: Shops.MENSMARKET }
        }
      })
      .then((categoriesSnap) => {
        const categories = categoriesSnap.data;

        this.brands = categories.map((category) => {
          const brandName = category.name.replace(/marca - /g, '');

          return {
            label: brandName,
            path: `/collections/${category.slug}`
          };
        });

        this.brands.sort((a, b) =>
          a.label
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim() >
          b.label
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            ? 1
            : -1
        );
      });
  }

  menuChanged(event: boolean): void {
    this.menuOpened = event;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setMaxResults();

    this.state$ = this.stateService.state$.pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );

    this.myProfile$ = this.shopFiltersProvider.myProfile$;
    this.myProfile$.subscribe((myProfile) => {
      if (this.myProfile.value !== myProfile)
        this.myProfile.setValue(myProfile);
    });
    this.category$ = this.shopFiltersProvider.category$;
    this.category$.subscribe((category) => {
      this.category = category;
    });
    this.filters$ = this.shopFiltersProvider.filters$;
    this.filters$.subscribe((filters) => {
      if (this.filters?.length && filters?.length === 0) this.closeFilters();

      this.filters = filters;
    });
  }

  get showCollectionFilters(): boolean {
    return (
      (this.router.url.includes('/collections') ||
        this.router.url.includes('/search') ||
        this.router.url.includes('/meus-favoritos')) &&
      !this.router.url.includes('login')
    );
  }

  setMaxResults(): void {
    if (window.innerHeight < 550) this.maxResults = 3;
    else if (window.innerHeight < 700) this.maxResults = 6;
  }

  onClickOpenCart(): void {
    this.stateService.openCart();
  }

  onClickCloseCart(): void {
    this.stateService.closeCart();
  }

  async getSubscriber(id: string): Promise<void> {
    this.subscriber = (
      await this.subscriptionRepository.find({
        filters: {
          user: { id: { operator: Where.EQUALS, value: id } },
          status: { operator: Where.EQUALS, value: Status.ACTIVE }
        }
      })
    ).data[0];
  }

  getClubMenuOptions() {
    if (!this.user) {
      return [
        { label: 'Assine o Clube', path: '/pages/clube', query: 'plans' },
        { label: 'Benefícios', path: '/pages/clube', query: 'benefits' },
        {
          label: 'Conteúdo',
          path: 'https://blog.mensmarket.com.br',
          query: 'content'
        }
      ];
    }

    if (!this.subscriber || this.subscriber?.status !== 'active') {
      return [
        { label: 'Assine o Clube', path: '/pages/clube', query: 'plans' },
        { label: 'Benefícios', path: '/pages/clube', query: 'benefits' },
        {
          label: 'Conteúdo',
          path: 'https://blog.mensmarket.com.br',
          query: 'content'
        }
      ];
    }

    if (this.subscriber && this.subscriber.subscriptionPlan.id === 'PRIME') {
      return [
        { label: 'Acesse o Clube', path: '/pages/clube' },
        { label: 'Compra de caixa avulsa', path: '/caixa-do-clube' },
        { label: 'Conteúdo', path: 'https://blog.mensmarket.com.br' }
      ];
    }

    if (this.subscriber && this.subscriber.subscriptionPlan.id === 'SELECT') {
      return [
        { label: 'Acesse o Clube', path: '/pages/clube' },
        { label: 'Conteúdo', path: 'https://blog.mensmarket.com.br' }
      ];
    }
  }

  getTotalQuantity(cart: Cart): number {
    return Object.values(cart).reduce(
      (acc, lineItem) => acc + lineItem.quantity,
      0
    );
  }

  getInitials(user: User): void {
    if (user && user.firstName && user.lastName)
      this.initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
  }

  toggleMenu(): void {
    BroadcastUtil.get('toggleMenuMobile').emit();
  }

  toggleSearch(): void {
    this.searchOpened = true;
  }

  toggleProfile($event: boolean): void {
    if (this.userHasProfile) {
      this.myProfile.setValue($event);
      this.onBeautyProfileChange($event);
    } else {
      this.myProfile.setValue(false);
      const ref = this.modalService.open(ProfileModalComponent);

      ref.componentInstance.loggedIn = this.userLogged;
      ref.result.then((result) => {
        this.goToBeautyProfile();
      });
    }
  }

  loadBeautyProfile(): void {
    const userId = this.user?.id;

    if (userId) {
      const { beautyProfile } = this.user;

      this.userHasProfile = Boolean(beautyProfile);
    } else {
      this.userHasProfile = false;
    }
  }

  goToBeautyProfile(): void {
    if (this.userLogged)
      this.router.navigate(['/minha-conta/perfil-de-beleza']);
    else this.router.navigate(['login'], { queryParams: { perfil: 1 } });
  }

  closeMenuNav(): void {
    BroadcastUtil.get('closeMenuNav').emit(true);
  }

  login(): void {
    if (this.isMobile) {
      window.scrollTo({
        behavior: 'auto',
        top: 0
      });
      this.router.navigate(['minha-conta/login'], {
        queryParams: {
          registerPageRedirectUrl: this.router.url,
          redirectUrl: this.router.url
        }
      });
    } else {
      const ref = this.modalService.open(LoginComponent, {
        backdropClass: 'invisible',
        modalDialogClass: 'modal-login'
      });

      ref.componentInstance.modal = true;
    }
  }

  openMenu(): void {
    this.modalService.open(HeaderSubmenuComponent, {
      backdropClass: 'invisible',
      modalDialogClass: 'modal-submenu'
    });
  }

  get userLogged(): boolean {
    return Boolean(this.user);
  }

  goToSearch(): void {
    if (this.search.value.trim())
      this.router.navigate([`/search/${this.search.value.trim()}`]);
  }

  clearResults(): void {
    this.resultsForShow = null;
    this.loading = false;
  }

  async searchChange(search: string): Promise<void> {
    if (!this.isMobile) {
      search = search.trim();

      if (!search.length) return;

      this.loading = true;

      const res = await this.productsElasticSearch.search(
        search,
        9,
        Shops.MENSMARKET
      );

      this.handleSearchResult(res as SearchResponse<Product>);
      this.loading = false;
    }
  }

  handleSearchResult(res: SearchResponse<Product>): void {
    const searchResults = res.hits.reduce(
      (acc: Product[], result: Hit<Product>) => [...acc, result._source],
      []
    );

    this.resultsForShow = searchResults
      ? searchResults.reduce((acc: Product[], cur: Product, index) => {
          if (index < 6) acc.push(cur);

          return acc;
        }, [])
      : [];
  }
  get showMobileFilters(): boolean {
    return (
      this.isLaptop &&
      (this.router.url.includes('/collections') ||
        this.router.url.includes('/search') ||
        this.router.url.includes('/meus-favoritos'))
    );
  }

  get mobileFilterFirstPage(): boolean {
    return (
      !this.optionSelected &&
      !this.brandSelected &&
      !this.genderSelected &&
      !this.priceSelected
    );
  }

  onBeautyProfileChange($event: boolean): void {
    this.shopFiltersProvider._myProfile = $event;
  }

  onMoreFiltersClick(): void {
    this.mobileMenu = true;
    this.mobileMenuEmitter.emit(true);

    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        if (this.fixedNav) document.body.style.overflow = 'initial';
        else document.body.style.overflow = 'hidden';
      }, 500);
    }
  }

  mobileBack(): void {
    if (this.mobileFilterFirstPage) {
      this.mobileMenu = false;
      this.mobileMenuEmitter.emit(false);

      if (isPlatformBrowser(this.platform))
        document.body.style.overflow = 'unset';
    } else {
      delete this.optionSelected;
      delete this.brandSelected;
      delete this.priceSelected;
      delete this.genderSelected;
    }
  }

  isFilterSelected(tag: string): boolean {
    return this.filters.some((f) => f.tag.value === tag);
  }

  isPriceFilterSelected(tag: PriceFilter): boolean {
    return this.filters.some((f) => f.tag.label === tag.label);
  }

  isBrandFilterSelected(tag: BrandFilter): boolean {
    return this.filters.some((f) => f.tag.label === tag.label);
  }

  isGenderFilterSelected(tag: GenderFilter): boolean {
    return this.filters.some((f) => f.tag.label === tag.label);
  }

  onDefaultFilterChange(name: string, tag: string, $event): void {
    this.shopFiltersProvider._filter = {
      name,
      tag,
      $event,
      isCategory: true
    };

    if (isPlatformBrowser(this.platform)) {
      window.scrollTo({
        behavior: 'auto',
        top: 0
      });
    }
  }

  onFilterChange(
    name: string,
    tag: BrandFilter | PriceFilter,
    $event: boolean
  ): void {
    this.shopFiltersProvider._filter = {
      name,
      tag,
      $event,
      isCategory: false
    };

    this.filterButtonText = this.filters.length > 0 ? 'Aplicar' : 'Fechar';

    if (isPlatformBrowser(this.platform)) {
      window.scrollTo({
        behavior: 'auto',
        top: 0
      });
    }
  }

  clearFilters(): void {
    BroadcastUtil.get('clearFilters').emit();

    if (isPlatformBrowser(this.platform)) {
      window.scrollTo({
        behavior: 'auto',
        top: 0
      });
    }
  }

  closeFilters(): void {
    this.mobileMenu = false;
    this.mobileMenuEmitter.emit(false);
    delete this.optionSelected;
    delete this.brandSelected;
    delete this.priceSelected;
    delete this.genderSelected;

    if (isPlatformBrowser(this.platform))
      document.body.style.overflow = 'unset';
  }

  get discountFilterCount(): number {
    return this.filters.filter((f) => f.name === 'Desconto Clube').length;
  }

  get brandFilterCount(): number {
    return this.filters.filter((f) => f.name === 'brand').length;
  }

  get priceFilterCount(): number {
    return this.filters.filter((f) => f.name === 'price').length;
  }

  get genderFilterCount(): number {
    return this.filters.filter((f) => f.name === 'gender').length;
  }

  get totalFilterCount(): number {
    return this.filters.filter((f) => f.name !== 'beautyProfile').length;
  }
}

type SearchResponse<T> = {
  total: number;
  hits: Hit<T>[];
};

type Hit<T> = {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: T;
  _version?: number;
  _explanation?: Explanation;
  fields?: unknown;
  highlight?: unknown;
  inner_hits?: unknown;
  matched_queries?: string[];
  sort?: string[];
};

type ShardsResponse = {
  total: number;
  successful: number;
  failed: number;
  skipped: number;
};

type Explanation = {
  value: number;
  description: string;
  details: Explanation[];
};
