import { Component, Input, OnChanges } from '@angular/core';
import { Filter, Product } from '@infrab4a/connect';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'components-filters-products',
  templateUrl: './filters-products.component.html',
  styleUrls: ['./filters-products.component.scss']
})
export class FiltersProductsComponent implements OnChanges {
  @Input()
  products: Array<Product>;

  @Input()
  showFilters = false;

  @Input()
  isResultsForMe = false;

  brandsFilterOptions: Array<string> = [];
  recommendedFilterOptions: Array<string> = [];
  ratingFilterOptions: Array<{ stars: number }> = [];
  discountClubFilterOptions: Array<string> = [];
  customFilters: Array<Filter> = [];

  constructor(private filtersService: FiltersService) {}

  ngOnChanges(): void {
    this.recommendedFilterOptions =
      this.filtersService.recommendedFilterOptions;
    this.ratingFilterOptions = this.filtersService.ratingFilterOptions;
    this.discountClubFilterOptions =
      this.filtersService.discountClubFilterOptions;
    this.brandsFilterOptions = this.filtersService.getBrands();
    this.customFilters = this.filtersService.getCustomFilters();
  }
}
