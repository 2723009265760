<div *ngIf="totalPages > 1" class="list-paginator">
  <button (click)="prevPage()" class="list-paginator__button">
    <img [src]="prevArrowImg" alt="seta para página anterior" />
  </button>
  <div
    *ngFor="let page of paginatedProductPages; let i = index"
    (click)="onClickPage(page)"
    class="list-paginator__item"
    [class.list-paginator__item--active]="page === currentPage"
    [class.list-paginator__item--ellipsis]="page === ellipsis"
  >
    {{ page }}
  </div>
  <button (click)="nextPage()" class="list-paginator__button">
    <img [src]="nextArrowImg" alt="seta para próxima página" />
  </button>
</div>
