import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'commons-shop-menu-dropdown',
  templateUrl: './shop-menu-dropdown.component.html',
  styleUrls: ['./shop-menu-dropdown.component.scss']
})
export class ShopMenuDropdownComponent implements OnInit {
  @Input()
  club = false;

  @Input()
  leftItems = false;

  @Input()
  brands = false;

  showDropdown = false;

  @HostListener('mouseenter') onMouseEnter(): void {
    this.showDropdown = true;
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.showDropdown = false;
  }

  @HostListener('focus') onFocus(): void {
    this.showDropdown = true;
  }

  constructor() {}

  ngOnInit(): void {}
}
