import { Component, Input, OnInit } from '@angular/core';
import { Product } from '@infrab4a/connect';

@Component({
  selector: 'components-product-card-horizontal',
  templateUrl: './product-card-horizontal.component.html',
  styleUrls: ['./product-card-horizontal.component.scss']
})
export class ProductCardHorizontalComponent implements OnInit {
  @Input()
  product: Product;

  computedRating: number = 0;

  get productImage(): string {
    if (!this.product.images?.length)
      return '../../../assets/images/no-img-brand.png';

    const image = this.product.images[0];

    if (image.includes('products-images')) return image;

    return image
      .replace('original/', 'medium/')
      .replace(`.${image.split('.').pop()}`, '.webp');
  }

  constructor() {}

  ngOnInit(): void {
    if (this.product) {
      this.computedRating = this.getProductRating(this.product);
    }
  }

  getProductRating(product: Product): number {
     if (product.rate) return product.rate;

    const reviews = product.reviews;

    if (!reviews) return 0;

    const trueReviews = reviews.filter((r) => r.status == true);

    if (!trueReviews || !trueReviews.length) return 0;

    return (
      trueReviews.reduce((acc, curr) => acc + curr.rate, 0) / trueReviews.length
    );
  }

  get miniImages(): string[] {
    const mini = this.product?.miniatures;
    return mini && mini.length ? this.product.miniatures : this.product.images;
  }

  get shouldShowFullPrice() {
    return (
      this.product.price?.fullPrice &&
      this.product.price?.fullPrice > this.product.price?.price
    );
  }
}
