import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'components-brands-menu',
  templateUrl: './brands-menu.component.html',
  styleUrls: ['./brands-menu.component.scss']
})
export class BrandsMenuComponent implements OnInit {
  @Input()
  brandsMenu: Array<{ label: string; path: string }>;

  @Input()
  path = '/collections/marcas';

  @Input()
  bottomText = true;

  @Input()
  showBottomText = true;

  @Input()
  mobile = false;

  @Input()
  mobileMenu = false;

  @Input()
  bottomBorder = true;

  @Output()
  closeMenu = new EventEmitter();

  constructor(
    @Inject(PLATFORM_ID) private platform: object,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  charAt(i: number): string {
    if (i < 0) {
      return '';
    }
    if (Number(this.brandsMenu[i].label[0]) > 0) {
      return '#';
    }
    return this.brandsMenu[i].label[0]
      .toLocaleUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  scrollToLetter(letter: string, parent: HTMLElement): void {
    if (isPlatformBrowser(this.platform)) {
      const element = document.getElementById(letter);
      if (element) {
        parent.scrollTop = element.offsetTop - 100;
      }
    }
  }

  redirect(path: string, link, nomeSubMenu = '', item = ''): void {
    this.router.navigate([path]);
  }
}
