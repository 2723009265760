<div class="profile-filter">
  <div
    (click)="closeTooltip()"
    *ngIf="showUnloggedTooltip || showLoggedHasNotBeautyProfileTooltip"
    class="overlay"
  ></div>
  <p class="title">Meu Perfil</p>
  <div class="toggle">
    <p class="status-label">
      {{ myProfile.value ? 'Ativado' : 'Desativado' }}
    </p>

    <commons-toggle
      [control]="myProfile"
      (valueChange)="changeBeautyProfile($event)"
    >
    </commons-toggle>
    <ng-container
      *ngIf="showUnloggedTooltip || showLoggedHasNotBeautyProfileTooltip"
    >
      <div class="tooltip-cta">
        <p class="text">
          Responda o Perfil de Beleza para ativar a função e ver produtos
          recomendados para o seu perfil
        </p>
        <div class="button">
          <commons-button
            [full]="true"
            [pink]="true"
            (buttonClick)="goTo()"
            [buttonText]="
              showUnloggedTooltip
                ? 'Cadastre-se e Responda'
                : 'Responda o Perfil'
            "
          >
          </commons-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-container
  *ngIf="
    hasProfile && !showLoggedHasNotBeautyProfileTooltip && !myProfile.value
  "
>
  <div class="logged-advice">
    <p class="text">Ative para ver produtos recomendados para seu perfil</p>
  </div>
</ng-container>
