<div class="modal-delete-wishlist">
  <div class="modal-delete-wishlist__close">
    <a (click)="modal.dismiss()">
      <img src="assets/icons/times.svg" />
    </a>
  </div>
  <div class="modal-delete-wishlist__container">
    <h1>Opções</h1>
    <img src="assets/icons/advice-icon.svg" alt="" />
    <p>Tem certeza que deseja excluir “Minha lista de desejos?”</p>
    <div class="modal-delete-wishlist__container__list">
      <commons-button
        [pink]="true"
        [full]="true"
        buttonText="Excluir"
        (buttonClick)="deleteWishlist()"
      >
      </commons-button>
      <commons-button
        [pink]="true"
        [outline]="true"
        [full]="true"
        buttonText="Cancelar"
        (buttonClick)="modal.dismiss()"
      >
      </commons-button>
    </div>
  </div>
</div>
