import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonsModule } from '@commons/commons.module';
import { RadioModule } from '@commons/radio/radio.module';
import { FilterPriceComponent } from './filter-price.component';

@NgModule({
  declarations: [FilterPriceComponent],
  imports: [
    CommonModule,
    CommonsModule,
    FormsModule,
    ReactiveFormsModule,
    RadioModule
  ],
  exports: [FilterPriceComponent]
})
export class FilterPriceModule {}
