import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LeadRepository } from '@infrab4a/connect';
import { LeadSource } from 'src/app/models/enums/lead-source';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'components-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent {
  email = new FormControl(null, [Validators.required, Validators.email]);
  showSuccess: boolean;

  constructor(
    public router: Router,
    @Inject('LeadRepository') private leadRepository: LeadRepository
  ) {}

  async submit(): Promise<void> {
    try {
      BroadcastUtil.get('loader').emit(true);
      await this.leadRepository.create({
        acceptsNewsletter: true,
        email: this.email.value,
        source: LeadSource.NEWSLETTER
      });
      BroadcastUtil.get('loader').emit(false);
      this.showSuccess = true;
    } catch (error) {
      console.error(error);
      BroadcastUtil.get('loader').emit(false);
    }
  }

  async validateEmail(): Promise<void> {
    BroadcastUtil.get('loader').emit(true);
    const emailValid = await this.leadRepository.find({
      filters: { email: this.email.value }
    });

    if (emailValid.data.length) {
      if (!this.email.errors) this.email.setErrors({ registered: true });
      else this.email.errors.registered = true;
    } else if (this.email.errors) {
      this.email.errors.registered = false;
    }

    BroadcastUtil.get('loader').emit(false);
  }
}
