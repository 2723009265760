import { SocialAuthService } from '@abacritt/angularx-social-login';
import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Status,
  SubscriptionRepository,
  Subscription as SubscriptionType,
  User,
  Where
} from '@infrab4a/connect';
import { AuthService, CartService } from '@infrab4a/connect-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';
import { AviseMeModalService } from 'src/app/services/avise-me-modal.service';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  modal = false;
  errorMessage: string = '';

  email = new FormControl('');
  password = new FormControl('');

  invalidLogin: string;
  authUser: User & { isAnonymous: boolean };
  userIsSubscriber: boolean;
  isInLoginPage: boolean;
  isInCheckout: boolean;
  redirectUrl: string;
  registerPageRedirectUrl: string;

  constructor(
    private _location: Location,
    protected route: ActivatedRoute,
    private modalService: NgbModal,
    private authenticationService: MensAuthenticationService,
    private autService: AuthService,
    private socialAuthService: SocialAuthService,
    private cartService: CartService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    @Inject('SubscriptionRepository')
    private subscriptionRepository: SubscriptionRepository,
    @Inject(PLATFORM_ID) private platform: object,
    private aviseMeModalService: AviseMeModalService
  ) {}

  async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this.platform)) {
      window.scroll(0, 0);
      this.socialAuthService.authState.subscribe(async (user) => {
        BroadcastUtil.get('loader').emit(true);
        this.modalService.dismissAll();
        BroadcastUtil.get('balanceChanged').emit();
        BroadcastUtil.get('loader').emit(false);
      });
      await this.subscribeQueryParams();

      this.isInLoginPage = this.router.url == '/minha-conta/login';
    }
  }

  async subscribeQueryParams(): Promise<Subscription> {
    return new Promise<Subscription>((resolve) => {
      resolve(
        this.route.queryParams.subscribe((queryParams) => {
          if (queryParams.perfil === '1')
            this.redirectUrl = '/minha-conta/perfil-de-beleza';
          else if (queryParams.assine === '1')
            this.redirectUrl = '/minha-conta/assinatura';
          else if (
            queryParams.registerPageRedirectUrl ||
            queryParams.redirectUrl
          ) {
            this.registerPageRedirectUrl = queryParams?.registerPageRedirectUrl;
            this.redirectUrl = queryParams?.redirectUrl;
          }
        })
      );
    });
  }

  async getUserSubscription(id: string): Promise<SubscriptionType> {
    return (
      await this.subscriptionRepository.find({
        filters: {
          user: { id: { operator: Where.EQUALS, value: id } },
          status: {
            operator: Where.EQUALS,
            value: Status.ACTIVE
          }
        }
      })
    ).data[0];
  }

  async isSubscriber(id: string): Promise<boolean> {
    const subs = await this.getUserSubscription(id);
    if (subs) return true;

    return false;
  }

  async login(event: Event): Promise<void> {
    event.preventDefault();
    const credentials = {
      email: this.email.value,
      password: this.password.value
    };

    try {
      BroadcastUtil.get('loader').emit(true);
      const user = await this.authenticationService.login(credentials);
      const isSubscriber = await this.isSubscriber(user.id);
      this.cartService
        .updateUserCart(User.toInstance({ ...user, isSubscriber }))
        .subscribe();
      this.modalService.dismissAll(true);

      await this.pushAndRedirect('default login after select plan');

      BroadcastUtil.get('balanceChanged').emit();
      BroadcastUtil.get('loader').emit(false);
    } catch (error) {
      this.invalidLogin =
        'Houve um problema ao autenticar, Verifique seu email e senha';
      BroadcastUtil.get('loader').emit(false);
    }
  }

  async loginWithGoogle(): Promise<void> {
    BroadcastUtil.get('loader').emit(true);
    const user = await this.authenticationService.loginWithGoogle().toPromise();
    const isSubscriber = await this.isSubscriber(user.id);
    this.cartService.updateUserCart({ ...user, isSubscriber }).subscribe();
    this.modalService.dismissAll(true);

    await this.pushAndRedirect('google login after select plan');

    BroadcastUtil.get('balanceChanged').emit();
    BroadcastUtil.get('loader').emit(false);
  }

  async pushAndRedirect(tag: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.autService.getUser().subscribe(async (user) => {
        if (this.redirectUrl) {
          if (this.redirectUrl === '/assine') this.pushTag(tag);
          else if (this.redirectUrl === '/minha-conta/assinatura') {
            const subscriber = await this.getUserSubscription(user.id);
            if (!subscriber) this.redirectUrl = '/pages/clube';
          }

          this.router.navigateByUrl(decodeURIComponent(this.redirectUrl));
        } else if (this.isInLoginPage) this._location.back();
        this.aviseMeModalService.openModalProductBackInStock(
          user?.email,
          user?.firstName
        );
        resolve();
      });
    });
  }

  checkAndDismiss(): void {
    if (this.modal) this.modalService.dismissAll();
  }

  goToRegister(): void {
    this.router.navigate(['/minha-conta/register'], {
      queryParams: { registerPageRedirectUrl: this.registerPageRedirectUrl }
    });
  }

  pushTag(method: string) {
    try {
      const gtmTag = {
        event: 'login',
        method: method
      };

      this.gtmService
        .pushTag({ ecommerce: null })
        .then(() => {})
        .catch(() => {});
      this.gtmService
        .pushTag(gtmTag)
        .then(() => {})
        .catch(() => {});
    } catch (error) {
      console.log('error pushing tags', error);
    }
  }
}
