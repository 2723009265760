import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalCreateFavoriteComponent } from '@components/modal-create-favorite/modal-create-favorite.component';
import { LineItem, Product, User, Variant } from '@infrab4a/connect';
import { CartService, WishlistService } from '@infrab4a/connect-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AviseMeModalService } from 'src/app/services/avise-me-modal.service';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StateService } from 'src/app/services/state.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'components-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product & { isWishlist?: boolean };
  @Input() user: User;
  @Input() isWishlistPage?: boolean;
  @Input() featuredProduct = false;
  @Input() showButtonBuy = true;
  @Input() position: number;
  @Input() addToCard = true;
  @Input() lazyImage = true;
  @Output() productClick = new EventEmitter<any>();
  @Output() buyClick = new EventEmitter<any>();
  maxRating = 5;

  isTablet = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.stylePage();
  }

  variants: Variant[];
  variantsForm;
  discountPercentage: number;

  get productImage(): string {
    if (!this.product.images?.length)
      return '../../../assets/images/no-img-brand.png';

    const image = this.product.images[0];

    if (image.includes('products-images')) return image;

    return image
      .replace('original/', 'medium/')
      .replace(`.${image.split('.').pop()}`, '.webp');
  }

  constructor(
    private cartService: CartService,
    private route: Router,
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private platform: object,
    private notificationService: NotificationService,
    private stateService: StateService,
    private wishListService: WishlistService,
    private authService: MensAuthenticationService,
    private modalService: NgbModal,
    private aviseMeModalService: AviseMeModalService
  ) {}

  async ngOnInit() {
    this.stylePage();
  }

  stylePage(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isTablet = window.innerWidth < 768;
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    if (this.product.price.fullPrice) {
      this.discountPercentage = Math.round(
        ((this.product.price.fullPrice - this.product.price.subscriberPrice) /
          this.product.price.fullPrice) *
          100
      );
    }

    if (this.product?.reviews?.length)
      this.product.reviews = this.product.reviews.filter((r) => r.status);
  }

  getProductRating(product: Product): string {
    if (product.rate) return product.rate.toFixed(1).replace('.', ',');

    const reviews = product?.reviews;

    if (!reviews || !reviews.length) return '0';

    return (reviews.reduce((acc, curr) => acc + curr.rate, 0) / reviews.length)
      .toFixed(1)
      .replace('.', ',');
  }

  addToCart() {
    if (this.product.hasVariants || !this.addToCard) {
      this.route.navigate(['/products/', this.product.slug]);
    } else {
      this.cartService
        .addItem(LineItem.toInstance(this.product.toPlain()), 1)
        .subscribe(
          async (cart) => {
            this.stateService.openCart();
          },
          (error) => {
            console.error(error);

            {
              this.notificationService.showWarning(
                `<p>Desculpe, mas só é possível comprar até <strong>${this.product.stock.quantity} unidades</strong>
            do item <strong>${this.product.name}</strong> no momento.</p>`,
                '',
                { positionClass: 'toast-top-full-width' }
              );
            }
          }
        );
    }
  }

  async addProductToFavorite(event, product) {
    BroadcastUtil.get('loader').emit(true);
    event.stopPropagation();
    event.preventDefault();
    const userLogged = this.authService.getUser()?.id;
    if (!userLogged) {
      BroadcastUtil.get('loader').emit(false);
      this.goToLogin();
    } else {
      const favorite = await this.loadWishlist();
      if (favorite) {
        this.wishListService
          .addProduct(favorite[0].id, product.id)
          .then(() => {
            // this.showSuccessToast();
            this.notificationService.showSuccess(
              '',
              `Produto adicionado a ${favorite[0].name}`
            );
            product = { ...product, isWishlist: true };
            BroadcastUtil.get('updateProductFavorite').emit(product);
            BroadcastUtil.get('loader').emit(false);
          })
          .catch((error) => {
            console.error(error);
            BroadcastUtil.get('loader').emit(false);
          });
      }
    }
  }

  async removeProductFromFavorite(event, product) {
    BroadcastUtil.get('loader').emit(true);
    event.stopPropagation();
    event.preventDefault();
    const userLogged = this.authService.getUser()?.id;
    if (!userLogged) {
      this.goToLogin();
    } else {
      let favorite = await this.loadWishlist();
      this.wishListService
        .removeProduct(favorite[0].id, product.id)
        .then(() => {
          this.notificationService.showSuccess(
            '',
            `Produto removido de ${favorite[0].name}`
          );
          product = { ...product, isWishlist: false };
          BroadcastUtil.get('updateProductFavorite').emit(product);
          BroadcastUtil.get('loader').emit(false);
        })
        .catch((error) => {
          console.error(error);
          BroadcastUtil.get('loader').emit(false);
        });
    }
  }

  goToLogin() {
    if (this.isTablet) {
      this.route.navigate(['/login'], {
        queryParams: { redirectUrl: this.route.url }
      });
    } else {
      BroadcastUtil.get('openLoginModal').emit();
    }
  }

  async loadWishlist() {
    BroadcastUtil.get('loader').emit(true);
    const userId = this.authService.getUser().id;
    try {
      return await this.wishListService.getWishlistsByPerson(userId);
    } catch (error) {
      this.openModalCreateFavorite();
      console.error(error);
    } finally {
      BroadcastUtil.get('loader').emit(false);
    }
  }

  openModalCreateFavorite() {
    const modal = this.modalService.open(ModalCreateFavoriteComponent, {
      centered: true,
      backdrop: 'static',
      modalDialogClass: 'create-favorite-modal'
    });
    modal.componentInstance.showCloseButton = true;
  }

  openModalProductBackInStock(): void {
    const user = this.authService.getUser();
    this.aviseMeModalService.showAviseMeModal = true;
    if (user)
      this.aviseMeModalService.openModalProductBackInStock(
        user.email,
        user.firstName,
        this.product.id
      );
    else {
      if (this.isTablet) {
        this.aviseMeModalService.productId = this.product.id;
        this.route.navigate(['minha-conta/login'], {
          queryParams: {
            redirectUrl: decodeURIComponent(this.route.url),
            registerPageRedirectUrl: decodeURIComponent(this.route.url)
          }
        });
      } else {
        this.aviseMeModalService.productId = this.product.id;
        BroadcastUtil.get('openLoginModal').emit();
      }
    }
  }

  emitEventAndNavigate() {
    this.productClick.emit(this.product);
    this.route.navigate(['/products/', this.product.slug]);
  }

  get isOnRootRoute(): boolean {
    return this.route.url === '/';
  }
}
