import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { MensAuthenticationService } from '../services/mens-authentication.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: MensAuthenticationService,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (isPlatformBrowser(this.platform)) {
      if (this.authService.authenticated === true) {
        return true;
      } else {
        this.router.navigate(['login'], {
          queryParams: { redirectUrl: state.url }
        });
        return false;
      }
    } else {
      return true;
    }
  }
}
