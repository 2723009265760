import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonsModule } from '@commons/commons.module';
import { PerPageOptionsComponent } from './per-page-options.component';

@NgModule({
  declarations: [PerPageOptionsComponent],
  imports: [CommonModule, CommonsModule],
  exports: [PerPageOptionsComponent]
})
export class PerPageOptionsModule {}
